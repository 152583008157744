.resourceCard {
    width: 100%;
    padding: 24px;
    background: white;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  /* Rest of the styles can remain the same since they only affect inner content */
  .sectionTitle {
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    color: #333;
    margin: 0 0 16px 0;
  }
  
  .videoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 16px;
    padding: 4px;
  }
  
  @media (max-width: 850px) {
    .resourceCard {
      width: 100%;
      min-height: 0;
    }
    
    .videoGrid {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
  }
  
  @media (max-width: 600px) {
    .resourceCard {
      padding: 16px;
    }
    
    .videoGrid {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
  }
  
  .videoCard {
    cursor: pointer;
    transition: transform 0.2s;
    position: relative;
  }
  
  .videoCard:hover {
    transform: translateY(-2px);
  }
  
  .thumbnailContainer {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    aspect-ratio: 9/16;
    background: #f0f0f0;
  }
  
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    opacity: 1 !important;
  }
  
  .thumbnailPlaceholder {
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, #f0f0f0, #e0e0e0);
  }
  
  .videoCard:hover .thumbnail {
    opacity: 0.8;
  }
  
  .videoOverlay {
    position: absolute;
    inset: 0;
    background: linear-gradient(to top, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.2) 50%, rgba(0,0,0,0) 70%);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 16px;
    pointer-events: none;
  }
  
  .playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 48px;
    height: 48px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: auto;
  }
  
  .videoCard:hover .playButton {
    opacity: 1;
  }
  
  .playIcon {
    color: #333;
    margin-left: 2px;
  }
  
  .videoInfo {
    color: white;
    z-index: 1;
    pointer-events: auto;
  }
  
  .videoTitle {
    font-size: 14px;
    font-weight: 600;
    margin: 0 0 8px 0;
    text-shadow: 0 1px 2px rgba(0,0,0,0.3);
  }
  
  .videoMeta {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 12px;
  }
  
  .duration {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  
  .durationIcon {
    opacity: 0.8;
  }
  
  .views {
    opacity: 0.8;
  }
  
  /* Template Section Styles */
  .templateList {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .templateRow {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 8px;
    transition: background-color 0.2s;
    cursor: pointer;
  }
  
  .templateRow:hover {
    background: #f5f5f5;
  }
  
  .templateIcon {
    padding: 8px;
    background: #f0f0f0;
    border-radius: 8px;
    color: #666;
  }
  
  .templateInfo {
    flex: 1;
    margin: 0 16px;
  }
  
  .templateTitle {
    font-size: 14px;
    font-weight: 600;
    color: #333;
    margin: 0 0 4px 0;
  }
  
  .templateDescription {
    font-size: 12px;
    color: #666;
    margin: 0 0 4px 0;
  }
  
  .templateMeta {
    font-size: 12px;
    color: #888;
  }
  
  .downloadIcon {
    color: #666;
    opacity: 0.6;
    transition: opacity 0.2s;
  }
  
  .templateRow:hover .downloadIcon {
    opacity: 1;
  }
  
  /* Upgrade Card Styles */
  .upgradeCard {
    width: 100%;
    padding: 32px;
    text-align: center;
    background: var(--sml-upload-background-color);
    color: white;
  }
  
  .upgradeIcon {
    width: 64px;
    height: 64px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
  }
  
  .uploadIcon {
    color: var(--sml-upload-background-color);
  }
  
  .upgradeTitle {
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 8px 0;
  }
  
  .upgradeText {
    font-size: 14px;
    opacity: 0.9;
    margin: 0 0 24px 0;
  }
  
  .upgradeButton {
    background: white;
    color: var(--sml-upload-background-color);
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .upgradeButton:hover {
    background: #f0f0f0;
  }
  
  /* Responsive Styles */
  @media (max-width: 850px) {
    .videoGrid {
      grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
    
    .upgradeCard {
      padding: 24px;
    }
  }
  
  @media (max-width: 600px) {
    .resourceCard {
      padding: 16px;
    }
    
    .videoGrid {
      grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    }
  
    .upgradeTitle {
      font-size: 20px;
    }
  }
  
  /* Video Player Overlay */
  .videoPlayerOverlay {
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .videoPlayerContent {
    position: relative;
    width: 90%;
    max-width: 1200px;
    background: #000;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    background: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.2s;
  }
  
  .closeButton:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  .videoPlayer {
    width: 100%;
    aspect-ratio: 16/9;
    display: block;
  }
  
  .videoPlayerTitle {
    color: white;
    padding: 16px;
    margin: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  /* Update thumbnail styles */
  .thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Add loading state for thumbnails */
  .thumbnailContainer {
    background: #f0f0f0;
  }
  
  .thumbnail:not([loaded]) {
    opacity: 0;
  }

  /* Template Section Styles */
.templateList {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.templateRow {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.2s;
  cursor: pointer;
}

.templateRow:hover {
  background: #f5f5f5;
}

.templateIcon {
  padding: 8px;
  background: #f0f0f0;
  border-radius: 8px;
  color: #666;
}

.templateInfo {
  flex: 1;
  margin: 0 16px;
}

.templateTitle {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0 0 4px 0;
}

.templateDescription {
  font-size: 12px;
  color: #666;
  margin: 0 0 4px 0;
}

.templateMeta {
  font-size: 12px;
  color: #888;
}

.templateActions {
  display: flex;
  gap: 8px;
  margin-left: 8px;
}

.actionButton {
  background: none;
  border: none;
  padding: 8px;
  border-radius: 6px;
  cursor: pointer;
  color: #666;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actionButton:hover {
  background: #f0f0f0;
  color: #333;
}

.actionButton svg {
  transition: all 0.2s ease;
}

/* Success state for copy button */
.actionButton svg.success {
  color: #4CAF50;
}

/* Optional: Preview functionality */
.templatePreview {
  padding: 16px;
  background: #f9f9f9;
  border-radius: 8px;
  margin-top: 8px;
  white-space: pre-wrap;
  font-family: monospace;
  font-size: 14px;
  display: none;
}

.templateRow.expanded .templatePreview {
  display: block;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .templateRow {
    padding: 8px;
  }

  .templateInfo {
    margin: 0 8px;
  }

  .templateTitle {
    font-size: 13px;
  }

  .templateDescription,
  .templateMeta {
    font-size: 11px;
  }

  .actionButton {
    padding: 6px;
  }
}