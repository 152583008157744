.comments-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  width: 100%; /* Take full width of parent */
}

/* Style for the suggestions title */
.comments-container .title1 {
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #1a1a1a !important;
  margin-bottom: 16px !important;
  font-family: 'Poppins', sans-serif;
  padding: 0 !important;
}

.input-track-notes {
  width: 100%;
  padding: 20px;
  margin: 0 0 16px 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  border: 1px solid #eaeaea;
  cursor: pointer;
  transition: opacity 0.2s ease; /* Add transition for smooth opacity change */
}

/* Style for dimmed comments when one is focused */
.input-track-notes.dimmed {
  opacity: 0.25;
}

/* Style for focused comment */
.input-track-notes.focused {
  opacity: 1;
}

.input-track-notes:hover {
  border-color: #e0e0e0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.timestamp {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
  font-size: 12px;
}

.author {
  font-weight: 500;
  margin-bottom: 4px;
  color: #333;
}

.comment-content {
  color: #555;
  line-height: 1.5;
  font-size: 14px;
}

/* Make sure all inner elements take full width */
.comments-container .row,
.comments-container .col {
  margin: 0;
  padding: 0;
  width: 100%;
} 