/* styleName: Heading 2; */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

[class*="DMSans"],
[class*="Elza"],
[class*="Inter"],
[class*="Thunder"],
[class*="Montserrat"],
[class*="Poppins"] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.Heading1 {
  font-family: ElzaMedium;
  font-weight: 600;
  font-size: 26px;
  line-height: 31px;
  text-align: center;
}

.Heading2 {
  font-family: InterBold;
  font-size: 48px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: 0px;
  text-align: left;
  color: #212529;
}

/*styleName: Subtitle 2 */
.Subtitle2 {
  font-family: InterBold;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
  background: #212529;
}

.DMSans {
  font-family: DMSansBold;
  font-size: 45px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0px;
  text-align: left;
  background: #212529;
}

.Thunder {
  font-family: Thunder;
  font-size: 67px;
  font-weight: 600;
  line-height: 62px;
  letter-spacing: 0em;
  text-align: left;
  background: #212529;
}

/*styleName: Main Header;*/
.Mainheader {
  font-family: DMSansBold;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: left;
}

.Mainheader.sup {
  font-family: ElzaMedium;
  font-size: 26px;
  font-weight: 600;
  line-height: 31.2px;
  letter-spacing: -0.01em;
  text-align: center;
}

.Mainheader1 {
  font-family: DMSansBold;
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: center;
}

.title1 {
  font-family: DMSansBold;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}

.title2 {
  font-family: DMSansBold;
  font-size: 16px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.rating-title2 {
  font-family: DMSansBold;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 5px;
}

.title3 {
  font-family: DMSansMedium;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.title4 {
  font-family: "DMSansRegular";
  font-size: 50px;
  font-weight: 300;
  line-height: 50px;
  text-align: right;
}

.Subtitle1 {
  font-family: DMSansRegular;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.sub-subtitle2 {
  font-family: Montserrat-Regular;
  font-size: 14px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0;
  color: #999999;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.sub-subtitle3 {
  font-family: Poppins-Light;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  color: #999999;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.subtitle3 {
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.subtitle3-submit-popup {
  font-family: ElzaRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.subtitle3-popup {
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
}
.Subtitle2 {
  font-family: DMSansRegular;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.title3 {
  font-family: DMSansRegular;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.Body {
  font-family: DMSansRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.Button1 {
  font-family: DMSansMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
}

.Button2 {
  font-family: DMSansMedium;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
}

.Button3 {
  font-family: DMSansMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: brown;
}

.Homepageheaders {
  font-family: InterBold;
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.4000000059604645px;
  text-align: left;
}

.HomepageDisplay1 {
  font-family: Thunder;
  font-size: 172px;
  font-weight: 600;
  line-height: 143px;
  letter-spacing: 0em;
  text-align: left;
}

.HomepageDisplay2 {
  font-family: Thunder;
  font-size: 116px;
  font-weight: 400;
  line-height: 95px;
  letter-spacing: 0em;
  text-align: left;
}

.HomepageDisplay2 {
  font-family: Thunder;
  font-size: 116px;
  font-weight: 600;
  line-height: 90px;
  letter-spacing: 0em;
  text-align: left;
}

.HomepageDisplay3 {
  font-family: Thunder;
  font-size: 72px;
  font-weight: 500;
  line-height: 64px;
  letter-spacing: 0em;
  text-align: left;
}

.error {
  color: #ff4405;
  position: relative;
  bottom: 9px;
}

.orange-color {
  color: #ff4405;
}

.black-font {
  color: #000000;
}

.toggle-tab-fonts {
  font-family: Elza;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.toggle-btn {
  max-width: fit-content;
  border: none;
  border-radius: 62px;
}

.Button4 {
  font-family: DMSansMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  text-align: center;
  color: #310904;
  width: 111px;
  height: 21px;
}

.Mainheader1 {
  font-family: DM Sans;
  font-size: 26px;
  font-weight: 700;
  line-height: 33.85px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #000000;
}

.Button5 {
  width: 84px;
  height: 48px;
  top: 1908px;
  left: 840px;
  gap: 8px;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  border-color: #310904;
  text-align: center;
  color: #310904;
}

.Button6 {
  font-family: DMSansMedium;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
  color: #ff4405;
}

.black {
  color: white;
  background-color: #141414;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  border-radius: 8px;
  padding: 7px 9px;
}

.orange {
  color: #ff4405;
  font-family: DMSansBold;
  font-size: 26px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  padding-left: 5px;
  cursor: pointer;
}

.notes {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  font-family: DMSansRegular;
}

.repsonsetitle {
  font-family: Montserrat-Regular;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: green;
}
.new-line{
  white-space: pre-line;
}

.track-detail-header {
  font-family: DMSansBold;
  font-size: 22px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: -0.02em;
  text-align: center;
  padding-left: 0.8rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

@media only screen and (max-width: 710px) {
  .text_responsive {
    font-size: 13px;
  }

  .title_responsive {
    font-size: 20px;
  }
}
