.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 34px;
  flex-shrink: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #ff4405;
}

input:checked + .slider:before {
  transform: translateX(46px);
}

.slider-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  transition: 0.4s;
  width: 40px;
  text-align: center;
}

.slider-text-on {
  left: 8px;
  color: white;
  opacity: 0;
}

.slider-text-off {
  right: 8px;
  color: #333;
}

input:checked ~ .slider-text-on {
  opacity: 1;
}

input:checked ~ .slider-text-off {
  opacity: 0;
}

.feedback-groupby .switch {
  flex-shrink: 0;
  margin-right: 10px;
}

.feedback-groupby .SMLLabel {
  flex-grow: 1;
  white-space: normal;
}
