.sup-header-div {
  height: 100px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

@media (max-width: 992px) {
  .sup-header-div {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
}

.collapsible-menu-item {
  position: absolute;
  top: 47px;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 999;
  border-radius: 8px;
}

.collapsible-menu-item-content {
  display: block;
  padding: 18px 37px 4px 34px;
  margin: -10px 1px -8px 0px;
  position: relative;
  top: 2px;
}
.profile-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-btn button {
  border: none;
}
.headerLogo {
  margin-left: 35px;
  height: 80px;
}
.logo {
  margin-left: 15px;
}
.sup-header-div .dashboard-link {
  cursor: pointer;
}
