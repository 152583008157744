.modal-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(5px);
}

.terms-modal-container {
  background: white;
  border-radius: 16px;
  width: 95%;
  max-width: 800px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  overflow: hidden;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.terms-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.terms-modal-header h2 {
  margin: 0;
  font-size: 28px;
  font-weight: 600;
  color: #1a1a1a;
}

.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 28px;
  color: #666;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  border-radius: 50%;
}

.modal-close:hover {
  background-color: #f5f5f5;
  color: #333;
}

.terms-modal-content {
  padding: 20px 24px 32px 24px;
  overflow-y: auto;
  flex: 1;
}

.terms-modal-content h3 {
  margin-top: 32px;
  margin-bottom: 16px;
  font-size: 22px;
  color: #1a1a1a;
  font-weight: 600;
}

.terms-modal-content p {
  margin-bottom: 24px;
  color: #444;
  font-size: 16px;
}

/* Custom scrollbar */
.terms-modal-content::-webkit-scrollbar {
  width: 10px;
}

.terms-modal-content::-webkit-scrollbar-track {
  background: #f5f5f5;
  border-radius: 8px;
}

.terms-modal-content::-webkit-scrollbar-thumb {
  background: #cdcdcd;
  border-radius: 8px;
  border: 2px solid #f5f5f5;
}

.terms-modal-content::-webkit-scrollbar-thumb:hover {
  background: #999;
}

/* Media query for smaller screens */
@media (max-width: 850px) {
  .terms-modal-container {
    width: 95%;
    height: 90vh;
    max-height: 90vh;
    margin: auto;
  }
  
  .modal-overlay {
    padding: 10px;
  }
  
  .terms-modal-header {
    padding: 20px;
  }
  
  .terms-modal-content {
    padding: 20px;
  }
  
  .terms-modal-header h2 {
    font-size: 24px;
  }
}
