.myProfileContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-areas:
    "stats-card stats-card stats-card stats-card stats-card stats-card"
    "stats-card stats-card stats-card stats-card stats-card stats-card"
    "profile-card profile-card profile-card profile-card profile-card profile-card";
  gap: 16px;
  margin-top: 16px;
}
@media screen and (max-width: 1250px) {
  .myProfileContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "stats-card stats-card"
      "stats-card stats-card"
      "profile-card profile-card";
  }
}

@media screen and (max-width: 600px) {
  .myProfileContainer {
    grid-template-columns: 1fr;
    grid-template-areas:
      "stats-card"
      "profile-card";
  }

  .statusCard,
  .profileCard {
    padding: 15px;
  }
}

@media screen and (min-width: 1250px) {
  .myProfileContainer {
    grid-template-columns: 0.5fr 1fr;
    grid-template-areas:
      "stats-card profile-card"
      "stats-card profile-card"
      "stats-card profile-card";
  }
}
.myProfileSubContainer {
  grid-area: stats-card;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.myProfileSubContainer1 {
  grid-area: profile-card;
}

.statusCard {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  display: flex;
  border-radius: 8px 0px 0px 0px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  margin-bottom: 5px;
  border-radius: 10px;
  min-width: max-content;
}
.statusCardPassword {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 25px;
  display: flex;
  flex-direction: column;
  border-radius: 8px 0px 0px 0px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  margin-bottom: 5px;
  border-radius: 10px;
  min-width: max-content;
}

.profileCard {
  position: relative;
  width: 100%;
  align-items: center;
  gap: 100px;
  padding: 25px;
  border-radius: 8px 0px 0px 0px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  margin-bottom: 5px;
  height: auto;
  border-radius: 10px;
}

.tracksContainer {
  width: 45%;
  padding: 4px 0px 8px 4px;
  min-height: 230px;
  max-height: 230px;
  overflow: auto;
}

.tracksEmptyPlaceholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 8px 0px 8px;
}

.tracksSecondaryText {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: var(--sml-label-color);
  padding: 16px 8px;
}

.trackRow {
  padding: 5px 8px 5px 8px;
  gap: 8px;
  border: 0px 0px 0.5px 0px;
  opacity: 0px;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid var(--sml-divider-color);
}

.trackRow h2 {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
}

.trackRow img {
  width: 24px;
  height: 24px;
}

.emailInput {
  border: 1px solid #e9eaec;
  outline: none;
  background-color: transparent;
  width: 100%;
  border-radius: 6px;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.paragraph {
  height: auto;
  padding: 10px;
  font-size: 14px;

  border-radius: 4px;
  box-sizing: border-box;
  overflow-y: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.textarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
  resize: vertical;
  background-color: transparent !important;
  overflow-y: "auto";
  border: none;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-border-input {
  border: 1px solid #e9eaec;
  outline: none;
  background-color: transparent;
  width: 100%;
  border-radius: 6px;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 1300px) {
  .myProfileContainer {
    grid-template-columns: 0.5fr 1fr;
    grid-template-areas:
      "stats-card  profile-card"
      "stats-card  profile-card"
      "stats-card  profile-card"
      "stats-card  profile-card"
      "stats-card profile-card";
  }

  .uploadContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .uploadContainer img {
    width: 85px;
    height: 62px;
  }

  .textarea {
    width: 100%;
    min-height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical;
    font-size: 16px;
    border-radius: 6px;
    background-color: transparent !important;
    border: 1px solid #e9eaec !important;
    overflow-y: "auto";
  }
}
