/* Root level styles for login modal */
.custom-login-modal .modal-content {
  border-radius: 24px;
  border: none;
  background-color: transparent;
  box-shadow: none;
}

.login-card,
.login-card * {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

.Login-close-div {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 25px;
  color: #666666;
  cursor: pointer;
}

.Login-content {
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.login-label-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -10px;
}

.login-confirm-label-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* top: -10px; */
  padding: 0.6rem 0rem;
  border: 2px solid #03c849;
  border-radius: 10px;
  background-color: #ecfef2;
  color: #6a917f;
}

.login-confirm-label-div span {
  color: #03c849;
}

.login-not-confirm-label-div {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  /* top: -10px; */
  padding: 0.6rem 0rem;
  border: 2px solid #c80303;
  border-radius: 10px;
  background-color: #feecec;
  color: #916a6a;
}

.login-not-confirm-label-div span {
  color: #c80303;
}

.login-fields {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.login-fields .error {
  margin-top: 4px;
  margin-bottom: 0;
}

.login-fields1 {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
}

.login-main {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 8px;
  border: 1px;
}

.login-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
}

.login-controls1 {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.login-card-end {
  position: relative;
  display: flex;
  justify-content: center;
}

.login-groupby {
  margin-top: 1rem;
  display: flex;
  align-items: center;
}

.login-groupbyBtns {
  display: flex;
  align-items: center;
  gap: 2px;
  margin: 1rem auto;
}

.login-groupbyBtns.google {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin: 0 auto;
  width: 100%;
}

.login-groupbyBtns.google > div {
  width: 100%;
  max-width: 280px;
}

.social-buttons {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: center;  /* Center the buttons */
}

.social-button-wrapper {
  width: 428px !important;  /* Force exact width */
}

.social-button-wrapper > div {
  width: 428px !important;  /* Force exact width for Google button */
}

.facebook-login-button {
  width: 428px !important;  /* Force exact width for Facebook button */
  height: 40px !important;
  border-radius: 4px !important;
  border: 1px solid #e0e0e0 !important;
  background-color: white !important;
  color: #333 !important;
  font-family: inherit !important;
  font-size: 14px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 8px !important;
  cursor: pointer !important;
  transition: background-color 0.2s, box-shadow 0.2s !important;
}

.facebook-login-button:hover {
  background-color: #f5f5f5 !important;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1) !important;
}

.btn-no-margin {
  background: none;
  border: none;
  cursor: pointer;
}

.btn-no-margin:hover {
  /* text-decoration: underline; */
}

.div-line {
  width: 199.34px;
  height: 1px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  margin: 0 10px;
}

.margin-left {
  margin-left: auto;
}

.login-card {
  border-radius: 24px;
  padding: 2rem;
  background: white;
  border: none;
  position: relative;
  background-color: #fff;
  margin: 0;
}

.login-container {
  background: #121212e5;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
}

.or {
  margin-bottom: 3px;
}

@media only screen and (max-width: 492) {
  .Login-close-div {
    width: 16px;
    height: 16px;
  }

  .Login-content {
    border-radius: 8px;
    border: 1px;
    opacity: 0px;
  }

  .login-label-div {
    width: 70px;
    height: 40.44px;
    top: 114px;
    left: 305px;
    gap: 0px;
    opacity: 0px;
  }

  .login-fields {
    position: relative;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    gap: 8px;
  }

  .login-fields1 {
    position: relative;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
  }

  .login-main {
    width: 486px;
    height: 672px;
    left: 8px;
    gap: 0px;
    border-radius: 8px;
    border: 1px;
    opacity: 0px;
  }

  .login-controls {
    width: 428px;
    height: 204px;
    top: 248.93px;
    left: 126px;
  }

  .login-controls1 {
    width: 486px;
    height: 128.43px;
    top: 610px;
    left: 97px;
    border-radius: 8px;
    border: 1px;
  }

  .login-card-end {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .login-groupby {
    margin-top: 1rem;
    display: flex;
    align-items: center;
  }

  .login-groupbyBtns {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    gap: 40px;
  }

  .btn-no-margin {
    background: none;
    border: none;
    cursor: pointer;
  }

  .btn-no-margin:hover {
    /* text-decoration: underline; */
  }

  .div-line {
    width: 199.34px;
    height: 1px;
    border: 1px solid #e9eaec;
    background: #e9eaec;
    margin: 0 10px;
  }

  .margin-left {
    margin-left: auto;
  }

  .login-card {
    border-radius: 24px;
    background: white;
    border: 1px solid #e9eaec;
    position: relative;
  }

  .login-container {
    background: #121212e5;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .google {
    flex-direction: column;
    gap: 15px;
    margin: 0;
  }

  .social-button-wrapper,
  .social-button-wrapper > div,
  .facebook-login-button {
    width: 100% !important;  /* Full width on mobile */
  }
}

.passwordfield {
  position: relative;
}

.toggle-password-visibility-login {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.forgotPassword {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.questionMark {
  margin-left: -2px;
  margin-top: -3px;
  /* font-size: 16px; */
}

@media only screen and (max-width: 492px) {
  .login-controls1 {
    max-width: 100%;
  }

  .google {
    flex-direction: column;
    gap: 15px;
    margin: 0;
  }
}

.error {
  color: #ff3b30;
  font-size: 14px;
  margin-top: 4px;
  text-align: left;
  width: 100%;
  padding-left: 4px;
}

/* Update error message styling for social login */
.error-message {
  color: #ff3b30;
  font-size: 14px;
  text-align: center;
  width: 100%;
  max-width: 280px;
  margin: 8px auto;
  padding: 8px;
  background-color: #fff5f5;
  border-radius: 4px;
}

.not-member-text,
.already-member-text {
  color: #666666;
  font-size: 14px;
}

.signup-link,
.login-link {
  color: #1a1a1a;
  font-size: 14px;
  background: none;
  border: none;
  padding: 0;
  margin-left: 4px;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
}

.forgot-password-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
}

/* Remove any padding from modal body */
.custom-login-modal .modal-body {
  padding: 0;
}