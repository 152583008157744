.recorder-container {
    position: fixed;
    bottom: 120px;
    right: 20px;
    background: black;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 500px;
    min-width: 400px;
    height: 350px;
    resize: both;
    overflow: hidden;
    cursor: move;
    user-select: none;
    touch-action: none;
  }
  
  .recorder-container.dragging {
    opacity: 0.9;
    cursor: grabbing !important;
    transition: none !important;
  }
  
  .controls, .close-button, .settings-button {
    cursor: pointer;
    z-index: 1001;
  }
  
  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    z-index: 2;
  }
  
  .close-button {
    position: absolute !important;
    top: 6px !important;
    right: 8px;
    z-index: 10;
    color: white !important;
    background: rgba(0, 0, 0, 0.5) !important;
    transition: background 0.2s ease-in-out !important;
  }
  
  .close-button:hover {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  
  .preview-window {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: black;
    display: block;
    z-index: 1;
  }
  
  .record-button {
    color: #ff4405 !important;
    transform: scale(1.2);
    background: rgba(0, 0, 0, 0.3) !important;
  }
  
  .controls .MuiIconButton-root {
    color: white;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .controls .MuiIconButton-root:hover {
    background: rgba(0, 0, 0, 0.5);
  }
  
  /* Add styles for picture-in-picture effect */
  .pip-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 200px;
    height: 150px;
    z-index: 1001;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Add resize handle indicator */
  .resize-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 15px;
    height: 15px;
    cursor: se-resize;
    background: linear-gradient(
      135deg,
      transparent 0%,
      transparent 50%,
      rgba(255, 255, 255, 0.5) 50%,
      rgba(255, 255, 255, 0.5) 100%
    );
  }
  
  .recorded-preview {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .recorded-preview .preview-window {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  /* Add animation for transitions */
  .recorder-container > * {
    transition: opacity 0.3s ease-in-out;
  }
  
  /* Optional: Add a recording indicator */
  @keyframes recording-pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
  
  .recording-indicator {
    position: absolute;
    top: 16px;
    left: 16px;
    width: 12px;
    height: 12px;
    background: #ff4405;
    border-radius: 50%;
    animation: recording-pulse 1.5s infinite;
  }
  
  /* Add styles for recording indicator */
  .recording-active {
    position: relative;
  }
  
  .recording-active::before {
    content: '';
    position: absolute;
    top: 16px;
    left: 16px;
    width: 12px;
    height: 12px;
    background: #ff4405;
    border-radius: 50%;
    animation: recording-pulse 1.5s infinite;
  }
  
  /* Optional: Add styles for the PIP preview */
  .pip-preview {
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 20%;
    aspect-ratio: 4/3;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }
  
  /* Update device selection controls styles */
  .recorder-container .MuiFormControl-root {
    margin: 4px;
  }
  
  .recorder-container .MuiSelect-select {
    color: white;
    padding: 8px 14px;
    background: rgba(0, 0, 0, 0.5);
  }
  
  .recorder-container .MuiInputLabel-root {
    color: white;
    font-size: 0.875rem;
  }
  
  .recorder-container .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.3);
  }
  
  /* Make dropdown items more compact */
  .MuiMenuItem-root {
    min-height: 32px !important;
    font-size: 0.875rem !important;
  }
  
  /* Ensure dropdowns appear above the video */
  .MuiSelect-root {
    z-index: 3;
  }
  
  /* Hover states */
  .recorder-container .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.5);
  }
  
  /* Dropdown menu styling */
  .MuiMenu-paper {
    background-color: #2f2f2f !important;
    color: white !important;
  }
  
  .MuiMenuItem-root {
    color: white !important;
    min-height: 32px !important;
    font-size: 0.875rem !important;
  }
  
  .MuiMenuItem-root:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
  }
  
  /* Device selection positioning */
  .device-controls {
    position: absolute;
    top: 8px;
    left: 8px;
    right: 40px; /* Leave space for close button */
    display: flex;
    gap: 8px;
    z-index: 2;
  }
  
  /* Adjust close button position if needed */
  .close-button {
    position: absolute !important;
    top: 6px !important;
    right: 8px;
    z-index: 10;
  }
  
  .settings-button {
    position: absolute !important;
    top: 10px !important;
    right: 40px !important;
    z-index: 10;
    color: white !important;
    background: rgba(0, 0, 0, 0.5) !important;
  }
  
  .settings-button:hover {
    background: rgba(0, 0, 0, 0.7) !important;
  }
  
  /* Settings panel styling */
  .settings-controls {
    position: absolute;
    top: 100px;
    left: 175px;
    width: 250px;
    background: rgba(0, 0, 0, 0.95);
    z-index: 1500;
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;  
    gap: 16px;
  }
  
  /* Style for the panel title */
  .settings-controls div:first-child {
    font-size: 16px;
    margin-bottom: 8px;
    width: 100%;
  }
  
  /* Style for the device messages */
  .settings-controls div {
    color: white;
    font-size: 14px;
  }
  
  .settings-controls > div {
    margin-bottom: 8px;
  }
  
  .settings-controls .MuiSelect-root {
    z-index: 10000 !important;
  }
  
  .MuiPopover-root {
    z-index: 10001 !important;
  }
  
  .MuiMenu-paper {
    z-index: 10001 !important;
  }
  
  /* Optional: Add animation for settings panel */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  .settings-controls {
    animation: fadeIn 0.2s ease-in-out;
  }
  
  /* Center record button controls */
  .record-controls {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;  /* Increased gap between buttons */
    z-index: 2;
  }
  
  .upload-button {
    color: white !important;
    transform: scale(1.2);
    background: rgba(0, 0, 0, 0.3) !important;
  }
  
  .upload-button:hover {
    background: rgba(0, 0, 0, 0.5) !important;
  }
  
  /* Optional: Add a drag-and-drop zone */
  .drag-drop-zone {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.8);
    border: 2px dashed rgba(255, 255, 255, 0.3);
    z-index: 1;
  }
  
  .drag-drop-zone.active {
    background: rgba(0, 0, 0, 0.9);
    border-color: #ff4405;
  }
  
  /* Bottom controls for preview/upload */
  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    z-index: 2;
  }
  
  .record-button {
    color: #ff4405 !important;
    transform: scale(1.2);
    background: rgba(0, 0, 0, 0.3) !important;
  }
  
  .success-message {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 3;
  }
  
  .success-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    color: white;
    text-align: center;
  }
  
  .success-content:hover {
    opacity: 0.8;
  }
  
  /* Add after your existing styles */
  .upload-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .upload-progress-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .upload-text {
    color: white;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .progress-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.2);
  }
  
  .progress-bar-fill {
    height: 100%;
    background: #FF4405;
    transition: width 0.3s ease;
  }
  
  /* Ensure dropdowns are visible */
  .MuiPopover-root {
    z-index: 1002 !important;
  }
  
  .MuiSelect-root {
    z-index: 1002 !important;
  }
  
  /* Update the settings controls styles */
  .settings-controls {
    position: absolute;
    left: 8px;
    top: 50px;
    background: rgba(0, 0, 0, 0.95);
    padding: 16px;
    border-radius: 8px;
    width: 250px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  }
  
  /* Update dropdown styles */
  .settings-controls .MuiFormControl-root {
    width: 100%;
    margin-bottom: 8px;
    position: relative;
    z-index: 10000 !important;
  }
  
  /* Ensure the Select dropdown menu appears above everything */
  .MuiPopover-root {
    z-index: 1400 !important;
  }
  
  .MuiMenu-paper {
    z-index: 1400 !important;
    background-color: rgba(0, 0, 0, 0.95) !important;
  }
  
  /* Add backdrop for settings panel */
  .settings-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1200;
  }
  
  /* Ensure the select dropdowns are visible */
  .settings-controls .MuiSelect-select {
    color: white !important;
    z-index: 1300 !important;
  }
  
  .settings-controls .MuiOutlinedInput-root {
    color: white !important;
  }
  
  .settings-controls .MuiInputLabel-root {
    color: white !important;
  }
  
  /* Add these styles */
  .MuiModal-root {
    z-index: 9999 !important;
  }
  
  .MuiPopover-root {
    z-index: 99999 !important;
  }
  
  .MuiSelect-root {
    z-index: 99999 !important;
  }
  
  /* Add a backdrop for better visibility */
  .settings-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1400;
  }
  
  /* Prevent text selection during drag */
  .recorder-container * {
    user-select: none;
  }
  