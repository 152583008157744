.SMLError {
  color: red;
  text-align: left;
}

.SMLErrorBox {
  /* position: absolute; */
  padding-left: 0.5rem;
  top: -15%;
  left: 0;
}
.SMLErrorBox.signup {
  top: 104%;
  left: 74%;
}
