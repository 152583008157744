.plan-signup-card {
  width: 1058px;
  height: 788px;
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  position: relative;
}
.plan-signUp-container {
  background: #121212e5;
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.plan-cardHeader {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  width: 377.16px;
  height: 22px;
  top: 122px;
  left: 70px;
  color: #000000;
}
.plan-signUp-content {
  width: 495px;
  height: 500.56px;
  top: 78.44px;
  left: 51px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;
}
.plan-signUp-right-content {
  width: 440px;
  height: 400.56px;
  top: 160.55px;
  left: 100px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;
}

.plan-signUp-label-div {
  width: 486px;
  height: 34px;
  top: 59px;
  left: 51px;
  position: relative;
}
.plan-signUp-close-div {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  margin: 5px 15px 0px 15px;
  left: 175px;
}
.plan-signUp-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 1000px;
  height: 571px;
  position: relative;
  border-radius: 8px;
  border: 1px;
}

.plan-signUp-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.plan-signUp-fields {
  width: 495px;
  height: 77px;
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}
.plan-signUp-groupby {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 3px;
}

.plan-signUp-grid {
  display: grid;
  grid-template-columns: 495px 10px 495px;
  grid-gap: 8px;
  width: 1000px;
  height: 571px;
  position: relative;
  border-radius: 8px;
  border: 1px;
}

.plan-div-vertical-line {
  width: 1px;
  height: 200px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  margin: 0 10px;
}
.plan-divider {
  border-left: 6px solid green;
  height: 200px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  width: 1px;
}

.plan-signUp-footer {
  padding: 50px;
}
.plan-signUp-progress-steps {
  width: 600px;
  margin: 10px;
  margin-left: 200px;
}
.plan-signUp-back {
  width: 111px;
  height: 37px;
  top: 619px;
  left: 32px;
  padding: 8px;
  gap: 8px;
}
