.confirm-mail-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: #333;
    font-family: Arial, sans-serif;
    border-radius: 20px;
    padding: 5rem 0;
  }
  
  h1 {
    font-size: 2rem;
    color: #444;
    margin-bottom: 20px;
  }
  
  .loading-message {
    font-size: 1.2rem;
    color: #007bff;
  }
  
  .success-message {
    font-size: 1.2rem;
    color: #28a745;
  }
  
  .error-message {
    font-size: 1.2rem;
    color: #dc3545;
  }
  