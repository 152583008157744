.custom-time-picker {
  appearance: none;
  width: 75px;
  padding: 8px 12px;
  font-family: DM Sans;
  border-radius: 5px;
  border: 1px solid #23f0c7;
  resize: none;
  font-size: 16px;
  line-height: normal;
  text-align: center;
}

.custom-time-picker:focus {
  box-shadow: 0 0 5px #23f0c7;
  outline: none;
  caret-color: transparent;
}
