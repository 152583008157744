.artist-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

/* Add z-index to ensure modal appears above other content */
.custom-modal {
  z-index: 1050;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.artist-item {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 24px;
  transition: all 0.2s ease;
}

.artist-item:hover {
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.artist-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 24px;
}

.artist-info {
  flex: 1;
}

.artist-info .title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0 0 8px 0;
}

.artist-info .roles {
  margin-bottom: 12px;
}

.role-tag {
  color: #4B5563;
  font-size: 14px;
}

.artist-info .description {
  color: #6B7280;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.artist-meta {
  min-width: 200px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.meta-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.meta-item .label {
  color: #6B7280;
  font-size: 14px;
}

.meta-item .value {
  color: #111827;
  font-size: 14px;
  font-weight: 500;
}

.view-profile-btn {
  background: #FF4405;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.view-profile-btn:hover {
  background: #E03D04;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.pagination-btn {
  background: white;
  border: 1px solid #d1d5db;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-btn:hover:not(:disabled) {
  background: #f9fafb;
  border-color: #9ca3af;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  color: #6B7280;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .artist-content {
    flex-direction: column;
  }

  .artist-meta {
    width: 100%;
  }

  .meta-item {
    justify-content: flex-start;
  }
}

/* Search input styles */
.search-container {
  flex: 1;
  max-width: 600px;
  margin: 0 24px;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  height: 36px;
  padding: 8px 40px 8px 20px;
  border-radius: 18px;
  border: 1px solid #e5e7eb;
  background: #f9fafb;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #FF4405;
  box-shadow: 0 0 0 2px rgba(255, 68, 5, 0.1);
}

.search-clear-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #6b7280;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.search-clear-button:hover {
  background: #e5e7eb;
}

.artist-table {
  width: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  overflow: hidden;
}

.artist-table-header {
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.8fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.8fr;
  gap: 16px;
  padding: 12px 16px;
  background: #f9fafb;
  border-bottom: 1px solid #e5e7eb;
}

.header-cell {
  font-weight: 600;
  color: #4b5563;
  font-size: 14px;
}

.artist-table-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr 0.8fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.8fr;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.artist-table-row:last-child {
  border-bottom: none;
}

.artist-table-row:hover {
  background: #f9fafb;
}

.table-cell {
  font-size: 14px;
  color: #111827;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roles-cell {
  white-space: normal;
  line-height: 1.4;
}

.role-tag {
  color: #4B5563;
  font-size: 14px;
}

.plan-badge {
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

.plan-badge.basic {
  background-color: #ecfdf5;
  color: #059669;
  border: 1px solid #a7f3d0;
}

.plan-badge.pro {
  background-color: #eff6ff;
  color: #3b82f6;
  border: 1px solid #bfdbfe;
}

.plan-badge.partner {
  background-color: #f5f3ff;
  color: #7c3aed;
  border: 1px solid #ddd6fe;
}

/* Default style for free/no subscription */
.plan-badge:not(.basic):not(.pro):not(.partner) {
  background-color: #f3f4f6;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.sync-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.sync-badge.yes {
  background: #D1FAE5;
  color: #065F46;
}

.sync-badge.no {
  background: #FEE2E2;
  color: #991B1B;
}

.view-profile-btn {
  background: #FF4405;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.view-profile-btn:hover {
  background: #E03D04;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #e5e7eb;
}

.pagination-btn {
  background: white;
  border: 1px solid #d1d5db;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.pagination-btn:hover:not(:disabled) {
  background: #f9fafb;
  border-color: #9ca3af;
}

.pagination-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.pagination-info {
  color: #6B7280;
  font-size: 14px;
}

/* Search input styles */
.search-container {
  flex: 1;
  max-width: 600px;
  margin: 0 24px;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  height: 36px;
  padding: 8px 40px 8px 20px;
  border-radius: 18px;
  border: 1px solid #e5e7eb;
  background: #f9fafb;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #FF4405;
  box-shadow: 0 0 0 2px rgba(255, 68, 5, 0.1);
}

.search-clear-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #6b7280;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.search-clear-button:hover {
  background: #e5e7eb;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .artist-table-header,
  .artist-table-row {
    grid-template-columns: 1.5fr 1fr 0.8fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.8fr;
  }

  .details-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 992px) {
  .artist-table {
    overflow-x: auto;
  }

  .artist-table-header,
  .artist-table-row {
    min-width: 900px;
  }

  .expanded-content {
    flex-direction: column;
  }

  .details-grid {
    grid-template-columns: 1fr;
  }
}

/* Track stats styles */
.track-stat {
  font-weight: 500;
  text-align: center;
}

/* Expanded row styles */
.artist-table-row {
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
}

.artist-table-row:hover {
  background-color: #f8f9fa;
}

.artist-table-row.expanded {
  background-color: #f8f9fa;
  border-bottom: none;
  position: relative;
}

.artist-table-row.expanded::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1px;
  height: 2px;
  background: linear-gradient(90deg, #FF4405 0%, #FF8A65 100%);
}

.expanded-row {
  background-color: #fff;
  border-bottom: 1px solid #e5e7eb;
  padding: 16px;
  position: relative;
}

.expanded-row::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: #e5e7eb;
}

.expanded-content {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 25% 25%;
  gap: 24px;
}

.expanded-section {
  background: #fff;
  padding: 16px;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.expanded-section h4 {
  font-size: 12px;
  font-weight: 600;
  color: #374151;
  margin-bottom: 16px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-bottom: 8px;
  border-bottom: 1px solid #e5e7eb;
  position: relative;
  display: flex;
  align-items: center;
}

.expanded-section h4::before {
  content: '';
  width: 3px;
  height: 16px;
  background: #FF4405;
  margin-right: 8px;
  border-radius: 2px;
}

/* Artist Details Grid */
.details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
  padding: 0 8px;
}

.detail-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px;
  background: #f8fafc;
  border-radius: 6px;
}

.detail-item.full-width {
  grid-column: 1 / -1;
}

.detail-item label {
  font-size: 11px;
  color: #6b7280;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.detail-item span {
  font-size: 13px;
  color: #111827;
  font-weight: 400;
  line-height: 1.5;
}

/* Personal Links section */
.links-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  padding: 0 8px;
}

.personal-link {
  color: #2563eb;
  font-size: 13px;
  text-decoration: none;
  padding: 8px 12px;
  background: #f8fafc;
  border-radius: 6px;
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid #e2e8f0;
}

.personal-link:hover {
  background: #f1f5f9;
  border-color: #cbd5e1;
}

/* Subscription Details section */
.subscription-grid {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 12px;
  padding: 0 8px;
}

.subscription-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 12px;
  background: #f8fafc;
  border-radius: 6px;
  border: 1px solid #e2e8f0;
}

.subscription-item label {
  font-size: 11px;
  color: #6b7280;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.subscription-item span {
  font-size: 13px;
  color: #111827;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .expanded-content {
    grid-template-columns: 1fr 1fr;
    gap: 16px;
  }

  .expanded-section:last-child {
    grid-column: 1 / -1;
  }

  .subscription-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }
}

@media (max-width: 768px) {
  .expanded-content {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .expanded-section {
    padding: 12px;
  }

  .details-grid {
    grid-template-columns: 1fr;
    padding: 0 4px;
  }

  .subscription-grid {
    grid-template-columns: 1fr;
    padding: 0 4px;
  }

  .links-list {
    padding: 0 4px;
  }

  .expanded-row {
    padding: 12px;
  }
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: capitalize;
}

.status-badge.active {
  background-color: #dcfce7;
  color: #166534;
}

.status-badge.inactive {
  background-color: #fee2e2;
  color: #991b1b;
}

.status-badge.pending {
  background-color: #fef3c7;
  color: #92400e;
}

.status-badge.cancelled {
  background-color: #f3f4f6;
  color: #374151;
}

/* Status tag styles */
.status-tag {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.85rem;
  font-weight: 500;
  text-transform: capitalize;
  display: inline-block;
}

.status-tag.deleted {
  background-color: #fee2e2;
  color: #991b1b;
}

.status-tag.disabled {
  background-color: #f3f4f6;
  color: #374151;
}

.status-tag.active {
  background-color: #dcfce7;
  color: #166534;
}

.detail-item.full-width {
  grid-column: 1 / -1;
}

/* Update responsive layout */
@media (max-width: 1200px) {
  .artist-table-header,
  .artist-table-row {
    grid-template-columns: 1.5fr 1fr 0.8fr 0.7fr 0.7fr 0.7fr 0.7fr 0.7fr 1fr 0.8fr;
  }
}

.date-cell {
  font-size: 13px;
  color: #4b5563;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.total-count {
  font-size: 1rem;
  color: #666;
  font-weight: 500;
}