@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 68, 5, 0.4);
    border-color: rgba(255, 68, 5, 0.6);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(255, 68, 5, 0);
    border-color: rgba(255, 68, 5, 0.2);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 68, 5, 0);
    border-color: rgba(255, 68, 5, 0.6);
  }
}

.stripWrapper {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.stripContainer {
  width: 100%;
  background: white;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.stripContainer:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.contentWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
}

.content {
  display: flex;
  align-items: center;
  gap: 16px;
  flex: 1;
}

.swipeOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(90deg, 
    transparent 0%,
    rgba(255, 68, 5, 0.1) 40%,
    rgba(255, 68, 5, 0.2) 50%,
    rgba(255, 68, 5, 0.1) 60%,
    transparent 100%
  );
  transform: translateX(-100%);
  animation: swipe 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  pointer-events: none;
}

.slideAnimation {
  animation: slideOut 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.slideContent {
  animation: fadeContent 10s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

@keyframes swipe {
  0%, 80% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slideOut {
  0%, 80% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50px);
  }
}

@keyframes fadeContent {
  0%, 80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.rightContent {
  display: flex;
  align-items: center;
  gap: 16px;
}

.timer {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 13px;
  font-weight: 600;
  padding: 4px 12px;
  border-radius: 20px;
}

.tag {
  font-size: 12px;
  padding: 4px 12px;
  border-radius: 6px;
  font-weight: 600;
  text-transform: uppercase;
  color: white;
  display: flex;
  align-items: center;
  gap: 6px;
  background: #3b82f6;
}

.tag-normal {
  background: #3b82f6;
  box-shadow: 0 2px 4px rgba(59, 130, 246, 0.2);
}

.tag-warning {
  background: #f59e0b;
  box-shadow: 0 2px 4px rgba(245, 158, 11, 0.2);
}

.tag-urgent {
  background: #FF4405;
  box-shadow: 0 2px 4px rgba(255, 68, 5, 0.2);
}

.tag-expired {
  background: #6b7280;
  box-shadow: none;
}

.timer-normal {
  color: #3b82f6;
  background: rgba(59, 130, 246, 0.1);
}

.timer-warning {
  color: #f59e0b;
  background: rgba(245, 158, 11, 0.1);
}

.timer-urgent {
  color: #FF4405;
  background: rgba(255, 68, 5, 0.1);
}

.timer-expired {
  color: #6b7280;
  background: rgba(107, 114, 128, 0.1);
}

.expiryDate {
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid currentColor;
  opacity: 0.8;
  font-weight: normal;
}

.title {
  font-weight: 600;
  font-size: 14px;
  color: #111827;
}

.description {
  font-size: 14px;
  color: #6b7280;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 500px;
}

.viewMore {
  display: flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  color: #FF4405;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.viewMore:hover {
  background: rgba(255, 68, 5, 0.05);
  transform: translateX(2px);
}

.viewMore span {
  font-size: 13px;
}

/* Urgency-based styles with red pulsing border */
.normal {
  border: 1px solid rgba(255, 68, 5, 0.6);
  animation: pulse 2s infinite cubic-bezier(0.4, 0, 0.6, 1);
}

.warning {
  border: 1px solid rgba(255, 68, 5, 0.6);
  animation: pulse 2s infinite cubic-bezier(0.4, 0, 0.6, 1);
}

.urgent {
  border: 1px solid rgba(255, 68, 5, 0.6);
  animation: pulse 2s infinite cubic-bezier(0.4, 0, 0.6, 1);
}

.expired {
  border: 1px solid rgba(107, 114, 128, 0.6);
  animation: none;
}

.navButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.9);
  border: 1px solid #e5e7eb;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s;
  z-index: 1;
}

.navButton:hover {
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.navButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.navButton:first-child {
  left: -16px;
}

.navButton:last-child {
  right: -16px;
}

.briefIndicators {
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 6px;
  z-index: 1;
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.activeIndicator {
  background: rgba(255, 68, 5, 0.4);
  transform: scale(1.2);
}

.slide-next {
  animation: slideNext 0.4s ease-in-out;
}

.slide-prev {
  animation: slidePrev 0.4s ease-in-out;
}

@keyframes slideNext {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(-10%);
    opacity: 0;
  }
  51% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slidePrev {
  0% {
    transform: translateX(0);
    opacity: 1;
  }
  50% {
    transform: translateX(10%);
    opacity: 0;
  }
  51% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.activeIndicator .progressBar {
  width: 100%;
  height: 100%;
  background: rgba(255, 68, 5, 0.3);
  animation: progress 5s linear;
}

.flipAnimation {
  animation: flip 0.6s ease-in-out;
  backface-visibility: hidden;
}

@keyframes flip {
  0% {
    transform: perspective(400px) rotateX(0);
    opacity: 1;
  }
  40% {
    transform: perspective(400px) rotateX(-10deg);
    opacity: 0.6;
  }
  70% {
    transform: perspective(400px) rotateX(10deg);
    opacity: 0.6;
  }
  100% {
    transform: perspective(400px) rotateX(0);
    opacity: 1;
  }
}

@keyframes slideContent {
  0%, 90% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-20px);
    opacity: 0;
  }
}