.rate-bar-container {
  width: 100%;
  min-width: 200px;
  display: flex;
  justify-content: center;
}
.rate-bar-slider {
  width: 100%;
}
.rate-bar-slider .MuiSlider-thumb {
  color: #000000 !important;
  position: relative;
}
.rate-bar-slider .MuiSlider-thumb:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7px;
  height: 7px;
  background-color: #ffffff;
  border-radius: 50%;
  transform: translate(-50%, -50%);
}
.rate-bar-slider-user .MuiSlider-thumb {
  display: none;
}
.rate-bar-slider-user .MuiSlider-thumb:before {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
}
.rate-bar-slider .MuiSlider-rail {
  color: #d1d1d1;
  height: 4px;
  border-radius: 5px;
}
.rate-bar-slider-user .MuiSlider-rail {
  color: #d1d1d1;
  height: 8px;
  border-radius: 5px;
}
.rate-bar-slider .MuiSlider-track {
  color: #ff4405;
}
.rate-bar-slider-user .MuiSlider-track {
  color: #ff4405;
  height: 25%;
}
.rate-bar-slider .MuiSlider-markLabel {
  color: #d1d1d1 !important;
  margin-left: 10px;
}
.rate-bar-slider-user .MuiSlider-markLabel {
  color: #d1d1d1 !important;
}
