.trackSectionMain {
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  padding: 0 10px;
  border-radius: 10px;
}
.trackRow {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 900px) {
  .track-name {
    width: 14%;
  }
}
.artist-plan-type {
  position: relative;
  display: inline-block;
  font-size: 0.8rem;
  padding: 0.4rem 1rem; /* Adjust padding for inner content */
  border-radius: 50px; /* Apply rounded corners */
  color: black;
  font-weight: 600;
  z-index: 1;
  background-color: white; /* Default fallback color */
}

.artist-plan-type.basic {
  background-color: #c1efce; 
  color: green;
}
.artist-plan-type.pro {
  background-color: #87bffe; 
 color: blue;
}
.artist-plan-type.partner {
  background-color: #e298de; 
  color: rgb(120, 14, 202);
}
.track-title,
.track-artist,
.track-info,
.track-status {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.track-title {
  font-family: DMSansBold;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  display: -webkit-box; /* Use flexbox for wrapping */
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis if truncated */
  white-space: normal; /* Allow wrapping within 2 lines */
  word-break: break-word; /* Handle long words */
  cursor: pointer; /* Optional: indicate it's interactive */
}
@media screen and (max-width: 1200px) {
  .track-title {
    max-width: 100px;
  }
}


.trackTitleSup {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.trackTitleSup h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Add keyframes for the processing issue animation */
@keyframes fadeInBlink {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

.trackSectionProcessingIssue {
  border: 1px solid rgba(255, 68, 5, 0.3) !important;
  background-color: rgba(255, 68, 5, 0.03) !important;
  position: relative;
  transition: box-shadow 0.3s ease;
}

.trackSectionProcessingIssue:hover {
  box-shadow: 0 0 8px rgba(255, 68, 5, 0.2);
}

.processingIssueTag {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff0f0;
  color: #FF4405;
  padding: 4px 10px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  animation: fadeInBlink 2s infinite ease-in-out;
  margin-left: auto;
  box-shadow: 0 1px 3px rgba(255, 68, 5, 0.2);
  border: 1px solid rgba(255, 68, 5, 0.2);
}