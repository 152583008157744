.upgrade-welcome-main {
  width: 40vw;
  height: 45vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.card-body {
  padding: 20px;
}

.card-text {
  font-size: 16px;
}

.viewPlan-container {
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewPlan-card {
  width: 960px;
  height: 660px;
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.plans-main {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.viewPlan-label-div {
  position: relative;
  color: #141414;
}

.viewPlan-close-div {
  right: 1rem;
  padding: 1px 0px 0px 0px;
  gap: 8px;
  position: absolute;
  font-size: 28px;
  color: #000000;
  cursor: pointer;
  z-index: 9;
}

.viewPlan-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  border: 1px;
  justify-content: center;
  align-items: center;
}

.viewPlan-grid {
  display: grid;
  grid-template-columns: 433px 433px 433px;
  grid-gap: 8px;
  width: 1300px;
  height: 580px;
  position: relative;
  border-radius: 8px;
  border: 1px;
}

.viewPlan-duration {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 4px;
  font-size: -webkit-xxx-large;
  font-weight: 600;
}

.viewPlan-horizontal-line {
  border-top: 1px solid white;
  margin: 10px 0;
}

.viewPlan-card-content {
  width: 232px;
  height: 263px;
  top: 168px;
  left: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-family: "DMSans";
  font-weight: 400;
  color: #141414;
}

.custom-button {
  width: 157px;
  height: 48px;
  font-family: "DM Sans";
  top: 390px;
  left: 54px;
  padding: 11px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;
  background: #ffffff;

  position: absolute;
  border-style: none;
  font-weight: 500;
}

.last-card-button {
  width: 129px;
  height: 48px;
  font-family: "DM Sans";
  top: 390px;
  left: 54px;
  padding: 11px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;
  background: #141414;
  color: #fff;
  position: absolute;
  border-style: none;
  font-weight: 500;
}

.first-card-button {
  width: 150px;
  height: 48px;
  font-family: "Elza";
  top: 390px;
  left: 54px;
  padding: 11px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;

  color: #121212;
  position: absolute;
  border-style: none;
  font-weight: 400;
}

.payment-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 30px 0px 30px 0px;
  border-radius: 8px;
}

.payment-cards {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 448px;
  width: 268px;
  margin: 70px 0px 30px 25px;
  border-radius: 8px;
  left: 65px;
  background-color: #e1e3e6 !important;
  border-style: none;
}

.card-Header {
  margin: 30px;
  text-align: center;
}

.feature-with-checkbox {
  display: flex;
  gap: 10px;
}

.selected-card {
  color: #ffffff;
}
.plan-toggle {
  margin: auto 0;
}
.plans-main-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.price-dollar {
  font-size: 2rem;
  font-weight: 700;
}

.price-whole {
  font-size: 2.2rem;
  font-weight: 700;
}

.price-decimal {
  font-size: 1.2rem;
  font-weight: normal;
  vertical-align: super;
  position: relative;
  top: -0.5rem;
}

.highlight-block {
  background: rgba(255, 68, 5, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  position: relative;
  border: 1px solid rgba(255, 68, 5, 0.15);
}

.highlight-label {
  color: #FF4405;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.highlight-value {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #FF4405;
  font-weight: 600;
  font-size: 14px;
}

.plan-old-price {
  font-size: 1.8em;
  color: #ff4405;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  text-decoration: none;
}

.plan-old-price::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ff4405;
  top: 50%;
  left: 0;
  transform: rotate(-10deg);
  transform-origin: center;
}

.highlight-tags {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 0;
}

.price-tags {
  position: absolute;
  top: -12px;
  right: -5px;
  display: flex;
  gap: 4px;
  z-index: 2;
}

.price-tag {
  height: 24px;
  width: auto;
  object-fit: contain;
}

.selected-card .price-tag {
  filter: brightness(0) invert(1);
}

.selected-card .highlight-block {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.selected-card .highlight-label,
.selected-card .highlight-value {
  color: #ffffff;
}
