.SMLTextbox {
  width: 100%;
  // max-width: 468px;
  height: 48px;
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  background-color: #f5f7f9 !important;
  border: none;
}
.SMLTextbox:hover {
  border: none;
}
.SMLButton {
  height: 48%;
  top: 16px;
  left: 15px;
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  background: #ff4405;
  border: none;
  color: #000 !important;
}

.SMLCheckbox {
  width: 37px;
  height: 24px;
  border-radius: 5px;
  border: 1px;
  background: #ffffff;
  border: 1px solid #e9eaec;
}

input[type="checkbox"]:checked {
  accent-color: #23f0c7;
  width: 37px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid #e9eaec;
}

.SMLSignupbutton {
  width: 100%;
  max-width: 239px;
  height: 56px;
  top: 16px;
  left: 16px;
  border-radius: 8px;
  border: 1px;
  gap: 8px;
}

.input-box {
  width: 428px;
  height: 77px;
  top: 129.44px;
  left: 80px;
  gap: 8px;
}
