.parallaxPlan-container,
.heading,
.plan-card,
.plan-price,
.feature-text,
.plan-button,
.price-container,
.plan-duration {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

.parallaxPlan-container {
  position: relative;
  width: 100%;
  min-height: 100dvh;
  padding: 0 0 10dvh 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.parallaxPlan-main {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  border-radius: 8px;
}

.heading {
  font-size: 3.5em;
  font-weight: 500 !important;
  text-align: center;
  color: #141414;
  font-family: Thunder-Medium;
  margin-top: 0.7em;
  margin-bottom: 1em;
}

.toggle-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  margin-bottom: 24px;
}

.toggle-buttons button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: #141414;
  color: #fff;
}

/* General Layout: 3 Cards Per Row */
.plan-cards-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 cards per row */
  gap: 24px; /* Space between cards */
  justify-content: center; /* Center-align the grid */
}

.plan-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #e9eaec;
  position: relative;
  height: 100%;
  overflow: visible;
}

.plan-card[data-layout="full-width"] {
  grid-column: 1 / -1;
  width: 100%;
  justify-self: center;
  margin: 0 auto;
}

/* Base card content centering */
.plan-card[data-layout="full-width"] .plan-card-content {
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

/* Feature list container */
.plan-card[data-layout="full-width"] .features-list {
  max-width: 600px;
  margin: 0 auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Feature items alignment */
.plan-card[data-layout="full-width"] .features-item {
  width: 100%;
  max-width: 600px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 10px;
}

/* Highlight block in full width */
.plan-card[data-layout="full-width"] .highlight-block {
  width: 100%;
  max-width: 600px;
  margin: 8px auto;
}

/* Nested features */
.plan-card[data-layout="full-width"] .nested-feature-item {
  width: 100%;
  max-width: 570px; /* Slightly less than parent to account for padding */
  padding-left: 30px;
}

/* Maintain checkbox and bullet alignment */
.plan-card[data-layout="full-width"] .checkbox-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.plan-card[data-layout="full-width"] .bullet-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

@media (max-width: 1200px) {
  .plan-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .plan-card[data-layout="full-width"] {
    grid-column: span 2;
  }
}

@media (max-width: 992px) {
  .plan-cards-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .plan-card[data-layout="full-width"] {
    grid-column: span 2;
  }
}

@media (max-width: 768px) {
  .plan-cards-container {
    grid-template-columns: 1fr;
  }

  .plan-card[data-layout="full-width"] {
    grid-column: span 1;
  }
}

.plan-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}

.plan-price {
  font-size: 2.2em;
  font-weight: 700 !important;
  margin-bottom: 10px;
  align-items: baseline;
}

.price-container {
  display: flex;
  align-items: baseline;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}

.plan-duration {
  font-size: small;
  font-weight: 400 !important;
  font-family: "DMSansBold";
}

.plan-old-price {
  font-size: 1.8em;
  color: #ff4405 !important;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  text-decoration: none;
}

.plan-old-price::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ff4405 !important;
  top: 50%;
  left: 0;
  transform: rotate(-10deg);
  transform-origin: center;
}

.plan-old-price1 {
  font-size: 1.8em;
  color: transparent;
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

.plan-old-price1::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: transparent;
  top: 50%;
  left: 0;
  transform: rotate(-10deg);
  transform-origin: center;
}

.horizontal-line {
  border-top: 1px solid white;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  position: relative;
  top: 15px;
  width: 100%;
  font-family: DMSansRegular;
  font-weight: 400;
}

.features-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.checkbox-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.bullet-icon {
  margin-right: 10px;
  flex-shrink: 0;
}

.feature-text {
  font-size: 14px;
  flex: 1;
}

.nested-feature-item {
  padding-left: 30px;
}

.features-item:not(.nested-feature-item) {
  padding-left: 0;
}

.features-item + .nested-feature-item {
  margin-top: -5px;
}

.best-value-image {
  width: 100px;
  height: 50px;
  position: absolute;
  top: 0;
  right: 10px;
  z-index: 1;
  filter: brightness(1) invert(1);
}

.plan-card {
  transition: all 0.3s ease;
}

.plan-card:hover .best-value-image {
  filter: brightness(0) invert(1);
}

.plan-card.selected-card .best-value-image {
  filter: brightness(0) invert(1);
}

.plan-card.selected-card:hover .best-value-image {
  filter: brightness(0) invert(1);
}

.title-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.plan-card button {
  padding: 10px 20px;
  background-color: #141414;
  color: #fff;
  border: none;
  font-family: "DMSansMedium";
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  align-self: center;
}

.plan-button {
  padding: 10px 20px;
  background-color: #141414;
  color: #fff;
  border: none;
  font-family: "DMSansBold";
  font-weight: 500;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.plan-button:hover {
  border-color: #000;
  color: #000;
  background-color: #fff;
}

.plan-button-0 {
  top: 180px;
}

.plan-button-1 {
  top: -6px;
}

.plan-button-2 {
  top: 6px;
}

.plan-card.selected-card {
  background-color: #ff4405;
  color: white;
  outline: none;
}

.plan-card.selected-card button {
  background-color: white;
  color: #141414;
}

.plan-card.selected-card .title1,
.plan-card.selected-card .plan-old-price,
.plan-card.selected-card .plan-price,
.plan-card.selected-card .plan-price::after,
.plan-card.selected-card .feature-text {
  color: #ffffff;
}

@media (max-width: 768px) {
  .heading {
    font-size: 9vw;
    margin-top: 2dvh;
    margin-bottom: 7dvh;
  }

  .toggle-buttons {
    flex-wrap: wrap;
    gap: 10px;
  }

  .price-container {
    margin-bottom: 0px;
  }
}

.price-dollar {
  font-size: 2rem;
  font-weight: 700 !important;
}

.price-whole {
  font-size: 2.2rem;
  font-weight: 700 !important;
}

.price-decimal {
  font-size: 1.2rem;
  font-weight: normal;
  vertical-align: super;
  position: relative;
  top: -0.5rem;
}

.plan-duration {
  font-size: 1rem;
  margin-left: 4px;
}

/* Add these specific styles for the toggle section */
.toggle-buttons,
.toggle-buttons button,
.toggle-buttons span {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  font-family: "DMSansRegular", sans-serif !important;
  font-weight: 400 !important;
}

/* Style for "Get 2 months free with annual plans" text */
.toggle-buttons span,
.toggle-buttons label {
  color: #141414;
  font-size: 16px;
  font-weight: 400 !important;
  letter-spacing: -0.02em;
}

/* Monthly/Yearly toggle specific styles */
.toggle-buttons button {
  font-weight: 500 !important;
  letter-spacing: -0.02em;
}

/* Update the heading styles */
.heading {
  font-family: "Thunder-Medium", sans-serif !important;
  font-weight: 500 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  letter-spacing: -0.02em;
}

/* Price text specific styles */
.plan-price,
.price-container,
.price-dollar,
.price-whole {
  font-family: "DMSansBold", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  letter-spacing: -0.02em;
}

/* Duration text */
.plan-duration {
  font-family: "DMSansRegular", sans-serif !important;
  font-weight: 400 !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

.limited-time-image {
  width: 150px;
  height: 80px;
  position: absolute;
  top: -5px;
  left: 250px;
  z-index: 1;
  filter: brightness(1) invert(0);
  transform: rotate(-12deg);
}

.plan-card:hover .limited-time-image {
  filter: brightness(0.9) invert(0);
}

.plan-card.selected-card .limited-time-image {
  filter: brightness(0) invert(1);
}

.plan-card.selected-card:hover .limited-time-image {
  filter: brightness(0) invert(1);
}

.highlight-block {
  background: rgba(255, 68, 5, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  position: relative;
  border: 1px solid rgba(255, 68, 5, 0.15);
}

.highlight-tags {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 0;
}

.price-tags {
  position: absolute;
  top: -12px;
  right: -5px;
  display: flex;
  gap: 4px;
  z-index: 2;
}

.limited-time-tag,
.price-value-tag {
  height: 24px;
  width: auto;
  object-fit: contain;
}

/* Handle selected card state */
.plan-card.selected-card .highlight-block {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.plan-card.selected-card .price-tags img {
  filter: brightness(0) invert(1);
}

/* Add hover state for better UX */
.highlight-block:hover .price-tags img {
  transform: translateY(-2px);
  transition: transform 0.2s ease;
}

.price-tag {
  height: 24px;
  width: auto;
  object-fit: contain;
}

.plan-card.selected-card .price-tag {
  filter: brightness(0) invert(1);
}

.plan-card.selected-card:hover .price-tag {
  filter: brightness(0) invert(1);
}

.annual-discount-text {
  color: #141414;
  font-weight: 600;
  margin-left: 8px;
  font-size: 14px;
  background: #23F0C7 !important;
  padding: 6px 12px;
  border-radius: 8px;
  display: inline-block;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(35, 240, 199, 0.25);
  text-decoration: none;
  border: none;
  line-height: normal;
}

.annual-discount-text:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(35, 240, 199, 0.3);
}

/* Add styles for selected card state */
.plan-card.selected-card .plan-old-price {
  color: #141414 !important;
}

.plan-card.selected-card .plan-old-price::after {
  background-color: #141414 !important;
}

.plan-card:hover {
  background-color: #ff4405;
  color: white;
}

.plan-card:hover .plan-old-price {
  color: #141414 !important;
}

.plan-card:hover .plan-old-price::after {
  background-color: #141414 !important;
}

.plan-card:hover .title1,
.plan-card:hover .plan-price,
.plan-card:hover .feature-text {
  color: #ffffff;
}

.plan-card:hover button {
  background-color: white;
  color: #141414;
}