@font-face {
  font-family: "DMSansBold";
  src: local("DMSansBold"), url("./fonts/DMSans-Bold.ttf") format("truetype");
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-weight: bold;
  font-style: normal; */
}

@font-face {
  font-family: "DM Sans";
  src: url("./fonts/DMSans-Regular.ttf") format("truetype");
  /* Add more src lines for other font weights/styles if needed */
}

@font-face {
  font-family: "DMSansMedium";
  src: local("DMSansMedium"),
    url("./fonts/DMSans-Medium.ttf") format("truetype");
  /* font-weight: bold;
  font-style: normal; */
}

@font-face {
  font-family: "DMSansRegular";
  src: local("DMSansRegular"),
    url("./fonts/DMSans-Regular.ttf") format("truetype");
  /* font-weight: bold;
  font-style: normal; */
}

@font-face {
  font-family: "ElzaRegular";
  src: local("ElzaRegular"),
    url("./fonts/ElzaTrial-Regular.otf") format("opentype");
  /* font-weight: bold;
  font-style: normal; */
}

@font-face {
  font-family: "InterBold";
  src: local("InterBold"), url("./fonts/Inter-Bold.otf") format("opentype");
  /* font-weight: bold;
  font-style: normal; */
}

@font-face {
  font-family: "Thunder-Bold";
  src: local("Thunder-Bold"),
    url("./fonts/Thunder-BoldLC.otf") format("opentype");
}

@font-face {
  font-family: "ElzaMedium";
  src: local("ElzaMedium"),
    url("./fonts/ElzaTextTrial-Medium.otf") format("opentype");
}
@font-face {
  font-family: "Thunder-SemiBold";
  src: local("Thunder-SemiBold"),
    url("./fonts/Thunder-SemiBoldLC.otf") format("opentype");
}
@font-face {
  font-family: "Thunder-SemiBold";
  src: local("Thunder-SemiBold"),
    url("./fonts/Thunder-SemiBoldLC.otf") format("opentype");
}
@font-face {
  font-family: "Thunder-Medium";
  src: local("Thunder-Medium"),
    url("./fonts/Thunder-MediumLC.otf") format("opentype");
}
@font-face {
  font-family: "Thunder-Light";
  src: local("Thunder-Light"),
    url("./fonts/Thunder-LightLC.otf") format("opentype");
}
@font-face {
  font-family: "Thunder";
  src: local("Thunder"), url("./fonts/Thunder-LC.otf") format("opentype");
}
@font-face {
  font-family: "Montserrat-Regular";
  src: local("Thunder"),
    url("./fonts/Montserrat-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Montserrat-Thin";
  src: local("Thunder"), url("./fonts/Montserrat-Thin.ttf") format("opentype");
}
@font-face {
  font-family: "Poppins-Regular";
  src: local("Thunder"), url("./fonts/Poppins-Regular.ttf") format("opentype");
}
@font-face {
  font-family: "Poppins-Medium";
  src: local("Thunder"), url("./fonts/Poppins-Medium.ttf") format("opentype");
}
@font-face {
  font-family: "Poppins-Light";
  src: local("Thunder"), url("./fonts/Poppins-Light.ttf") format("opentype");
}
:root {
  --sml-color-primary: #ff4405;
  --sml-color-primary-dark: rgba(151, 24, 12, 1);
  --sml-color-primary-light: rgba(255, 244, 237, 1);
  --sml-surface: #f5f7f9;
  --sml-surface-dark: rgba(233, 234, 236, 1);
  --sml-surface2: #141414;
  --sml-content-surface: #ffffff;
  --sml-content-surface2: linear-gradient(
    180deg,
    #ffffff 73.72%,
    rgba(255, 255, 255, 0) 100%
  );
  --sml-label-color: #999999;
  --sml-sidebar-background: var(--sml-surface2);
  --sml-sidebar-color: #e9eaec;
  --sml-divider-color: rgba(233, 234, 236, 1);
  --sml-header-placeholder-color: #999999;
  --sml-header-shadow-background: rgba(142, 141, 208, 0.16);
  --sml-header-box-shadow: 0px 4px 4px 0px var(--sml-header-shadow-background);
  --sml-header-content-background: #ffffff;
  --sml-box-shadow: 0px 4px 4px 0px rgba(142, 141, 208, 0.16);
  --sml-box-shadow2: 0px -4px 4px 0px rgba(142, 141, 208, 0.16);
  --sml-text-color-dark-surface: #ffffff;
  --sml-text-color-dark-surface-light: rgba(153, 153, 153, 1);
  --sml-dashboard-banner-backgroud: #310904;
  --sml-dashboard-video-background: #141414;
  --sml-upload-background-color: var(--sml-color-primary);
  --sml-primary-button-background: var(--sml-color-primary);
  --sml-track-icon-default-background: rgba(87, 19, 10, 1);
  --sml-spinner-overlay: rgba(20, 20, 20, 0.7);
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  --font-family-primary: "DM Sans", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', sans-serif;
  --font-size-base: 14px;
  --color-text-primary: #141414;
  --color-text-placeholder: #999999;
}
/* ::deep(.css-4ff9q7.Mui-active) {
  /* color: #23f0c7 !important; */
/* color: red !important; */
/* } */

/* Global spinner overlay style */
.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background: var(--sml-spinner-overlay) !important;
  border-radius: 0 !important;
}

.HomeLayout_container__HOzSt {
  background: var(--sml-surface);
}

/* Add global input styles */
input,
textarea,
select {
  font-family: var(--font-family-primary);
  font-size: var(--font-size-base);
  color: var(--color-text-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input::placeholder,
textarea::placeholder {
  color: var(--color-text-placeholder);
}
