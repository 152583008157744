.container {
  height: 100%;
  width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  background-color: var(--sml-sidebar-background);
  border-top-right-radius: 24px;
  color: var(--sml-sidebar-color);
}

.container ul {
  list-style: none;
}

.container li {
  height: 64px;
  line-height: 24px;
  font-weight: 400;
  font-size: 16px;
  margin-top: 16px;
}

.header {
  align-items: center;
  margin:  0 auto ;
  margin-left: 33px;
  padding-top: 24px;
}

.header img {
  height: 120px;
}

.navigation {
    margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.container li img {
  height: 24px;
  width: 24px;
  margin-right: 10px;
}
.footer {
  justify-content: center;
  margin-bottom: auto;
  cursor: pointer;
}

.container li span {
  display: none;
}
.footer {
    display: flex;
    align-items: center;
}

.profileLink {
  margin-top: auto;
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profileLink ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
}

.profileLink li {
  padding-left: 33px;
}

@media screen and (min-width: 1025px) {
  .container {
    width: 245px;
  }
  .container li span{
    display: inline;
  }
 
  .navigation{
    flex:1;
  }
  .navigation,.footer{
    justify-content: flex-start;
  }

}
@media screen and (max-width: 1024px) {
  .header img {
    height: 72px;
  }
  
  .navigation {
    margin-top: 65px;
  }

  /* Center all list items including profile when collapsed */
  .container li {
    padding-left: 0;
    display: flex;
    justify-content: center;
  }

  .profileLink li {
    padding-left: 0;  /* Remove the left padding for small screens */
    margin-left: 0;   /* Ensure no margin is affecting alignment */
  }
}