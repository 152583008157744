.notif-container {
  position: relative;
  display: inline-block;
}

.notif-bell {
  position: relative;
  background: transparent;
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notif-bell:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.unread-bell {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.unread-bell-count {
  position: absolute;
  right: -5px;
  top: -5px;
  background-color: #ff4405;
  border-radius: 50px;
  color: white;
  padding: 0.1rem 0.3rem;
  font-size: 8px;
  font-weight: 800;
  min-width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notif-panel,
.notif-panel-sup {
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  width: 450px;
  max-height: 70vh;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow-y: auto;
  z-index: 1000;
  padding: 0;
  overscroll-behavior: contain; /* Added to prevent scroll propagation */
  -ms-overflow-style: none; /* Added for IE/Edge compatibility */
}

.notif-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #eee;
  z-index: 1;
}

.notif-header h3 {
  margin: 0;
  font-size: 16px;
  font-weight: normal;
}

.close-button {
  background: transparent;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.close-button:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.notif-card {
  border: none;
  border-bottom: 1px solid #eee;
  margin: 0;
}

.notif-card:last-child {
  border-bottom: none;
}

.notif-card-content {
  padding: 12px 16px;
}

.notif-message {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer !important; /* Added !important to ensure override */
  padding: 8px;
  border-radius: 6px;
  user-select: none; /* Prevent text selection on click */
}

.notif-message * {
  cursor: pointer !important; /* Ensure all child elements show pointer cursor */
}

/* Ensure the label component inherits cursor */
.notif-message .sml-label {
  cursor: pointer !important;
}

.notif-message:hover {
  background-color: #f5f5f5;
}

.notif-message img {
  width: 32px; /* Increased from 24px */
  height: 32px; /* Increased from 24px */
  object-fit: contain;
  min-width: 32px; /* Added to prevent shrinking */
}

/* Style for new notifications */
.notif-message.new-notification {
  font-weight: 600;
}

.no-notif {
  border: none;
  padding: 32px 16px;
}

.no-notif-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  color: #666;
  font-weight: normal;
}

.notif-loader-card {
  padding: 32px 16px;
  border: none;
}

.loader-container {
  display: flex;
  justify-content: center;
  padding: 16px;
}

.notifs-loader {
  animation: spin 1s linear infinite;
  color: #666;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Custom scrollbar styles */
.notif-panel::-webkit-scrollbar,
.notif-panel-sup::-webkit-scrollbar {
  width: 6px;
}

.notif-panel::-webkit-scrollbar-track,
.notif-panel-sup::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.notif-panel::-webkit-scrollbar-thumb,
.notif-panel-sup::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.notif-panel::-webkit-scrollbar-thumb:hover,
.notif-panel-sup::-webkit-scrollbar-thumb:hover {
  background: #555;
}