.delete-account-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: none;
  padding: 1.2rem 1.5rem;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.delete-account-modal .modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

.delete-account-modal .close-icon {
  padding: 1rem;
  cursor: pointer;
}

.delete-account-modal .modal-body {
  padding: 0 1.5rem;
  background-color: #ffffff;
  color: #555;
  border-radius: 10px;
}

.delete-account-modal label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.delete-account-modal select,
.delete-account-modal textarea {
  width: 100%;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.2s ease;
}

.delete-account-modal select:focus,
.delete-account-modal textarea:focus {
  border-color: #dc3545;
  outline: none;
}

.delete-account-modal .modal-footer {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 2rem;
  padding: 1rem 0;
}

.delete-account-modal .btn-danger {
  background-color: #dc3545;
  border: none;
  padding: 0.6rem 1.2rem;
  font-weight: bold;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.delete-account-modal .btn-danger:hover {
  background-color: #c82333;
}

.delete-account-modal .btn-secondary {
  background-color: white;
  color: #555;
  border: none;
  padding: 0.6rem 1.2rem;
  font-weight: bold;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.delete-account-modal .btn-secondary:hover {
  background-color: #d6d8db;
}

.delete-account-modal .modal-content {
  animation: modal-appear 0.3s ease-out;
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.delete-account-modal .subscription-warning {
  color: #dc3545;
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 0.5rem;
}
