body {
  background-color: #000000;
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
}

.dynamic-text {
  font-size: 122px;
  font-weight: 400;
  font-family: Thunder;
  color: #cbcbcb;
  padding: 70px;
  width: 100%;
  max-width: min(1200px, 80vw);
  line-height: 117.12px;
  position: relative;
  top: 40px;
}

.typewriter-container {
  display: inline-block;
  width: 100%;
}

.typewriter {
  display: inline;
}

.cursor {
  color: #ff4405;
  animation: blink 0.7s infinite step-end;
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .dynamic-text {
    font-size: 12vw;
    line-height: 13vw;
  }
}

@media (max-width: 768px) {
  .dynamic-text {
    padding: 30px;
  }
}
