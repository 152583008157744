.header {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  gap: 1rem;
  width: 100%;
  z-index: 100;
}

.logo-nav {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  align-items: center;
}

.logo {
  display: flex;
  justify-content: start;
}

.logo img {
  height: 120px;
}

.nav-buttons {
  display: flex;
  gap: 4rem;
  position: relative;
  left: 127px;
  font-family: ElzaMedium;
  color: #310904;
}

.auth-buttons {
  display: flex;
  gap: 0.5rem;
  justify-self: end;
  font-family: DMSansMedium;
  position: relative;
}

.Login-Button {
  font-family: DMSansMedium;
  height: 48px;
  width: 96px;
  font-size: 16px;
  border: 1px solid #310904;
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: transparent;
}

.Login-Button:hover {
  color: #fff;
  background-color: #000;
}

.signupbtn {
  font-family: DMSansMedium;
  height: 48px;
  width: 96px;
  font-size: 16px;
  border: 1px solid #310904;
  border-radius: 8px;
  background-color: #141414;
  color: white;
  transition: all 0.3s ease;
}

.signupbtn:hover {
  color: #000;
  background-color: #fff;
}

.menu-icon {
  display: none;
  border: 1px solid #310904;
  border-radius: 8px;
}

@media (max-width: 992px) {
  .nav-buttons {
    display: none;
  }

  .auth-buttons {
    display: none;
  }

  .menu-icon {
    display: block;
  }
}

@media (max-width: 425px) {
  .header {
    padding: 1rem;
  }
}