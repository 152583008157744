.upload-card-submittrack {
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  position: relative;
  height: 80%;
  width: 44%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.upload-main-submittrack {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  border: 1px;
  top: 40px;
}

.downloadTrack-btn-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  gap: 18px;
}

.btn-1 {
  width: 181px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  gap: 4px;
  border-radius: 8px;
  background: #141414;
  color: #fff;
  margin-left: 12px;
  border-style: none;
}

.btn-2 {
  max-width: 207px;
  height: 48px;
  padding: 11px 24px 16px 24px;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid #310904;
  background: #fff;
  color: #310904;
}

.downloadTrack-container {
  gap: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.downloadTrack-close-div {
  position: absolute;
  display: flex;
  top: 1rem;
  right: 1rem;
  color: #999999;
  cursor: pointer;
}

.submit-checkbox {
  margin-left: 127px;
}

.downloadTrack-group {
  width: 432px;
  gap: 8px;
  display: grid;
}

.submitEvaluation-group {
  width: 432px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.submitEvaluation--btn-group {
  text-align: center;
}

.checkbox-image {
  padding: 6px;
}

.submitEvaluation-backbtn {
  width: 80px;
  height: 37px;
  bottom: 2rem;
  left: 2rem;
  padding: 8px;
  gap: 8px;
  position: absolute;
}

.submitEvaluation-btn {
  padding: 16px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;
}

.feedback-submit-btn {
  max-width: 265px;
  /* height: 56px; */
  padding: 16px 32px 16px 32px;
  /* gap: 8px; */
  border-radius: 8px;
  /* left: -28px; */
  /* position: relative; */
}

.feedback-btn-group {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: flex-start; */
  align-items: center;
  flex-wrap: wrap;
  /* gap: 18px; */
  position: absolute;
  bottom: 0.5rem;
  right: 1rem;
  /* left: 24px; */
}

.feedbackReview-container {
  text-align: center;
  width: 432px;
  gap: 20px;
  display: grid;
}

.feedbackReview-backbtn {
  width: 80px;
  height: 37px;
  padding: 8px;
  position: absolute;
  bottom: 2rem;
  left: 2rem;
}

.feedbackSubmitted-icon {
  width: 120px;
  height: 120px;
  position: relative;
  left: 156px;
}

.feedbackSubmitted-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.feedbackSubmitted-btn {
  width: 147px;
  height: 48px;
  padding: 16px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;
  position: relative;
  left: 106px;
}

.feedbackSubmitted-container {
  width: 432px;
}

.feedbackSubmitted-txt {
  font-family: Elza;
  font-size: 26px;
  font-weight: 600;
  line-height: 31.2px;
  letter-spacing: -0.01em;
  text-align: center;
}

@media (max-width: 1200px) {
  .upload-card-submittrack {
    height: 60%;
    width: 65%;
  }
  .upload-main-submittrack {
    padding: 0 2.5rem;
  }
  .feedbackReview-container {
    width: 100%;
    padding: 0 2rem;
  }
  .downloadTrack-group {
    width: 100%;
  }
  .Popup-Content {
    width: 100%;
  }
  .feedbackSubmitted-container {
    width: 100%;
  }
  .feedbackSubmitted-btn {
    width: 100%;
    left: 0;
  }
  .feedbackSubmitted-group {
    align-items: center;
  }
  .feedbackSubmitted-icon {
    left: 0;
  }
}
