.forgot-password-containers {
  padding-bottom: 2rem;
  position: relative;
}
.modal-content {
  border-radius: 20px;
}
.forgot-password-card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.forgot-password-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.forgot-pass-close {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.forgot-pass-form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}
.forget-pass-btn {
  display: flex;
  justify-content: center;
}
.forget-pass-error {
  text-align: center;
  margin-top: 1rem;
}
.forget-pass-footer {
  position: absolute;
  margin-top: 1rem;
  right: 1rem;
  bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.reset-pass-form-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
  gap: 2rem;
}
.reset-pass-success {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-family: Montserrat-Regular;
  font-weight: 500;
  padding: 2rem;
}
.password-input-wrapper {
  position: relative;
}
.password-toggle-icon {
  position: absolute;
  top: 0.7rem;
  right: 0.5rem;
}
