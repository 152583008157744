.upload-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  background: #121212e5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-card {
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  position: relative;
  height: 491px;
  width: 680px;
}

.upload-close-div {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  top: 24px;
  left: 640px;
  padding: 1px;
  gap: 8px;
  color: #999999;
}

.upload-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  border-radius: 8px;
  border: 1px;
  top: 40px;
}

.upload-label-div {
  height: 34px;
  margin: auto;
}

.progressbar-div {
  width: 584px;
  height: 95px;
  top: 12px;
  left: 45px;
  padding: 16px;
  gap: 12px;
  position: relative;
  border: 1px solid #e9eaec;
}

.progressbar-width {
  width: -webkit-fill-available;
}

.upload-box {
  width: 584px;
  height: 240px;
  padding: 16px 0px 0px 0px;
  gap: 4px;
  border-radius: 8px;
  position: relative;
  left: 45px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #999999;
  cursor: pointer;
}

.upload-box-complete {
  width: 584px;
  height: 360px;
  left: 45px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  position: relative;
}

.upload-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 8px;
  position: relative;
}

.upload-grid-left-content {
  height: 205px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;
}

.upload-grid-right-content {
  width: 370px;
  height: 205px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;
}

.upload-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
  height: 100%;
}

.upload-fields {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
}

.uploaded-row {
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-radius: 5px;
  background-color: #e9f0ff;
}

.upload-content {
  display: flex;
  align-items: center;
}

.upload-img-frame {
  border-radius: 8px;
  width: 205px;
  height: 205px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.uploadImg-inner-frame {
  position: absolute;
  width: 58px;
  height: 58px;
}

.upload-btn-Bottom-right {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 285px;
}

.sendFeedback {
  width: 206px;
  height: 48px;
  top: 411px;
  left: 410px;
  border-radius: 8px;
  gap: 8px;
  background-color: #ff4405;
}

.trackCheck-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: white;
  padding: 20px;
  border: 1px solid #ccc;
  z-index: 999;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.upload-grid-left-content {
  width: 205px;
  height: 205px;
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url("../../../assets/Images/UploadTrackRectangle.svg");
  background-size: cover;
}

.uploadImg-inner-frame {
  position: absolute;
  width: 73px;
  bottom: 77px;
  z-index: 1;
}

.progressbar-content {
  display: flex;
  justify-content: space-between;
}

.progressbar-width::-webkit-progress-value {
  background-color: red;
}

.progressbar-width::-moz-progress-bar {
  background-color: red;
}

.progressbar-width::-ms-fill {
  background-color: red;
}

.error-text {
  color: red;
  margin-top: 2px;
  font-size: 0.875rem;
}

@media only screen and (max-width: 710px) {
  .upload-btn-Bottom-right {
    position: unset;
  }

  .upload-container {
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    background: #121212e5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-box {
    width: 85% !important;
    max-width: 500px !important;
    height: 215px !important;
    padding: 16px !important;
    border-radius: 8px;
    position: relative !important;
    left: 6% !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px dashed #999999 !important;
    cursor: pointer !important;
    margin-bottom: 100px;
  }

  .upload-card {
    width: 90% !important;
    max-width: 500px !important;
    height: auto !important;
    border-radius: 12px;
    position: absolute !important;
  }

  .upload-close-div {
    position: relative !important;
    top: 16px !important;
    left: 90% !important;
    display: flex !important;
    width: 16px !important;
    height: 14px !important;
    color: #999999 !important;
  }

  .upload-main {
    top: 24px !important;
  }

  .progressbar-div {
    width: calc(100% - 90px) !important;
    height: auto !important;
    top: -7px !important;
    left: 45px !important;
    padding: 16px !important;
    gap: 12px !important;
    border-top: 1px solid #e9eaec;
    position: relative !important;
    border-style: none !important;
    border-color: none !important;
  }

  .progressbar-content {
    display: flex;
    width: 100% !important;
  }

  .upload-box-complete {
    width: 90% !important;
    max-width: 500px !important;
    height: 100% !important;
    padding: 16px !important;
    gap: 0px;
    border-radius: 8px;
    position: relative;
    left: 6%;
    cursor: pointer;
  }

  .upload-grid {
    display: flex !important;
    justify-content: center !important;
    padding: 0 8px;
    position: relative;
    margin-bottom: 30%;
  }

  .upload-grid-left-content {
    border-radius: 14px !important;
    border: 1px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 58px;
  }

  .uploadImg-inner-frame {
    position: absolute !important;
    width: 58px !important;
    height: 58px !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .upload-img-frame {
    width: 205px !important;
    height: 205px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .upload-grid-right-content {
    width: 370px !important;
    height: 208px !important;
    border-radius: 8px !important;
    border: 1px;
    position: relative !important;
    display: flex !important;
    flex-direction: column;
    gap: 58px !important;
  }

  .upload-fields {
    position: relative;
    display: flex !important;
    flex-direction: column;
    margin-bottom: -15px !important;
    padding: 3px !important;
    min-height: 116px;
  }
}
.SMLDropdown {
  height: 48px;
  background: #f5f7f9;
  border: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f5f7f9;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.SMLDropdownWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.SMLDropdown:focus {
  box-shadow: none;
}

.SMLDropdown:hover {
  border-color: transparent;
}

.custom-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #f5f7f9;
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.SMLDropdown option {
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  background-color: transparent;
  border: none;
}

.SMLDropdown option:hover {
  background-color: #e5e7eb;
  border-radius: 4px;
}

.SMLDropdown option:focus {
  background-color: #d1d5db;
  outline: none;
}
