.custom-modal-terms {
  z-index: 9999;
}
.custom-modal-terms .modal-content {
  height: 50vh;
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.5);
  background-color: #fff;
}

.custom-modal-terms .modal-header,
.custom-modal-terms .modal-body,
.custom-modal-terms .modal-footer {
  padding: 1.5rem;
}

.custom-modal-terms .modal-header {
  border-bottom: none;
}

.custom-modal-terms .modal-footer {
  border-top: none;
}

.custom-modal-terms .modal-content {
  padding: 2rem;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.terms-page {
  height: 100%;
  overflow-y: scroll;
  background-color: white;
  color: #333;
  font-family: DMSansRegular, sans-serif;
}

.terms-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.terms-navbar-logo img {
  height: 60px;
  width: auto;
}

.terms-nav-links {
  display: flex;
  gap: 20px;
}

.terms-nav-links a {
  color: #333;
  text-decoration: none;
  font-family: DMSansMedium, sans-serif;
  font-size: 16px;
}

.terms-nav-links a:hover {
  text-decoration: underline;
}

.terms-container {
  position: relative;
  padding-top: 20px;
  padding-bottom: 40px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}
.terms-close {
  position: absolute;
  cursor: pointer;
  right: 1rem;
  top: 1rem;
}
.terms-header {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #141414;
  font-family: DMSansBold, sans-serif;
}

.terms-content {
  line-height: 1.6;
}

.terms-section {
  margin-bottom: 30px;
}

.terms-section-title {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: #141414;
  font-family: DMSansMedium, sans-serif;
}

.terms-text {
  margin-bottom: 15px;
}

.terms-list {
  padding-left: 20px;
}

.terms-list-item {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .terms-navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .terms-nav-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .terms-container {
    padding-top: 60px;
  }
}

.terms-nav-return {
  color: #333;
  text-decoration: none;
  font-family: DMSansMedium, sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid #333;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.terms-nav-return:hover {
  background-color: #333;
  color: white;
}
@media screen and (max-width: 992px) {
  .modal-dialog {
    min-width: 90%;
  }
}
