.marginRight {
  margin-right: 24px;
}

.consistent-custom-grid {
  grid-template-columns: 10fr 3fr 3fr;
}
.track-bg-main-div-artist {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
  width: 100%;
}
.track-bg-main-div-feedback {
  background-color: #f5f7f9;
  margin: 0 auto;
  position: relative;
  border-radius: 10px;
  margin-bottom: 100px;
}
.track-bg-main-div {
  background-color: #f5f7f9;
  width: 70vw;
  margin: 0 auto;
  max-width: none;
  position: relative;
  border-radius: 10px;
  margin-bottom: 100px;
}
.track-bg-not-main-div {
  width: 70vw;
  margin: 0 auto;
  max-width: none;
  position: relative;
}
.track-bg-main-divs {
  width: 80%;
  margin: 0 auto;
  max-width: none;
  position: relative;
}

.dashboard-container {
  border-radius: 8px;
  background-color: #f5f7f9;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
}

.track-rate-container {
  width: 100%;
  background-color: #ffffff;
}
.track-rate-slider-div {
  width: 100%;
  position: relative;
  padding-left: 6px !important;
}

.css-1aznpnh-MuiSlider-root {
  width: 100% !important;
}

@media screen and (max-width: 768px) {
  .content-wrapper {
    flex-direction: column;
  }

  .consistent-wrapper-left-div {
    margin-bottom: 20px;
  }
}

@media screen and (min-width: 992px) {
  .content-wrapper {
    flex-direction: row;
  }

  .consistent-wrapper-left-div {
    margin-right: 20px;
  }
}

@media (max-width: 992px) {
  .track-bg-main-div {
    max-width: 800px !important;
  }
}

.trackEvalBtnBox {
  display: flex;
  align-items: center;
  gap: 12px;
}

.trackEvalHeaderBox {
  margin-bottom: 16px;
}
.ratebar {
  margin: 8px 0;
}

.TrackEvalTitle {
  text-align: center;
}
.add-note {
  position: absolute;
  display: flex;
  flex-direction: column;
}

.ratebar {
  color: black;
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 5px;
}

.sml-textarea-slide {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;
  max-height: 0;
  opacity: 0;
}

.sml-textarea-slide.show {
  max-height: 200px;
  opacity: 1;
}

.add-note {
  margin-top: 10px;
}

.notes-btn {
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}
.notes-line {
  display: flex;
  align-items: flex-start;
  margin: 12px 0;
  text-align: left;
  width: 100%;
}

.notes-text {
  resize: none;
  max-width: 100%;
  height: 150px;
  padding: 6px;
  border-radius: 8px;
  gap: 8px;
  background: #f5f7f9;
  border: none;
  width: 100%;
  width: Fill (428px) !important;
  outline: none;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #23f0c7;
}
.final-note {
  /* width: 50%; */
}
@media (max-width: 700px) {
  .final-note {
    width: 100%;
  }
}

/* Remove any fixed heights or overflows that might cut content */
.track-evaluation-section {
  height: auto;
  overflow: visible;
  padding-bottom: 120px; /* Space for player */
}

/* Style the suggested targets section */
.SMLSecondaryButtonSelected {
  margin: 4px;
  display: inline-block;
}

.vh-50 {
  height: auto;
  overflow: visible;
  color: #333;
}
