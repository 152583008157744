/* Add at the top of the file */
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  
  .full-screen-background {
    background-color: #f8f9fa;
  }
  .full-screen {
    height: 87vh !important;
  }
  .full-screen-artist {
    height: 87vh !important;
    max-width: 87vw;
    overflow-y: auto;
  }
  .trackFeedback-main {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 100px;
  }
  .trackPlay {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin: 0;
  }
  .detail-results-submit {
    display: flex;
    width: 100%;
    gap: 1rem;
    align-items: flex-start;
    justify-content: center;
  }
  .descriptions-detail-submit {
    flex: 1 1 auto;
    min-width: 30%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    gap: 20px;
    margin-top: 10px;
    padding: 10px 0;
    white-space: nowrap;
    max-height: 400px;
    overflow-y: auto;
  }
  
  .padding-0 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  
  @media (max-width: 992px) {
    .track-main-div {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    .padding-0 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .headerLogo-trackf {
    height: 80px;
  }
  .popup-background-blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 998;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .ArtistProfile-popup {
    position: relative;
    z-index: 999;
  }
  .back-to-dashboard {
    display: flex;
    flex: 0 1 auto;
    padding: 0;
    justify-content: start;
    align-items: center;
    margin-left: 3.2rem;
    /* margin-top: 1rem; */
    flex: 0;
  }
  .trackf-right-header {
    width: 100%;
    flex: 1;
  }
  .wavesurfer-div {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-left: 5rem;
  }
  #trackTitle-details-final {
    z-index: 9999;
  }
  #trackTitle-player {
    max-width: 400px;
    padding: 8px 12px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  #trackTitle-details-final {
    max-width: 400px;
    padding: 8px 12px;
    background-color: #333;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    white-space: normal;
    word-wrap: break-word;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
  .no-review {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .resubmit-track-btn {
    min-width: fit-content;
    font-size: 14px;
    padding: 0.6rem 2.1rem;
    color: white;
    border-radius: 5px;
    gap: 8px;
    background: #ff4405;
    position: relative;
    border-style: none;
  }
  .req-video-btn {
    min-width: fit-content;
    font-size: 14px;
    padding: 0.6rem 2.1rem;
    color: white;
    border-radius: 5px;
    gap: 8px;
    background: #ff4405;
    position: relative;
    border-style: none;
  }
  .req-video-btn2 {
    min-width: fit-content;
    font-size: 14px;
    padding: 0.6rem 0.75rem;
    color: black !important;
    border-radius: 5px;
    gap: 8px;
    background: white;
    position: relative;
    border: 2px solid black;
  }
  .req-video-btn2 .Button1,
  .req-video-btn2 .button1:hover,
  .req-video-btn2 .button1:active,
  .req-video-btn2 .button1:focus {
    color: black !important;
  }
  
  @media (max-width: 700px) {
    .back-to-dashboard {
      margin-top: 5rem;
      margin-left: 0;
    }
  }
  @media (max-width: 900px) {
    .wavesurfer-div {
      margin-left: 0;
    }
  }
  
  /* New styles being added */
  .revision-link {
    background: none;
    border: none;
    padding: 0;
    color: var(--sml-color-primary, #ff4405);
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: color 0.2s ease;
  }
  
  .revision-link:hover {
    color: var(--sml-color-primary-dark, #e63e04);
    text-decoration: underline;
  }
  
  .title-text {
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .revision-info {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: -4px; /* Adjust spacing from title */
  }
  
  .revision-number {
    font-size: 13px;
    color: #666;
    font-weight: 400;
  }
  
  .revision-link {
    background: none;
    border: none;
    padding: 0;
    color: var(--sml-color-primary, #ff4405);
    font-size: 13px;
    font-weight: 400;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: color 0.2s ease;
  }
  
  .revision-link:hover {
    color: var(--sml-color-primary-dark, #e63e04);
    text-decoration: underline;
  }
  
  .artist-link {
    color: #6c757d;
    cursor: pointer;
    font-size: 14px;
    text-decoration: underline;
  }
  
  .artist-link:hover {
    color: #495057;
  }
  
  /* Update the title styles */
  .title1,
  .artist-link,
  .sml-label-wrapper label {
    font-weight: 500;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }
  
  /* If you need specific weight for certain elements */
  .title1 {
    font-weight: 600;
  }
  
  .artist-link {
    font-weight: 500;
  }
  
  .video-feedback-button {
    position: fixed;
    bottom: calc(2rem + 100px);
    right: 2rem;
    background-color: #ff4405;
    border-radius: 20px;
    padding: 8px 16px;
    gap: 8px;
    display: flex;
    align-items: center;
    color: white;
    text-transform: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .video-feedback-button:hover {
    background-color: #cc3704;
  }
  
  .video-feedback-button span {
    font-size: 14px;
    font-weight: 500;
  }
  .textwrap-nowrap-button {
    text-wrap: nowrap;
  }
  
  /* Add these styles to your TrackFeedback.css */
  .track-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 1rem;
  }
  
  .back-link {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    text-decoration: none;
    font-size: 16px;
  }
  
  .back-link:hover {
    color: #333;
  }
  
  .page-title {
    font-size: 24px;
    font-weight: 600;
    color: #1a1a1a;
    margin: 0;
    text-align: center;
  }
  
  .track-info-container {
    display: flex;
    align-items: center;
    gap: 16px;
    height: 128px; /* Match height */
  }
  
  .track-details {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .track-title {
    font-size: 16px !important;
    font-weight: 500;
    color: #1a1a1a;
    max-width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    margin-bottom: 12px;
  }
  
  .track-info-icon {
    cursor: pointer;
    color: #6c757d;
    padding: 4px;
    display: flex;
    align-items: center;
  }
  
  .track-actions {
    margin-left: 32px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  
  .duration-badge {
    background-color: #f8f9fa;
    color: #6c757d;
    padding: 2px 8px;
    border-radius: 12px;
    font-size: 12px;
    white-space: nowrap;
  }
  
  .revision-badge {
    display: inline-flex;
    align-items: center;
    background-color: #fff3ed;
    border-radius: 12px;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .revision-number {
    color: #ff4405;
    padding: 2px 8px;
    font-size: 12px;
  }
  
  .revision-link {
    padding: 2px 8px;
    border-left: 1px solid rgba(255, 68, 5, 0.2);
    color: #6c757d;
    display: flex;
    align-items: center;
  }
  
  /* Add these badge styles to your TrackFeedback.css */
  
  .artist-plan-type {
    display: none;
  }
  
  /* Button styles */
  .SMLAssignBtn {
    background-color: white !important;
    border: 1px solid #ff4405 !important;
    color: #ff4405 !important;
    transition: all 0.2s ease-in-out !important;
    padding: 8px 16px !important;
    font-size: 14px !important;
    border-radius: 6px !important;
    color: #ff4405 !important;
  }
  
  .SMLAssignBtn:hover:not(.disabled) {
    background-color: #ff4405 !important;
    color: white !important;
  }
  
  .SMLButton-Orange {
    /* background-color: #6c757d !important; */
    color: white !important;
    transition: background-color 0.2s ease-in-out !important;
    padding: 8px 16px !important;
    border-radius: 6px !important;
  }
  
  .SMLButton-Orange:hover:not(.disabled) {
    color: #fff !important; 
    background-color: #000 !important;
  }
  
  .SMLButton-Orange.disabled,
  .SMLAssignBtn.disabled {
    opacity: 0.5 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
  }
  
  /* Track image size adjustment */
  .track-image {
    max-width: 80px !important;
    height: auto !important;
  }
  
  /* Artist and badge line */
  .artist-info {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
  }
  
  /* Add these new styles for the plan badge - matches MyProfile styling */
  .plan-badge {
    border-radius: 12px;
    padding: 4px 12px;
    font-size: 13px;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
  }
  
  .plan-badge.basic {
    background-color: #ecfdf5;
    color: #059669;
    border: 1px solid #a7f3d0;
  }
  
  .plan-badge.pro {
    background-color: #eff6ff;
    color: #3b82f6;
    border: 1px solid #bfdbfe;
  }
  
  .plan-badge.partner {
    background-color: #f5f3ff;
    color: #7c3aed;
    border: 1px solid #ddd6fe;
  }
  
  /* Default style for no subscription */
  .plan-badge:not(.basic):not(.pro):not(.partner) {
    background-color: #f3f4f6;
    color: #6b7280;
    border: 1px solid #e5e7eb;
  }
  
  /* New comment positioning fixes */
  #waveform {
    position: relative;
    width: 100%;
    margin-bottom: 40px; /* Add space below waveform */
  }
  
  .comment-icon {
    position: absolute;
    cursor: pointer;
    z-index: 10;
    transform: translateY(-50%);
    transition: transform 0.2s ease;
  }
  
  /* Add hover effect */
  .comment-icon:hover {
    transform: translateY(-50%) scale(1.15);
  }
  
  /* Active state specific styling */
  .comment-icon.active {
    transform: translateY(-50%) scale(1.15);
  }
  
  /* Optional: Add a small indicator dot below active comments */
  .comment-icon.active::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 4px;
    height: 4px;
    background-color: #008080; /* Teal color matching the icon */
    border-radius: 50%;
  }
  
  /* Track evaluation container */
  .track-evaluation-section {
    width: 50%;
    height: calc(100vh - 300px); /* Adjust based on your header/footer heights */
    position: sticky;
    top: 0;
    overflow: hidden;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Comment box styles */
  .comment-box {
    position: absolute;
    width: 300px;
    background: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }
  
  .comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
  }
  
  /* Transcript styling */
  .input-track-notes {
    width: 100%;
    padding: 20px;
    margin: 0 0 16px 0;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border: 1px solid #eaeaea;
  }
  
  .input-track-notes .timestamp {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 12px;
    margin-bottom: 8px;
  }
  
  .input-track-notes .author {
    font-weight: 500;
    margin-bottom: 4px;
    color: #333;
  }
  
  .input-track-notes .comment-content {
    color: #555;
    line-height: 1.5;
  }
  
  /* Fix for detail-results section */
  .detail-results {
    display: flex;
    gap: 10px;
    margin: 0 1rem 120px 1rem;
    width: calc(100% - 2rem);
  }
  
  #descriptions-detail {
    position: relative;
    z-index: 1;
    padding-top: 15px; /* Reduce from 30px to 15px */
  }
  
  /* Spinner overlay */
  .spinner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 100;
  }

  /* Comments section styling */
.comments-container {
    width: 50%;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 20px;
    margin-top: 0;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  /* Style for the suggestions title to match track evaluation */
  .comments-container .title1 {
    font-size: 28px !important;
    font-weight: 600 !important;
    color: #1a1a1a !important;
    margin-bottom: 24px !important;
    font-family: 'Poppins', sans-serif;
    padding: 0 !important;
  }
  
  /* Refine comment note styling */
  .input-track-notes {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    transition: opacity 0.2s ease;
    border: 1px solid #eaeaea;
  }
  
  /* Style for comment timestamps */
  .input-track-notes .timestamp {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #666;
    font-size: 12px;
    margin-bottom: 8px;
  }
  
  /* Style for comment author */
  .input-track-notes .author {
    font-weight: 500;
    margin-bottom: 4px;
    color: #333;
  }
  
  /* Style for comment content */
  .input-track-notes .comment-content {
    color: #555;
    line-height: 1.5;
    font-size: 14px;
  }
  
  /* Style for the focused comment */
  .input-track-notes:focus,
  .input-track-notes:hover {
    border-color: #e0e0e0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.08);
  }

  /* Make it responsive */
  @media (max-width: 992px) {
    .detail-results {
      flex-direction: column;
    }

    .track-evaluation-section,
    .comments-section {
      width: 100%;
    }
  }

  /* Update the track-bg-main-div-artist padding */
  .track-bg-main-div-artist {
    padding: 20px;
  }

  /* Update input-track-notes padding to match */
  .input-track-notes {
    padding: 20px;
  }

  /* Make comments take full width inside container */
  .comments-container .row {
    margin: 0;
    width: 100%;
  }

  .comments-container .col {
    padding: 0;
  }

  /* Make comments section scrollable */
  .comments-section {
    width: 50%;
    height: calc(100vh - 300px);
    overflow-y: auto;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  /* Control both sections at parent level */
  .track-evaluation-section,
  .comments-section {
    width: 50%;
    margin-top: 0;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  /* Ensure comments take full width of their section */
  .comments-section {
    padding: 0;
  }

  /* Consistent title styling for both sections */
  .track-evaluation-section .title1,
  .comments-section .title1 {
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #1a1a1a !important;
    margin-bottom: 16px !important;
    font-family: 'Poppins', sans-serif;
    padding: 0 !important;
  }

  @media (max-width: 992px) {
    .detail-results {
      flex-direction: column;
    }

    .track-evaluation-section,
    .comments-section {
      width: 100%;
    }
  }

  /* Reduce wave track and content height */
  .wave-track-container {
    height: 128px;
    margin-bottom: 10px;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 14px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }

  /* Reduce track thumbnail size */
  .track-thumbnail {
    width: 128px; /* Same as height to keep square */
    height: 128px; /* Match wavesurfer container height */
    border-radius: 4px;
    margin-right: 16px;
    object-fit: cover;
  }
  