.Parallax-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #cbcbcb;
  position: relative;
  padding: 80px 20px 100px;
  gap: 40px;
}

.Parallax-Content {
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Parallax-Text {
  font-size: min(12vw, 120px);
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
  width: 65%;
  white-space: normal;
  font-family: Thunder-SemiBold;
}

.Parallax-bottom {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.Parallax-logo-nav {
  display: flex;
  align-items: center;
  gap: 20px;
}

.Parallax-logo img {
  width: 80px;
  height: auto;
  margin-right: 20px;
}

.Parallax-nav-group {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}

.parallav-nav-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* margin: 10px; */
  cursor: pointer;
  font-family: DMSansMedium;
  font-weight: 500;
}

.parallav-nav-content div {
  margin: 5px 0;
  text-align: left;
}

.parallax-btn-group {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: column;
  font-family: DMSansMedium;
  font-weight: 500;
}

/* .parallax-btn-group-mobile {
  display: none;
} */

.parallax-btn {
  padding: 10px 20px;
  margin: 5px;
  border-radius: 8px;
  border: 1px solid #ffffff;
  background-color: #141414;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.parallax-btn:hover {
  border-color: #ff4405;
  color: #ff4405;
}

@media (max-width: 768px) {
  .Parallax-container {
    padding: 10px;
  }

  .Parallax-bottom {
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }

  .parallax-btn-group-mobile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: DMSansMedium;
    font-weight: 500;
  }

  .Parallax-logo-nav {
    width: 100%;
    align-items: center;
    flex-direction: column;

  }

  .Parallax-nav-group {
    margin-left: 0;
  }
}

.parallav-nav-content a {
  text-decoration: none;
  color: inherit;
  margin: 5px 0;
  text-align: left;
}

.terms-modal-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  z-index: 9999;
}

.terms-modal-root > div {
  pointer-events: auto;
}

.modal-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.85);
}

.modal-viewport-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}