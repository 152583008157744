.superVisor-Login-close-div {
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: #999999;
  cursor: pointer;
}

.superVisor-Login-content {
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.superVisor-login-label-div {
  text-align: center;
  padding-top: 2rem;
  position: relative;
}

.superVisor-login-fields {
  width: 468px;
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
}

.superVisor-login-fields1 {
  position: relative;
  display: flex;
  flex-direction: column;
}

.superVisor-login-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  border-radius: 8px;
  border: 1px;
}

.superVisor-login-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.superVisor-login-controls1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.superVisor-login-card-end {
  position: relative;
}

.superVisor-login-groupby {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}
.superVisor-login-groupby1 {
  margin-top: 1rem;
  display: flex;
  align-items: center;

  position: relative;
}
.superVisor-login-groupbyBtns {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 40px;
}

.btn-no-margin {
  background: none;
  border: none;
  cursor: pointer;
}

.btn-no-margin:hover {
  /* text-decoration: underline; */
}

.div-line {
  width: 199.34px;
  height: 1px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  margin: 0 10px;
}

.margin-left {
  margin-left: auto;
}

.superVisor-login-card {
  width: 100%;
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  position: relative;
  padding: 1.5rem;
}

.superVisor-login-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1200px) {
  .superVisor-Login-content {
    width: 100%;
  }

  .superVisor-login-fields {
    width: 100%;
    position: relative;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
    gap: 8px;
  }

  .superVisor-login-fields1 {
    width: 100%;
    position: relative;
    display: flex;
    padding: 0.5rem;
    flex-direction: column;
  }

  .superVisor-login-main {
    gap: 0px;
    border-radius: 8px;
  }

  .superVisor-login-controls {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .superVisor-login-controls1 {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
  }

  .superVisor-login-card-end {
    position: relative;
    width: 232.55px;
    height: 48px;
    top: 20px;
    left: 120px;
  }

  .superVisor-login-groupby {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    align-items: center;
    margin: 1rem auto;
  }

  .btn-no-margin {
    background: none;
    border: none;
    cursor: pointer;
  }

  .btn-no-margin:hover {
    /* text-decoration: underline; */
  }

  .div-line {
    width: 100%;
    height: 1px;
    border: 1px solid #e9eaec;
    background: #e9eaec;
    margin: 0 10px;
  }

  .margin-left {
    margin-left: auto;
  }

  .superVisor-login-card {
    border-radius: 24px;
    background: white;
    border: 1px solid #e9eaec;
    position: relative;
    width: 100%;
    padding: 2rem;
  }
}

@media (max-width: 576px) {
  .superVisor-login-fields {
    width: 100%;
  }
  .provider-btn {
    width: 100%;
  }

  .google-sup {
    flex-direction: column;
    gap: 15px;
    margin-top: 0px;
  }
}
.supervisor-login-card-end {
  position: relative;
  display: flex;
  justify-content: center;
}

.supervisor-login-groupbyBtns {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 40px;
}
