.uploadTracks-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  height: 80dvh;
  position: relative;
}

.uploadTracks-rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 30px;
  width: 100%;
  max-width: 1800px;
  height: 100%;
  padding: 40px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.uploadTracks-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 20px;
  flex-direction: row-reverse;
}

.uploadTracks-container {
  flex: 1;
  max-width: 487px;
  text-align: left;
  margin-left: 53px;
}

.uploadTracks-btn {
  display: inline-block;
  background: #c8fff4;
  border: none;
  border-radius: 8px;
  padding: 8px 16px;
  margin-bottom: 20px;
  font-size: 14px;
  cursor: pointer;
  font-family: ElzaMedium;
}

.uploadTracks-title {
  font-size: 70px;
  line-height: 80px;
  font-weight: 500;
  margin-bottom: 20px;
  font-family: Thunder-Medium;
  font-weight: 500;
}

.uploadTracks-content {
  font-size: 16.5px;
  line-height: 1.5;
  text-align: left;
  font-family: DMSansRegular;
  font-weight: 400;
  position: relative;
}

.uploadTracks-img {
  flex: 1;
  max-width: 450px;
  height: auto;
  margin-right: 150px;
}

/* Media Queries */
@media (max-width: 1024px) {
  .uploadTracks-img {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .uploadTracks-btn {
    position: absolute;
    top: 60px;
  }

  .uploadTracks-group {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .uploadTracks-container {
    text-align: center;
  }

  .uploadTracks-container {
    flex: 1;
    max-width: 90%;
    text-align: left;
    margin-left: 0;
  }

  .uploadTracks-img {
    margin-top: 5dvh;
    width: 50vw;
    max-width: 350px;
    max-height: 27dvh;
  }

  .uploadTracks-title {
    font-size: min(70px, 8vw);
    line-height: min(80px, 9.5vw);
  }

  .uploadTracks-content {
    font-size: 2.5vw;
  }
}

@media (max-width: 425px) {
  .uploadTracks-rectangle {
    padding: 20px;
  }

  .uploadTracks-title {
    font-size: min(70px, 10vw);
    line-height: min(80px, 11vw);
  }

  .uploadTracks-content {
    font-size: 4vw;
  }
}
