.privacy-page {
  width: 100%;
  min-height: 100vh;
  background-color: white;
  color: #333;
  font-family: DMSansRegular, sans-serif;
}

.privacy-navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.privacy-navbar-logo img {
  height: 60px;
  width: auto;
}

.privacy-nav-return {
  color: #333;
  text-decoration: none;
  font-family: DMSansMedium, sans-serif;
  font-size: 16px;
  padding: 10px 20px;
  border: 2px solid #333;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.privacy-nav-return:hover {
  background-color: #333;
  color: white;
}

.privacy-container {
  padding-top: 120px;
  padding-bottom: 40px;
  max-width: 800px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.privacy-header {
  font-size: 2.5em;
  margin-bottom: 1em;
  color: #141414;
  font-family: DMSansBold, sans-serif;
}

.privacy-content {
  line-height: 1.6;
}

.privacy-section {
  margin-bottom: 24px;
}

.privacy-section-title {
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 600;
}

.privacy-text {
  margin-bottom: 16px;
  line-height: 1.6;
}

.privacy-list {
  list-style-type: disc;
  padding-left: 24px;
  margin-bottom: 16px;
}

.privacy-list-item {
  margin-bottom: 12px;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .privacy-navbar {
    flex-direction: column;
    height: auto;
    padding: 10px;
  }

  .privacy-container {
    padding-top: 160px;
  }
}

.privacy-modal-container {
  background: white;
  border-radius: 16px;
  width: 95%;
  max-width: 800px;
  height: 90vh;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: modalFadeIn 0.3s ease-out;
  overflow: hidden;
}

.privacy-modal-header {
  padding: 20px 24px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  position: sticky;
  top: 0;
  z-index: 1;
}

.privacy-modal-header h2 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
}

.privacy-modal-content {
  padding: 20px 24px 32px 24px;
  overflow-y: auto;
  flex: 1;
}

.modal-close {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: #666666;
}

@keyframes modalFadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
