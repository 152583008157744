.trackSubmission {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trackSubmission-Frame {
  width: 100%;
  height: 100dvh;
  display: flex;
  justify-content: center;
  position: relative;
}

.trackSubmission-rectangle {
  width: 680px;
  height: 660px;
  gap: 0px;
  background: #ffffff;
  border-radius: 24px;
}

.trackSubmission-header {
  width: 377.16px;
  height: 34px;
  top: 40px;
  left: 260px;
  gap: 0px;
  opacity: 0px;
  position: relative;
}

.trackSubmission-text {
  width: 356px;
  height: 38px;
  top: 40px;
  left: 188px;
  gap: 0px;
  opacity: 0px;
  position: relative;
  text-align: center;
  color: #999999 !important;
}

.trackSubmission-close-div {
  position: relative;
  display: flex;
  width: 32px;
  height: 18px;
  top: 46px;
  left: 622px;
  padding: 1px;
  gap: 8px;
  font-size: 25px;
  color: #999999;
  cursor: pointer;
}

.trackSubmission-content {
  width: 428px;
  height: 428px;
  top: 80px;
  left: 70px;
  gap: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.trackSubmission-contents {
  width: 428px;
  height: 391px;
  top: 80px;
  left: 70px;
  gap: 60px;
  opacity: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.trackSubmissionStatus-input {
  width: 366px;
  height: 11px;
  gap: 16px;
  opacity: 0px;
  gap: 8px;
  opacity: 0px;
  display: flex;
}

.trackSubmission-btn {
  width: 140px;
  height: 48px;
  top: 90px;
  left: 500px;
  padding: 15px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  position: relative;
  border-style: none;
}

.trackSubmissionFeedback-Btn {
  width: 80px;
  height: 37px;
  padding: 8px;
  gap: 8px;
  opacity: 0px;
  bottom: 5%;
  right: 5%;
  transform: translate(50%, 180%);
}
.trackSubmission-Btn {
  width: 80px;
  height: 37px;
  padding: 8px;
  gap: 8px;
  opacity: 0px;
  bottom: 5%;
  right: 5%;
  transform: translate(50%, 280%);
}

.trackSubmissionStatus-input {
  width: 366px;
  height: 11px;
  gap: 16px;
  display: flex;
}

.trackSubmission-button {
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .trackSubmission {
    position: relative;
    width: 100% !important;
    height: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -335px;
  }

  .trackSubmission-Frame {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .trackSubmission-rectangle {
    max-width: 680px !important;
    height: auto !important;
  }
}
