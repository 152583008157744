.resources-page {
  padding: 16px;
  width: 100%;
  min-height: 100%;
  overflow: auto;
  flex: 1;
}

/* Add custom scrollbar styling */
.resources-page::-webkit-scrollbar {
  width: 8px;
}

.resources-page::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.resources-page::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.resources-page::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.resources-container {
  display: flex;
  flex-direction: row;
}

.resources-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);
  overflow-y: auto;
} 