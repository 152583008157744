#checkout {
  min-height: 60vh;
}

.payment-popup {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

.left-payment {
  display: flex;
  justify-content: center;
  width: 30%;
}

.right-payment {
  width: 50%;
  border: 1px solid #ccc;
  padding: 2rem;
  border-radius: 10px;
}

.payment-plan-card {
  display: flex;
  min-height: 500px;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 2rem 20px 3rem 20px;
  text-align: center;
  position: relative;
  align-items: center;
  background-color: #ff4405;
  color: #fff !important;
}

.payment-plan-card h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
  text-align: center;
}

.payment-price-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  position: relative;
  top: 15px;
  width: 100%;
  font-family: DMSansRegular;
  font-weight: 400;
}

.features-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.checkbox-icon {
  margin-right: 10px;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
}

.bullet-icon {
  margin-right: 10px;
  flex-shrink: 0;
  font-size: 20px;
  line-height: 1;
}

.feature-text {
  font-size: 14px;
  flex: 1;
}

.nested-features-list {
  list-style: none;
  padding-left: 30px;
  margin: 5px 0 0 0;
}

.nested-feature-item {
  display: flex;
  align-items: flex-start;
  margin-top: 5px;
}

.horizontal-line {
  border-top: 1px solid white;
  margin: 15px 0;
}

.plan-price {
  font-size: 2.2em;
  font-weight: 700;
  margin-bottom: 10px;
}

.plan-duration {
  font-size: small;
  font-weight: 400;
  font-family: "DMSansBold";
}

.plan-old-price {
  font-size: 1.8em;
  color: #ff8c7a;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  text-decoration: line-through;
}

.plan-old-price1 {
  font-size: 1.8em;
  color: transparent;
  margin-top: 10px;
  position: relative;
  display: inline-block;
}

@media (max-width: 768px) {
  .payment-popup {
    flex-direction: column;
  }

  .left-payment,
  .right-payment {
    width: 100%;
  }

  .payment-plan-card {
    margin-bottom: 2rem;
  }
}

.line-payment {
  width: 1px;
  background-color: #ccc;
  margin: 0 2rem;
}

.signUp-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
}

.continue-btns {
  cursor: pointer;
}

.signUp-progress-steps {
  margin-bottom: 2rem;
}

.signUp-close-div {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}


.payment-methods-section {
  padding: 20px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.saved-payment-methods {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.saved-method {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.saved-method:hover {
  border-color: #4a90e2;
  background-color: #f8f9fa;
}

.saved-method.selected {
  border-color: #4a90e2;
  background-color: #f0f7ff;
}

.saved-method span {
  font-size: 14px;
  color: #333;
}

.change-payment-btn {
  margin-top: 12px;
  padding: 10px 15px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: transparent;
  color: #4a90e2;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.change-payment-btn:hover {
  background-color: #f8f9fa;
  border-color: #4a90e2;
}

#payment-form {
  width: 100%;
  overflow: visible;
  padding: 0 20px;
}