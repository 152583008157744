/* Global font smoothing - apply to everything */
:root {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}


/* Font families */
[class*="DMSans"],
.DMSans-Regular,
.DMSansMedium,
.DMSansBold,
[class*="Elza"],
[class*="Inter"],
[class*="Thunder"],
[class*="Montserrat"],
[class*="Poppins"] {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Common text elements */
h1, h2, h3, h4, h5, h6, 
p, span, a, label, input, 
button, textarea,
div, li {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

/* Specific font weights */
h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
}

label, button {
  font-weight: 500;
}

p, span, input, textarea {
  font-weight: 400;
}

/* Force font smoothing on specific components that might need it */
[class*="plan-"],
[class*="price-"],
[class*="feature-"],
[class*="toggle-"],
[class*="heading"] {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

