.container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 30px 30px 0 30px;
}

.leftContainer {
  box-shadow: var(--sml-header-box-shadow);
  border-radius: 8px;
  padding: 12px;
  width: 100%; 
  max-width: 831px;
  display: flex;
  align-items: center;
  background-color: var(--sml-header-content-background);
}

.leftContainer input {
  flex: 1;
  border: none;
  outline: none;
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
}
.leftContainer input::placeholder {
  color: var(--sml-header-placeholder-color);
}

.leftContainer img {
  height: 24px;
  width: 24px;
}
.rightContainer {
  display: flex;
  gap: 16px;
  justify-self: flex-end;
  align-items: center;
  position: relative;
}

.rightContainer button {
  border: none;
}

.profileDropdown {
  padding: 8px 12px;
  box-shadow: var(--sml-header-box-shadow);
  background-color: var(--sml-header-content-background);
  height: 42px;
  border-radius: 8px;
  line-height: 19px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  cursor: pointer;
}

.profileButton {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}

.profileButton img {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

.logoutCard {
  position: absolute;
  top: 100%; 
  right: 0;
  background-color: var(--sml-header-content-background);
  border: 1px solid #ccc;
  border-radius: 0 0 8px 8px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  max-height: 100%;
  min-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoutCard button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 8px 12px;
  text-align: center;
  width: 100%;
  font-size: 14px;
  border-radius: 4px;
}

.logoutCard button:hover {
  background-color: #f2f2f2;
  border-radius: 4px;
}

.planContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.planLabel {
  font-size: 14px;
  color: #6b7280;
}

.currentPlan {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}

.currentPlan.free {
  background-color: #f3f4f6;
  color: #6b7280;
}

.currentPlan.basic {
  background-color: #dbeafe;
  color: #1d4ed8;
}

.currentPlan.essential {
  background-color: #d1fae5; /* Light Green */
  color: #065f46; /* Dark Green */
}

.currentPlan.pro {
  background-color: #fef3c7;
  color: #b45309;
}

.currentPlan.partner {
  background-color: #f3e8ff;
  background-color: #f5f3ff;
  color: #7c3aed;
  border: 1px solid #ddd6fe;
}

.planName {
  color: #23f0c7;
  font-weight: 600;
}
