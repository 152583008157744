.main-container {
  background-color: black;
}
.Login-container {
  background: #ffffff;
  border-radius: 24px;
}
.form-margin {
  margin: 51px;
}
