.contact-signUp-container {
  position: relative;
}
.contact-label-div {
  text-align: center;
}
.contact-close-div {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.contact-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
}
.contact-fields {
  margin-top: 2rem;
  width: 495px;
}
.buttons-container {
  margin-top: 2rem;
}
.contact-backBtn {
  position: absolute;
  bottom: 0;
  left: 0;
}
.Contact-Btn {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 1200px) {
  .contact-signUp-container {
    height: 80vh;
  }
  .contact-fields{
    width: 100%;
    
  }
}
