.signup-card {
  border-radius: 24px;
}

.cardHeader {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  width: 377.16px;
  height: 22px;
  top: 122px;
  left: 70px;
  color: #000000;
}

.signUp-right-content {
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.signUp-controls {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.signUp-divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.signUp-fields {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
}

.signUp-groupby {
  margin-top: 0;
  display: flex;
  align-items: center;
  gap: 3px;
}

.div-vertical-line {
  width: 1px;
  height: 200px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  margin: 0 10px;
}

.signUp-back {
  width: 111px;
  height: 37px;
  top: 619px;
  left: 32px;
  padding: 8px;
  gap: 8px;
}
.continue-btn {
  position: absolute;
  top: 145px;
  left: 330%;
}
