.supAssign-container {
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
  z-index: 9999;
}

.supAssign-card {
  border-radius: 24px;
  background-color: #fff;
  border: 1px solid #e9eaec;
  position: relative;
  height: 80%;
  width: 40%;
  padding: 6rem 2rem;
}
.supAssign-success-card {
  border-radius: 24px;
  background-color: #fff;
  border: 1px solid #e9eaec;
  position: relative;
  height: 60%;
  width: 40%;
  padding: 6rem 2rem;
}
.supAssign-main {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
}

.supAssign-Popup-Content {
  display: flex;
  position: relative;
  width: 100%;
  height: 110%;
  gap: 32px;
  flex-direction: column;
}

.supAssign-heading {
  font-size: 1.6rem;
  text-align: left;
  color: #333;
}

.supAssign-close-div {
  position: absolute;
  top: 5%;
  right: 5%;
  cursor: pointer;
}

.supAssign-GroupBy {
  color: #333;
  display: flex;
  align-items: center;
  gap: 4px;
}

.supAssign-GroupBy-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-height: 400px;
  overflow-y: auto;
}

.supAssignButton {
  border: none;
  position: absolute;
  bottom: 5%;
  right: 5%;
  color: #333;
  background-color: orangered;
  padding: 1rem 2rem;
  border-radius: 10px;
  cursor: pointer;
}

.supAssignButton.disabled {
  border-radius: 8px;
  gap: 10px;
  background-color: #cccccc;
  border-color: transparent;
}
.checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 5px 0;
  width: 100%;
}

@media screen and (max-width: 1300px) {
  .supAssign-card {
    width: 60%;
  }
}
@media screen and (max-width: 800px) {
  .supAssign-card {
    width: 97%;
  }
}
