.banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
  height: 100dvh;
}

.text-container {
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.text-container {
  max-width: 75%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.text-container h1 {
  font-family: "Thunder-SemiBold";
  font-weight: 600;
  font-size: clamp(3rem, 7vw, 116px);
  line-height: 1;
  background: linear-gradient(101.3deg,
      #e62e05 23.86%,
      #97180c 42.35%,
      #57130a 62.23%,
      #141414 79.06%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-container p {
  font-family: "ElzaRegular";
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 1.7;
  text-align: center;

  margin: 20px auto;
  position: relative;
  max-width: 58%;
}

.logos img {
  max-height: 10vw;
  margin: 0 20px;
}

.logos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  animation: scroll 40s linear infinite;
  display: block;
}

.scroll-container {
  width: 100%;
  justify-content: center;
  white-space: nowrap;
  position: relative;
}

@keyframes scroll {
  0% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 768px) {
  .text-container {
    max-width: 100%;
  }

  .text-container h1 {
    font-size: 11vw;
    padding: 5vw;
  }

  .text-container p {
    font-size: 3vw;
    max-width: 80%;
  }

  .logos img {
    max-height: 15vw;
    margin: 0 2vw;
  }
}

@media (max-width: 425px) {
  .text-container h1 {
    font-size: 12vw;
    padding: 0;
  }

  .text-container p {
    font-size: 4.5vw;
  }
}