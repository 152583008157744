.consistent-container {
  display: flex;
  flex-direction: row;
}

.consistent-content {
  display: flex;
  flex-direction: column;
}

.consistent-header {
  color: black;
  height: 60px;
  top: 32px;
  left: 40px;
}

.consistent-main-content {
  flex-grow: 1;
  padding: 31px 30px 50px 53px;
  margin-top: 20px;
  width: 244px;
}

.consistent-card {
  width: auto;
  height: 112px;
  top: 128px;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  position: absolute;
  margin-left: 50px;
  display: flex;
}

.consistent-card-left {
  flex: 1;
  border-radius: 8px;
}

.consistent-card-right {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 20px;
}
.consistent-card-right-div {
  display: flex;
  align-items: center;
}

.consistent-wrapper {
  width: 750px;
  height: 639px;
  gap: 0px;
  margin-left: 50px;
  margin-top: 210px;
}

.consistent-wrapper-div {
  width: 750px;
  height: 114px;
  padding: 16px;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  margin-bottom: 8px;
  display: flex;
}

.consistent-wrapper-left-div {
  width: auto !important;
  margin-right: 26px;
}

.consistent-custom-grid {
  display: grid;
  grid-template-columns: 10fr 3fr;
}

.consistent-btn-width {
  max-width: max-content;
}

.video-player {
  position: relative;
}

.controls {
  position: absolute;
  top: 55%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.controls button {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.progress {
  position: relative;
  bottom: 0;
  height: 5px !important;
  background-color: #ccc;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #007bff;
}
