.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    z-index: 1000;
    overflow-y: auto;
    padding: 20px 0;
  }
  
  .modal-backdrop .modal-content {
    background: white !important;
    border-radius: 24px !important;
    border: 1px solid #e9eaec;
    position: relative;
    width: 720px !important;
    max-width: 720px !important;
    height: auto;
    padding: 40px;
    margin: 20px auto;
  }
  
  .modal-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
    background: white;
    border-bottom: none;
    padding: 0;
  }
  
  /* Rest of your styles remain the same */
  .close-button {
    position: absolute;
    right: -20px;
    top: -20px;
    background: none;
    border: none;
    cursor: pointer;
    color: #999999;
    width: 16px;
    height: 16px;
    padding: 1px;
  }
  
  form {
    background: white;
    padding: 20px 0;
  }
  
  .form-group {
    margin-bottom: 20px;
    background: white;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 8px;
    color: black;
    background: white;
  }
  
  .form-control {
    width: 100%;
    height: 48px;
    background: #f5f7f9 !important;
    border: none;
    border-radius: 8px;
    padding: 0 16px;
    color: black;
  }
  
  textarea.form-control {
    height: 120px;
    padding: 16px;
    resize: vertical;
    background: #f5f7f9 !important;
    color: black;
  }
  
  .modal-footer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 24px;
    padding: 24px 0 0 0;
    background: white;
    border-top: 1px solid #e9eaec;
  }
  
  input[type="file"] {
    height: 48px;
    background: #f5f7f9 !important;
    border: none;
    border-radius: 8px;
    padding: 12px;
    color: black;
  }
  
  input[type="file"]::file-selector-button {
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 6px 12px;
    margin-right: 12px;
    color: black;
  }
  
  /* Rest of your resource list styles */
  .resource-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .resource-item {
    padding: 16px;
    background: white;
    border-radius: 8px;
    border: 1px solid #e9eaec;
  }
  
  .resource-content {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    justify-content: space-between;
  }
  
  .thumbnail-column {
    flex: 0 0 100px;
    margin-right: 16px;
  }
  
  .resource-title-section {
    flex: 0 0 500px;
  }
  
  .resource-title-section .title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #333;
  }
  
  .resource-title-section .description {
    margin: 4px 0 0;
    font-size: 14px;
    color: #666;
  }
  
  .resource-info-grid {
    flex: 1;
    margin-left: auto;
  }
  
  .column-labels {
    display: grid;
    grid-template-columns: 160px 100px 90px 200px 90px auto;
    gap: 20px;
    margin-bottom: 8px;
  }
  
  .column-values {
    display: grid;
    grid-template-columns: 160px 100px 90px 200px 90px auto;
    gap: 20px;
    align-items: center;
  }
  
  .label {
    font-size: 12px;
    color: #6b7280;
    font-weight: 500;
    text-transform: uppercase;
  }
  
  .value {
    font-size: 14px;
    color: #111827;
  }
  
  .status-stack {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .status-tag {
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
  
  .category-tag {
    background: #f0f2f5;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 14px;
    display: inline-block;
  }
  
  .category-tag.brief {
    background: #fff2f0;
    color: #ff4405;
  }
  
  .category-tag.video {
    background: #e8f5ff;
    color: #0066cc;
  }
  
  .category-tag.template,
  .category-tag.guide {
    background: #f0f7f0;
    color: #2e7d32;
  }
  
  .plan-tags {
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
  }
  
  .plan-tag {
    background: #e8f5ff;
    color: #0066cc;
    padding: 2px 8px;
    border-radius: 4px;
    font-size: 12px;
  }
  
  .actions .column-value {
    display: flex;
    gap: 16px;
  }
  
  .resource-link {
    text-decoration: none;
    color: inherit;
  }
  
  .resource-link:hover {
    text-decoration: underline;
  }
  
  .text-danger {
    color: #dc3545;
  }
  
  /* Handle text overflow */
  .column-value {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  @media only screen and (max-width: 710px) {
    .modal-backdrop .modal-content {
        width: 90% !important;
        max-width: 480px !important;
        height: auto;
        border-radius: 12px;
        padding: 24px;
      }
    
    .form-group {
      margin-bottom: 12px;
    }
  }

.plans-section {
  margin: 24px 0;
}

.plans-label {
  font-size: 14px;
  color: #374151;
  margin-bottom: 12px;
}

.plans-checkboxes {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 20px;
}

.plan-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f3f4f6;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
}

.plan-checkbox:hover {
  background: #e5e7eb;
}

.plan-checkbox input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
  accent-color: #00E6C3;
  cursor: pointer;
}

/* For browsers that don't support accent-color */
.plan-checkbox input[type="checkbox"]:checked {
  background-color: #00E6C3;
  border-color: #00E6C3;
}

/* Optional: Add a subtle hover effect */
.plan-checkbox input[type="checkbox"]:hover {
  outline: 1px solid #00E6C3;
}

.status-toggles {
  display: flex;
  gap: 16px;
  margin-top: 20px;
}

.status-toggle {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background: #f3f4f6;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
}

.status-toggle:hover {
  background: #e5e7eb;
}

.status-toggle input[type="checkbox"] {
  margin: 0;
  width: 16px;
  height: 16px;
  accent-color: #00E6C3;
  cursor: pointer;
}

/* For browsers that don't support accent-color */
.status-toggle input[type="checkbox"]:checked {
  background-color: #00E6C3;
  border-color: #00E6C3;
}

/* Optional: Add a subtle hover effect */
.status-toggle input[type="checkbox"]:hover {
  outline: 1px solid #00E6C3;
}

.meta {
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #666;
  font-size: 0.9em;
  margin-top: 8px;
}

.meta div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.upload-files-container {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.thumbnail-section {
  flex: 0 0 200px; /* Fixed width for thumbnail section */
}

.thumbnail-upload {
  margin-top: 10px;
}

.thumbnail-preview {
  width: 200px;
  height: 150px; /* Increased height for 4:3 aspect ratio */
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  background: #f8f9fa;
}

.thumbnail-preview img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Changed from cover to contain */
  padding: 4px; /* Add some padding around the image */
}

.thumbnail-preview .placeholder-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
}

.thumbnail-preview .placeholder-text i {
  font-size: 24px;
  margin-bottom: 8px;
}

.file-section {
  flex: 1;
}

.file-name {
  margin-top: 8px;
  color: #666;
  font-size: 0.9em;
}

@media only screen and (max-width: 768px) {
  .modal-backdrop .modal-content {
    width: 90% !important;
    padding: 24px;
  }

  .upload-files-container {
    flex-direction: column;
  }
}

/* Add smooth scrolling to the page when modal is open */
body.modal-open {
  overflow: hidden;
}

/* Make form content scrollable if needed */
.modal-content form {
  max-height: calc(100vh - 160px); /* Subtract padding and margins */
  overflow-y: auto;
}

.resource-thumbnail {
  width: 100px;
  height: 75px;
  object-fit: contain;
  border-radius: 4px;
  background: #f5f7f9;
}

.thumbnail-placeholder {
  width: 100px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7f9;
  border-radius: 4px;
  color: #999;
}

.thumbnail-placeholder i {
  font-size: 24px;
}

.filter-toggles {
  display: flex;
  gap: 12px;
  align-items: center;
}

.filter-toggle {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 4px 8px;
  min-width: 100px; /* Fixed width for consistency */
  justify-content: center;
  border-radius: 4px;
  background: #f5f7f9;
  color: #6b7280;
  cursor: pointer;
  user-select: none;
  transition: all 0.2s ease;
  font-size: 14px;
}

.filter-toggle input[type="checkbox"] {
  margin: 0;
}

.filter-toggle:hover {
  background: #e5e7eb;
}

.filter-toggle.active {
  background: #374151;
  color: white;
}

/* Add styles for disabled state */
.filter-toggle.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.filter-toggle.disabled:hover {
  background: #f5f7f9;
}

.search-container {
  flex: 1;
  max-width: 600px;
  margin: 0 24px;
}

.search-input-wrapper {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  height: 36px;
  padding: 8px 40px 8px 20px; /* Added right padding for clear button */
  border-radius: 18px;
  border: 1px solid #e5e7eb;
  background: #f9fafb;
  font-size: 14px;
}

.search-input:focus {
  outline: none;
  border-color: #FF4405;
  box-shadow: 0 0 0 2px rgba(255, 68, 5, 0.1);
}

.search-clear-button {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  font-size: 20px;
  color: #6b7280;
  cursor: pointer;
  padding: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.search-clear-button:hover {
  background: #e5e7eb;
}

.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  user-select: none;
}

.checkbox-container input[type="checkbox"] {
  width: 16px;
  height: 16px;
}

/* Adjust category buttons */
.SMLToggleBtn-orange,
.SMLToggleBtn-white {
  padding: 6px 24px; /* Increased horizontal padding */
  height: 36px;
  font-size: 14px;
  border-radius: 18px;
  min-width: 140px; /* Added minimum width */
  white-space: nowrap;
}

.supd-header2 {
  padding: 12px 16px; /* Reduced padding */
}

.category-buttons {
  display: flex;
  gap: 12px;
}

.edit-form-section {
  padding: 24px;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #374151;
  margin-bottom: 8px;
  display: block;
}

.order-input {
  max-width: 120px;
}

/* Modal specific styles */
.modal-content {
  padding: 24px;
}

.modal-header {
  margin-bottom: 24px;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
}

.resource-metadata {
  display: flex;
  gap: 20px;
  margin-top: 8px;
  font-size: 14px;
}

.metadata-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.metadata-label {
  color: #6b7280;
  font-weight: 500;
}

.metadata-value {
  color: #111827;
}

.metadata-value.expired {
  color: #DC2626;
}

.status-tag {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.status-tag.hidden {
  background-color: #FEF3C7;
  color: #92400E;
}

.status-tag.archived {
  background-color: #E5E7EB;
  color: #374151;
}

.status-tag.active {
  background-color: #D1FAE5;
  color: #065F46;
}

/* Ensure datetime input has consistent styling */
input[type="datetime-local"] {
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  max-width: 300px;
}

input[type="datetime-local"]:focus {
  outline: none;
  border-color: #00E6C3;
  box-shadow: 0 0 0 2px rgba(0, 230, 195, 0.1);
}

.status-stack {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.status-tag {
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}

