.dashboardContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "banner banner banner banner upload upload"
    "video video video video r-activity r-activity"
    "stats-card stats-card stats-card stats-card stats-card stats-card";
  gap: 16px;
  margin-top: 16px;
  color: black;
}

.bannerContainer {
  grid-area: banner;
  background: var(--sml-dashboard-banner-backgroud) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--sml-text-color-dark-surface) !important;
  position: relative;
  overflow: hidden;
  gap: 20px;
  padding: 32px 12px;
}

.bannerBackgroundImage {
  overflow: hidden;
  position: absolute;
  bottom: -5px;
  width: 100% !important;
  height: 100% !important;
  z-index: 0;
}

.bannerContainer div {
  z-index: 0;
}

.bannerContainer h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
}
.bannerContainer h5 {
  font-size: 14px;
  font-weight: 400px;
  line-height: 19px;
  color: white;
}
.bannerContainer img {
  height: 22px;
  width: 35px;
}

.uploadContainer {
  grid-area: upload;
  background-color: var(--sml-upload-background-color) !important;
  color: var(--sml-text-color-dark-surface);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding: 24px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.uploadContainer:hover {
  opacity: 0.95;
  transform: translateY(-1px);
}

.uploadContainer:active {
  transform: translateY(0);
}

.uploadContainer.isPaidUser > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
}

.uploadContainer.isPaidUser img {
  width: 48px;
  height: 48px;
}

.uploadContainer.isPaidUser h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
}

.videoContainer {
  grid-area: video;
  display: flex;
  padding: 0px !important;
  flex-direction: column;
}
.videoContainer div:nth-of-type(2) {
  padding: 20px;
}
.videoContainer div:nth-of-type(2) h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--sml-label-color);
}
.videoContainer div:nth-of-type(2) h4 {
  margin: 0px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #333;
}

.recentActivityContainer {
  grid-area: r-activity;
  display: flex;
  flex-direction: column;
}
.recentActivityContainer div:nth-of-type(1) {
  flex: 1;
}

.recentActivityContainer h6 {
  color: var(--sml-label-color);
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.recentActivityContainer h2 {
  font-weight: 700;
  font-size: 20px;
  line-height: 22px;
  flex: 1;
  color: black;
}
.recentActivityContainer div:nth-of-type(2) {
  display: flex;
}

.recentActivityContainer h4 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--sml-label-color);
}

.recentActivityContainer div:nth-of-type(2) div {
  display: flex;
  flex-direction: column;
}
.recentActivityContainer div:nth-of-type(2) div:nth-child(2) {
  align-items: flex-start;
}

.recentActivityContainer div:nth-of-type(2) div:nth-child(2) {
  align-items: flex-end;
}
.dashboardSubContainer {
  grid-area: stats-card;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.statusCard {
  width: calc(55% - 17px);
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 25px;
  cursor: pointer;
}
.statusCard img {
  width: 24px;
  height: 24px;
}

.statusCard h2 {
  flex: 3;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  color: black;
}
.statusCard h3 {
  text-align: right;
  flex: 2;
  font-size: 35px;
  font-weight: 700;
  line-height: 36px;
  color: black;
}

.tracksContainer {
  width: 45%;
  padding: 4px 0px 8px 4px;
  min-height: 230px;
  max-height: 230px;
  /* overflow: auto; */
}
.tracksEmptyPlaceholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: 16px 8px 0px 8px;
}
.tracksSecondaryText {
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  color: var(--sml-label-color);
  padding: 16px 8px;
}
.trackRow {
  padding: 5px 8px 5px 8px;
  gap: 8px;
  border: 0px 0px 0.5px 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border-bottom: 0.5px solid var(--sml-divider-color);
}
.trackRow h2 {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px; /* Adjust this width as needed */
  display: inline-block;
  vertical-align: middle;
}
.trackRow img {
  width: 24px;
  height: 24px;
}

@media screen and (min-width: 850px) {
  .dashboardContainer {
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      "banner  upload"
      "stats-card  upload"
      "stats-card video"
      "stats-card video"
      "stats-card r-activity"
      "stats-card r-activity";
  }
  .uploadContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .uploadContainer img {
    width: 85px;
    height: 62px;
  }
}
h5 {
  color: black;
}

.videoContainer {
  grid-area: video;
  display: flex;
  flex-direction: column;
  padding: 0 !important;
  overflow: hidden;
}

.playerWrapper {
  position: relative;
  width: 100%;
}

.reactPlayer {
  position: absolute;
  top: 0;
  left: 0;
}

.videoInfo {
  padding: 20px;
}

.videoInfo h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--sml-label-color);
  margin: 0;
}

.videoInfo h4 {
  margin: 5px 0 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #333;
}
.scrollable {
  overflow-y: auto;
}

@media (max-width: 850px) {
  .statusCard,
  .tracksContainer {
    min-height: 0px;
    height: 161px;
  }
  .uploadContainer,
  .bannerContainer {
    height: 89px;
  }
}
@media (max-width: 600px) {
  .dashboardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .bannerContainer {
    width: 250px;
  }
  .uploadContainer {
    width: 250px;
  }
  .videoContainer {
    width: 250px;
  }
  .dashboardSubContainer {
    width: 250px;
  }
  .statusCard {
    width: 100%;
  }
  .tracksContainer {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .dashboardContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .bannerContainer {
    width: 150px;
  }
  .uploadContainer {
    width: 150px;
  }
  .videoContainer {
    width: 150px;
  }
  .dashboardSubContainer {
    width: 150px;
  }
  .statusCard {
    width: 150px;
  }
  .statusCard h2 {
    font-size: 10px;
  }
  .statusCard h3 {
    font-size: 15px;
  }
  .tracksContainer {
    width: 100%;
  }
}

.upgradeIcon {
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.uploadIcon {
  width: 48px;
  height: 48px;
}

.upgradeTitle {
  font-size: 24px;
  font-weight: 600;
  color: var(--sml-text-color-dark-surface);
  margin: 0;
}

.upgradeText {
  font-size: 16px;
  line-height: 1.5;
  color: var(--sml-text-color-dark-surface);
  opacity: 0.8;
  margin: 0;
  max-width: 280px;
}

.upgradeButton {
  background: white;
  color: var(--sml-upload-background-color);
  border: none;
  border-radius: 8px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.upgradeButton:hover {
  opacity: 0.9;
  transform: translateY(-1px);
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.currentPlan {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
  gap: 4px;
}

.planName {
  color: #ff4405;
  font-weight: 500;
}