.container {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #f5f7f9;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.childrenContainer {
  flex: 1;
  padding: 30px;
  /* padding-top: 48px; */
  overflow-y: hidden;
}

.scrollableContainer {
  overflow-y: auto;
}
