.footer-section .Parallax {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  background-color: rgb(20, 20, 20);
}

.footer-section .Parallax-Form-container {
  padding: 40px;
  border-radius: 8px;
  color: #cbcbcb;
  max-width: 600px;
  width: 50%;
  position: relative;
  z-index: 2;
  background-color: rgb(20, 20, 20);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

/* Header section */
.footer-section .Parallax-Header {
  text-align: right;
  background-color: rgb(20, 20, 20);
}

.footer-section .Parallax-Question-content {
  font-size: 70px;
  font-weight: 400;
  margin-bottom: 10px;
  font-family: Thunder;
  color: #ffffff;
  background-color: rgb(20, 20, 20);
}

.footer-section .Body {
  text-align: right;
  font-size: 16px;
  color: #aaaaaa;
  background-color: rgb(20, 20, 20);
}

/* Content area */
.footer-section .Parallax-Content-Area {
  width: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  background-color: rgb(20, 20, 20);
}

.footer-section .Parallax-Form {
  width: 100%;
  display: grid;
  gap: 16px;
  background-color: rgb(20, 20, 20);
}

/* Form elements */
.footer-section .Parallax-Form-Contents,
.footer-section .Parallax-Form-Content {
  margin-bottom: 20px;
  background-color: rgb(20, 20, 20) !important;
}

.footer-section .Parallax-Form .SMLLabel-container,
.footer-section .Parallax-Form label,
.footer-section .SMLLabel {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: rgb(20, 20, 20) !important;
}

.footer-section .Parallax-Form input,
.footer-section .Parallax-Form textarea,
.footer-section .SMLTextBox,
.footer-section .SMLTextArea {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #ffffff !important;
  color: #333333 !important;
  font-size: 14px;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
}

.footer-section .submit-button {
  width: 100%;
  padding: 12px;
  background-color: #333 !important;
  color: #fff;
  border: 1px solid #555;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.footer-section .submit-button:hover {
  background-color: #444 !important;
  border-color: #666;
}

.footer-section .submit-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Success and error messages */
.footer-section .success-message {
  color: #4ECCA3;
  font-size: 32px;
  text-align: right;
  padding: 40px 0;
  background-color: rgb(20, 20, 20);
  animation: fadeIn 0.5s ease-in-out;
  line-height: 1.4;
}

.footer-section .error-message {
  color: #f44336;
  padding: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  background-color: rgb(20, 20, 20);
}

.footer-section .error-text {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  background-color: rgb(20, 20, 20);
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .footer-section .Parallax {
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .footer-section .Parallax-Form-container {
    max-width: 500px;
    width: 100%;
    padding: 20px;
  }

  .footer-section .Parallax-Form {
    padding: 10px;
  }

  .footer-section .Parallax-Question-content {
    font-size: 12vw;
    text-align: center;
  }

  .footer-section .Body,
  .footer-section .success-message {
    text-align: center;
  }

  .footer-section .SMLTextArea {
    max-width: 100% !important;
  }
}