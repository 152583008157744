.section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.main-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    color: #fff;
    z-index: 10;
}

.main-section h1 {
    font-size: min(12vw, 140px);
    font-family: "Thunder-SemiBold";
    font-weight: 600;
    color: black;
    line-height: 0.9;
    width: min(65vw, 750px);
}

.main-section p {
    font-size: min(1.7vw, 20px);
    font-family: "ElzaMedium";
    font-weight: 500;
    color: black;
    max-width: min(60vw, 680px);
    padding: 0 2rem;
    text-align: center;
}

.trial-button {
    padding: 1rem 2rem;
    font-size: 1rem;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 8px;
    font-family: "DMSansMedium";
    font-weight: 500;
    margin-top: 30px;
    transition: all 0.3s ease;
}

.trial-button:hover {
    color: #000;
    background-color: #fff;
}

.features {
    display: flex;
    justify-content: space-between;
    font-size: min(2vw, 24px);
    font-family: "ElzaMedium";
    font-weight: 500;
    color: #141414;
    position: relative;
    width: 85vw;
    padding-bottom: 20px;
}

.feature-item {
    opacity: 0;
}

.feature-item.visible {
    transform: translateY(0);
    opacity: 1;
}

@media (max-width: 768px) {
    .trial-button {
        margin-top: 0px;
    }

    .main-section {
        padding-top: 140px;
    }

    .main-section p {
        font-size: min(4vw, 20px);
        font-family: "ElzaMedium";
        font-weight: 500;
        color: black;
        max-width: min(60vw, 680px);
        padding: 0;
        text-align: center;
    }

    .features {
        flex-direction: column;
        align-items: start;
        font-size: min(4vw, 24px);
    }
}