.aboutTrack {
  padding-bottom: 1rem;
  border-radius: 24px;
  background-color: #ffffff;
  position: relative;
  display: flex;
  flex-direction: column;
}

.aboutTrack-Frame {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-family: DMsans;
}

.aboutTrack-rectangle {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.aboutTrackHeadbar {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.aboutTrack-header {
  text-align: center;
  flex-grow: 1;
}

.aboutTrack-close-div {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.aboutTrack-content {
  display: grid;
  grid-template-columns: 2fr 1px 2fr;
  gap: 20px;
  padding: 40px;
}

.aboutTrack-left,
.aboutTrack-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.aboutTrack-divider {
  background-color: #e5e5e5;
  width: 1px;
}

.aboutTrack-input,
.aboutTrack-input1,
.aboutTrackStatus-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 15px; */
  gap: 20px;
}

.body1 {
  font-size: 16px;
  font-weight: 600;
  color: #777;
  text-wrap: wrap;
  width: 100%;
  word-break: break-word;
  white-space: normal;
  overflow-wrap: break-word;
  text-align: right;
  font-family: DM Sans;
}

.d-flex {
  display: flex;
}

.title2 {
  font-weight: bold;
  margin-bottom: 5px;
}
.title2.back-btn {
  font-weight: bold;
  margin-bottom: 0px;
}

.gap-1 {
  gap: 5px;
}
.Mainheader p {
  margin-top: 20px;
  font-size: 14px;
  line-height: 20px;
  padding: 0 100px;
  font-weight: 300;
  color: #999999;
}
#trackInfo {
  width: 20vw;
}
@media (max-width: 992px) {
  .aboutTrack {
    padding-bottom: 2rem;
  }
  .aboutTrack-content {
    padding: 20px;
  }
  .Mainheader p {
    padding: 0 20px;
  }
  .aboutTrack-input {
    width: 100%;
  }
}

/* Add these properties to text-containing elements */
.aboutTrack-header,
.title2,
.body1,
.Mainheader {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}
