.supd-text,
.supd-heading,
.supd-stats {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

body {
  background: none !important;
}

.marginRight {
  margin-right: 24px;
}
.supervisor-dashboard-container {
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
}

.bg-main-div {
  margin: 0 auto;
  background-color: #f8f9fa;
  padding: 0 1rem;
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.padding-0 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}
.bg-main-res {
  padding: 0px 50px;
}
.supd-stats-header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.supd-header2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}
.supd-heading {
  display: flex;
}

@media (max-width: 1200px) {
  .supd-heading {
    overflow-x: scroll;
  }
  .supd-header2 {
    gap: 4rem;
  }
}

@media (max-width: 992px) {
  .bg-main-div {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .padding-0 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media screen and (max-width: 770px) {
  .bg-main-res {
    padding: 0px;
  }
  .supd-stats-header {
    flex-direction: column;
  }
  .supervisor-dashboard-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .supd-stats {
    width: 100%;
    height: 100%;
    text-wrap: nowrap;
  }
}
