.trackDetailsContainer {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
}

.trackContentMain {
  flex: 1;
  width: 100%;
  overflow: auto;
  padding: 32px;
}

.trackPlayer {
  background-color: var(--sml-surface2);
  min-height: 72px;
  display: flex;
  align-items: stretch;
  padding: 16px 40px 16px 20px;
  color: var(--sml-text-color-dark-surface);
}

.trackInfo {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 150px;
  width: 35%;
}

.trackInfo h3 {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  margin-top: 0.5rem;
  max-width: 100%; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: block; 
}


.trackInfo h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--sml-text-color-dark-surface-light);
}

.controlsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40%;
}

.trackControls {
  display: flex;
  align-items: center;
  gap: 10px;
}

.trackControls button {
  background: transparent;
  border: transparent;
  height: 24px;
  width: 24px;
  margin: 0px;
  padding: 0px;
}

.trackControls img {
  height: 24px;
  width: 24px;
  margin: 0px;
  padding: 0px;
}

.trackControls button:nth-of-type(2) {
  height: 40px;
  width: 40px;
  padding-bottom: 0px;
}
.trackControls button:nth-of-type(2) img {
  height: 40px;
  width: 40px;
}

.additiotrlControls {
  display: flex;
  align-items: center;
  min-width: 150px;
  justify-content: flex-end;
}

.trackHeaderContainer {
  display: flex;
}

.trackHeaderContainer > div:nth-of-type(1) {
  height: 111px;
  width: 111px;
  border-radius: 8px;
  overflow: hidden;
  background-color: rebeccapurple;
}
.trackHeaderContainer > div:nth-of-type(2) {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-left: 16px;
}

.trackHeaderContainer > div:nth-of-type(2) h2 {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
}

.trackHeaderContainer > div:nth-of-type(2) h4 {
  font-size: 14px;
  font-weight: 500;
  line-height: 18.23px;
  text-align: center;
  padding: 8px;
  border-radius: 8px;
  color: var(--sml-color-primary-dark);
  background: var(--sml-surface-dark);
}

.trackHeaderContainer > div:nth-of-type(3) {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.trackDetailsNavigation {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 32px 0px;
}

.trackDetailsNavigation img:nth-child(1) {
  height: 19px;
  width: 19px;
}

.trackDetailsNavigation img:nth-child(2) {
  height: 16px;
  width: 16px;
}

.trackDetailsNavigation span {
  font-size: 26px;
  font-weight: 600;
  line-height: 31.2px;
  letter-spacing: -0.01em;
  text-align: left;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.popupContent {
  background-color: #fff; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #999;
}

.rangeContainer {
  width: 100px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.customRange {
  appearance: none;
  width: 100%;
  height: 5px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.customRange::-webkit-slider-runnable-track {
  height: 5px;
}

.customRange::-moz-range-track {
  height: 5px;
}

.customRange::-webkit-slider-thumb {
  opacity: 0;
}

.customRange::-moz-range-thumb {
  opacity: 0;
}
.reload-btn {
  color: #a3a3a3;
}
@media (max-width: 700px) {
  .trackInfo {
    min-width: auto !important;
    width: 100%;
  }
  .additionalControls {
    min-width: auto !important;
    width: 100%;
  }
}
@media (max-width: 450px) {
  .rangeContainer {
    min-width: auto !important;
    width: 100%;
  }
}
.durationContainer {
  display: flex;
  align-items: center;
  padding-right: 50px;
}
.volumeControls {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 50px;
}
