.videoContainer {
  grid-area: video;
  display: flex;
  padding: 1rem !important;
  flex-direction: column;
}
.videoPlayerWrapper {
  position: relative;
  width: 100%;
  background: #000;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.videoInfo {
  padding: 20px;
}

.videoInfo h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: var(--sml-label-color);
  margin: 0;
}

.videoInfo h4 {
  margin: 5px 0 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #333;
}

.video-player-modal.custom-modal .modal-content {
  border: none !important;
  border-radius: 12px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
  background: #000 !important;
  overflow: hidden;
  padding: 0 !important;
}

.video-player-modal.custom-modal .modal-dialog {
  margin: 1.75rem auto !important;
  max-width: 80vw !important;
  max-height: 80vh !important;
  display: flex;
  align-items: center;
}
