.footer-back {
  display: flex;
  position: relative;
}

.button-with-icon {
  display: flex;
  justify-content: center;
  border: none;
  background-color: #ffffff;
  gap: 8px;
  align-items: flex-end;
  cursor: pointer;
}
