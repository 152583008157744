/* Modal General Styling */
.cancel-sub-modal .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  border-bottom: none;
  padding: 1.2rem 1.5rem;
  background-color: #f8f9fa;
  border-radius: 10px;
}

.cancel-sub-modal .modal-title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
}

.cancel-sub-modal .close-icon {
  padding: 1rem;
  cursor: pointer;
}

.cancel-sub-modal .modal-body {
  padding: 0 1.5rem;
  background-color: #ffffff;
  color: #555;
  border-radius: 10px;
}

/* Form Elements Styling */
.cancel-sub-modal label {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin-bottom: 0.5rem;
}

.cancel-sub-modal select,
.cancel-sub-modal textarea {
  width: 100%;
  font-size: 1rem;
  color: #333;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 6px;
  transition: border-color 0.2s ease;
}

.cancel-sub-modal select:focus,
.cancel-sub-modal textarea:focus {
  border-color: #007bff;
  outline: none;
}

.cancel-sub-modal .modal-body select {
  appearance: none;
  background-position: right 0.75rem center;
  background-size: 1rem;
}

.cancel-sub-modal textarea {
  resize: vertical;
  min-height: 100px;
  padding-bottom: 2rem;
}

/* Confirmation Text */
.cancel-sub-modal .confirmation-text {
  font-size: 1rem;
  font-weight: 600;
  color: #444;
  margin-top: 1.5rem;
  text-align: center;
}

/* Button Styling */
.cancel-sub-modal .modal-footer {
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 2rem;
  padding: 1rem 0;
}

.cancel-sub-modal .btn-danger {
  background-color: #dc3545;
  border: none;
  padding: 0.6rem 1.2rem;
  font-weight: bold;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.cancel-sub-modal .btn-danger:hover {
  background-color: #c82333;
}

.cancel-sub-modal .btn-secondary {
  background-color: white;
  color: #555;
  border: none;
  padding: 0.6rem 1.2rem;
  font-weight: bold;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.cancel-sub-modal .btn-secondary:hover {
  background-color: #d6d8db;
}

/* Animation for Modal Appearance */
.cancel-sub-modal .modal-content {
  animation: modal-appear 0.3s ease-out;
}

@keyframes modal-appear {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
