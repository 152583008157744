.radiobtn-lbl {
  margin-left: 15px;
  vertical-align: middle;
}

.SMLRadioButton {
  width: 20px;
  height: 22px;
  vertical-align: middle;
}
