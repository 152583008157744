html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow: visible;
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
  font-weight: 400;
}

#root {
  overflow: visible !important;
}

.smooth-scroll-container {
  position: relative;
  width: 100%;
  overflow: visible;
}

.home-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Background layers */
.background-layers {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  overflow: visible;
}

/* Main background with parallax */
.main-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform 0.5s ease-out;
}

.landing-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  background-image: url("../../assets/Images/bgImage1.svg");
  background-size: cover;
  background-repeat: no-repeat;
}

/* Continuous dark background overlay */
.continuous-dark-background {
  position: absolute;
  top: 200vh;
  /* Starts after intro section */
  left: 0;
  width: 100%;
  height: 800vh;
  background-color: rgb(20, 20, 20);
  z-index: 0;
}

/* Hero section */
.hero-section {
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.hero-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-content {
  position: fixed;
  z-index: 2;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.hero-content .scroll-container {
  top: -100px;
}

.hero-placeholder {
  height: 100vh;
  width: 100%;
}

/* Hero section effects */
.noise-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/Images/noiseImage.svg");
  background-size: auto;
  background-repeat: repeat;
  opacity: 0.2;
  z-index: 1;
}

.effect-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.image-effect {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  height: 3000px;
  width: 3000px;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-image: url("../../assets/Images/bgImg2.svg");
  background-size: auto;
  background-position: center;
  animation: rotate 10s linear infinite;
}

/* Content container */
.content-container {
  position: relative;
  z-index: 1;
}

/* Intro section */
.intro-section {
  position: relative;
  z-index: 2;
}

.intro-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(20, 20, 20);
  border-radius: 30px;
}

/* Dynamic text section */
section.dynamic-text-section {
  width: 100%;
  height: 100%;
  z-index: 3;
  place-items: center;
  display: grid;
  justify-content: center;
}

.dynamic-text-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 3;
}

.section {
  min-height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

section#videoSection {
  width: 100%;
  height: 100%;
  z-index: 3;
  place-items: center;
  display: grid;
  justify-content: center;
}

/* Dark background sections */
.dark-background-sections {
  position: relative;
  z-index: 2;
  background: transparent;
}

.how-it-works-section-wrapper {
  /* padding: 20px; */
  position: sticky;
  top: 0;
  overflow: hidden;
  min-height: 100vh;
}

.how-it-works-section {
  width: 100%;
  min-height: 100vh;
  will-change: transform;
  display: grid;
  place-items: center;
}

.section-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/* Footer styles */
#footer {
  width: 100%;
  padding: 20px;
}

.footer-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: rgb(20, 20, 20);
  border-radius: 30px;
}

.footer-section {
  width: 100%;
  padding: 20px;
  overflow: hidden;
}


@keyframes rotate {
  from {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@media (max-width: 425px) {

  .section,
  .footer-section {
    padding: 10px;
  }
}

@media (max-width: 768px) {
  .hero-content .scroll-container {
    top: -20px;
  }
}



.sticky-hero {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 100vh;
  width: 100%;
  transform: translateZ(0);
  will-change: transform;
}

/* Add specific font weights for home page elements */
.hero-content,
.intro-content,
.how-it-works-section,
.section {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

/* Adjust heading weights specifically for home page */
.hero-content h1,
.hero-content h2,
.hero-content h3,
.intro-content h1,
.intro-content h2,
.intro-content h3 {
  font-weight: 500;  /* or adjust to desired weight */
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

/* Root level styles */
html,
body,
#root,
.smooth-scroll-container,
.content-wrapper,
.modal-content,
.modal-body {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

/* Target modal content specifically */
.modal-content,
.modal-content * {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}

/* Ensure font smoothing persists in modal */
.modal-dialog[role="document"] * {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  text-rendering: optimizeLegibility !important;
}