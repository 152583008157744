.tracksViewContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.tracksViewHeaderContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.tracksViewLabelContainer {
  margin-left: 24px;
}

.tracksViewLabelContainer img {
  height: 20px;
  width: 20px;
}

.tracksViewLabelContainer h2 {
  font-size: 26px;
  font-weight: 700;
  line-height: 33.85px;
  letter-spacing: -0.02em;
  text-align: left;
}

.tracksViewLabelContainer h4 {
  font-size: 20px;
  font-weight: 400;
  line-height: 26.04px;
  text-align: left;
}

.tracksOverviewImageContainer {
  display: flex;
  width: calc(56px * 2);
  flex-wrap: wrap;
  border-radius: 8px;
  overflow: hidden;
}

.tracksOverviewImageItem {
  width: 56px;
  height: 56px;
  background: var(--sml-track-icon-default-background);
}

.trackListContainer {
  margin-top: 30px;
  overflow-y: auto;
  flex-grow: 1;
}

.trackCard {
  display: flex;
  gap: 16px;
  align-items: stretch;
  max-width: 750px;
  margin-top: 16px;
  position: relative;
  cursor: pointer;
}

.trackImage {
  height: 82px;
  width: 82px;
  background-color: var(--sml-track-icon-default-background);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.playPauseControllbutton {
  position: absolute;
  height: 24px;
  width: 24px;
  top: 50%;
  left: 50%;
  transform: translate(-12px, -8px);
}
.playPauseControllbutton:hover,
.playPauseControllbutton:focus {
  transform: translate(-12px, -8px) scale(1.2);
}

.trackNameAndLabelContaienr {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trackNameAndLabelContaienr h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.trackLengthAndStatusContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.trackLengthAndStatusContainer * {
  text-align: right;
}

.trackLengthAndStatusContainer h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: right;
}

.noTracksContainer h6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.trackSeeker {
  position: absolute;
  width: 100%;
  height: 5px;
  bottom: -5px;
  overflow: hidden;
  left: 0px;
  border-radius: 8px;
  background: var(--sml-color-primary-light);
}
.trackSeekerProgress {
  height: 100%;
  background-color: var(--sml-color-primary);
}


.metadataContainer {
  display: flex;
  gap: 24px;
  align-items: center;
}

.revisionContainer {
  display: flex;
  align-items: center;
}

.revisionText {
  display: flex;
  align-items: center;
  gap: 4px;
}

.viewOriginalLink {
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  margin-left: 4px;
  color: var(--sml-color-primary, #FF5733);
  cursor: pointer;
}

.viewOriginalLink:hover {
  color: var(--sml-color-primary-dark, #E64A2E);
}

/* Animations for re-upload elements */
@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes fadeInBlink {
  0% { opacity: 0.8; }
  50% { opacity: 1; }
  100% { opacity: 0.8; }
}

@keyframes slideIn {
  0% { transform: translateX(20px); opacity: 0; }
  100% { transform: translateX(0); opacity: 1; }
}

.trackCardReupload {
  border: 1px solid #FF4405;
  border-radius: 8px;
  background-color: rgba(255, 68, 5, 0.05);
  position: relative;
  transition: box-shadow 0.3s ease;
}

.trackCardReupload:hover {
  box-shadow: 0 0 8px rgba(255, 68, 5, 0.4);
}

.reuploadIndicator {
  position: absolute;
  top: 0;
  right: 0;
  width: 24px;
  height: 24px;
  border-radius: 0 8px 0 8px;
  background-color: #FF4405;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-size: 14px;
  z-index: 10;
  animation: pulse 1.5s infinite ease-in-out;
  box-shadow: 0 0 0 rgba(255, 68, 5, 0.5);
}

.reuploadTag {
  position: absolute;
  top: 10px;
  right: 30px;
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  white-space: nowrap;
  background-color: #FF4405;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
  z-index: 9;
  animation: fadeInBlink 2s infinite ease-in-out, slideIn 0.5s ease-out;
}

.reuploadText {
  color: #FFFFFF;
  font-weight: 500;
  font-size: 12px;
  margin-left: auto;
  white-space: nowrap;
  background-color: #FF4405;
  padding: 3px 8px;
  border-radius: 4px;
  display: inline-block;
}

.trackTitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
}
