.success-signUp-Welcome-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-top: 4rem;
}
.success-Welcome-label-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.success-signup-close-div {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}
.success-Welcome-btn-grp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
@media (max-width: 1200px) {
  .success-signUp-container {
    height: 80vh;
  }
  .success-signup-card {
    margin-top: 15rem;
  }
}
