.register-signup-card {
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  position: relative;
  bottom: 24px;
  color: black;
}

.register-signUp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -3rem;
  left: 1px;
  z-index: 100;
}

.cardHeader {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  width: 377.16px;
  height: 22px;
  top: 122px;
  left: 70px;
  color: #000000;
}

.register-signUp-content {
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;
}
.register-signUp-content-left {
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 58px;
}

.register-close-div {
  display: flex;
  position: absolute;
  justify-content: flex-end;
  top: 1rem;
  right: 1rem;
}

.register-signUp-right-content {
  border-radius: 8px;
  border: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.register-signUp-label-div {
  width: 486px;
  height: 34px;
  top: 59px;
  left: 51px;
  position: relative;
}

.register-signUp-close-div {
  position: relative;
  display: flex;
  width: 16px;
  height: 16px;
  margin: 5px 15px 0px 15px;
  left: 175px;
}

.register-signUp-main {
  width: 100%;
  padding: 2rem 4rem;
}

.register-signUp-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.register-signUp-controls1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}
.register-login-label-div {
  width: full;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
}

.register-signUp-fields {
  width: 495px;
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}
.register-signUp-fields-right {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
}

.register-signUp-groupby {
  display: flex;
  align-items: center;
  gap: 3px;
}

.register-signUp-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.div-vertical-line {
  width: 1px;
  height: 200px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  margin: 0 10px;
}

.register-signUp-footer {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.register-signUp-progress-steps {
  width: 600px;
  margin: 10px;
  margin-left: 200px;
}

.register-signUp-back {
  width: 111px;
  height: 37px;
  top: 619px;
  left: 32px;
  padding: 8px;
  gap: 8px;
}

.register-backBtn {
  width: 79px;
  height: 35px;
  top: 118px;
  right: 564px;
  padding: 8px 0px 0px 0px;
  gap: 8px;
  opacity: 0px;
  position: relative;
}
.provider-btn-sup {
  width: 258px;
}
@media (max-width: 1200px) {
  .register-signUp-grid {
    flex-direction: column;
  }
  .register-signUp-content {
    width: 100%;
  }
  .register-signUp-fields {
    width: 100%;
  }
  .register-signUp-controls1 {
    flex-direction: row;
    align-items: center;
  }
  .register-signUp-content-left {
    align-items: flex-start;
    width: 100%;
  }
  .provider-btn-sup {
    width: 220px;
  }
}

.register-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.terms-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  pointer-events: none;
}

.terms-modal-wrapper > div {
  pointer-events: auto;
}
