.styledHeader {
  font-size: 26px;
  font-weight: 700;
  line-height: 33.85px;
}

.card {
  background-color: var(--sml-content-surface);
  box-shadow: var(--sml-box-shadow);
  padding: 20px;
  border-radius: 8px;
  box-sizing: border-box;
}

.card2 {
  background: var(--sml-content-surface2);
  box-shadow: var(--sml-box-shadow2);
  padding: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.textWithLabel h6 {
  color: var(--sml-label-color);
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  margin: 0px;
}

.textWithLabel h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  margin-bottom: -1px !important;
}

.textWithLabel img {
  height: 20px;
  width: 20px;
  margin-left: 4px;
}

.smlButton {
  padding: 16px 32px 16px 32px;
  gap: 8px;
  border-radius: 8px;
  opacity: 0px;
  border: none;
  background-color: var(--sml-primary-button-background);
  color: var(--sml-text-color-dark-surface);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.83px;
  text-align: center;
  margin-top: 16px;
}
