.SMLButton1 {
  min-width: 100%;
  max-width: 428px;
  max-height: 51px;
  padding: 11px 31px 15px 33px;
  border-radius: 8px;
  gap: 8px;
  background: #ff4405;
  position: relative;
  border-style: none;
}

.SMLButton2 {
  max-width: 150px;
  max-height: 51px;
  padding: 12px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  background: #ff4405;
  position: relative;
}
.SMLButton2.SMLButton-Orange.disabled {
  max-height: 51px;
  padding: 12px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  background: #ff4405;
  position: relative;
  cursor: not-allowed;
}

.SMLAssignBtn {
  padding: 12px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  color: #97180c;
  background: #f0f0f0;
  position: relative;
  border: none;
  text-align: center;
}
.SMLAssignBtn-disabled {
  padding: 12px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  color: #97180c;
  background: #f0f0f0;
  position: relative;
  border: none;
  cursor: not-allowed;
}
.NotesBtn {
  max-width: 150px;
  max-height: 51px;
  padding: 8px;
  border-radius: 6px;
  gap: 8px;
  color: #97180c;
  background: #e2e2e2;
  position: relative;
  border: none;
  text-align: center;
  margin: 10px 0px;
}
.SMLToggleBtn-orange {
  padding: 16px 32px 16px 32px;
  gap: 8px;
  background: #ff4405;
  position: relative;
  color: #ffffff;
  max-width: fit-content;
  border: none;
  border-radius: 62px;
  margin-right: 5px;
}

.SMLToggleBtn-white {
  padding: 16px 32px 16px 32px;
  gap: 8px;
  position: relative;
  color: black;
  margin-right: 5px;
  max-width: fit-content;
  border: none;
  border-radius: 62px;
}

.SMLOptionalBtn {
  margin-right: 3.5px;
  max-width: 428px;
  height: 48px;
  padding: 10px;
  border-radius: 8px;
  gap: 8px;
  background: #f5f7f9;
  border: 1px solid #ff4405;
  color: #ff4405;
}

.SMLSecondaryOptionalBtn {
  margin-right: 3.5px;
  max-width: 428px;
  height: 48px;
  padding: 10px;
  border-radius: 8px;
  gap: 8px;
  background: #f5f7f9;
  border: none;
}

.SMLButton3 {
  max-height: 51px;
  padding: 12px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  background-color: transparent;
  position: relative;
  color: #000;
  margin-right: 5px;
  border: 1px solid #310904;
}

.SMLButton-Orange {
  border-color: transparent;
  max-width: fit-content;
}

.SMLCancelUpload {
  width: 159px;
  height: 48px;
  top: 411px;
  left: 257px;
  border-radius: 8px;
  gap: 8px;
  border: 1px solid #310904;
  background-color: transparent;
  margin-right: 6px;
}

.SMLSendFeedback {
  width: 206px;
  height: 48px;
  top: 411px;
  left: 410px;
  border-radius: 8px;
  gap: 8px;
  background-color: #ff4405;
  border-color: transparent;
}

.SMLSendFeedback.disabled {
  width: 206px;
  height: 48px;
  top: 411px;
  left: 410px;
  border-radius: 8px;
  gap: 8px;
  background-color: #cccccc;
  border-color: transparent;
}

.SMLWhiteButton {
  max-width: 150px;
  max-height: 51px;
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  background-color: white;
  position: relative;
}

.SMLBlackButton {
  max-width: 150px;
  max-height: 51px;
  padding: 16px 32px 16px 32px;
  border-radius: 8px;
  gap: 8px;
  background-color: black;
  position: relative;
}
@media screen and (max-width: 1100px) {
  .SMLButton2 {
    padding: 12px 8px 16px 8px;
  }
  .SMLButton2.SMLButton-Orange.disabled {
    padding: 12px 8px 16px 8px;
  }
  .SMLButton-Orange {
    max-width: auto !important;
    width: 100%;
    text-wrap: nowrap;
  }
}
