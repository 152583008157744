.SMLSecondaryLabel {
  font-family: DMSansMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.SMLSecondaryLabelSelected {
  font-family: DMSansMedium;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #ff4405;
}

.SMLSecondaryButton {
  margin-right: 4px;
  margin-bottom: 8px;
  padding: 10px 7px;
  border-radius: 8px;

  background: #e9eaec;
  border: 1px solid #e9eaec;
  color: #97180c;
}
.SMLSecondaryButton.method {
  min-width: 90px;
}
.SMLSecondaryButtonSelected.method {
  min-width: 90px;
}

.SMLSecondaryButton1 {
  margin-right: 4px;
  padding: 8px;
  border-radius: 6px;
  outline: none;
  background: #e9eaec;
  border: 1px solid #e9eaec;
  color: #97180c;
}

.SMLSecondaryButtonSelected {
  margin-right: 4px;
  margin-bottom: 8px;
  padding: 10px 7px;
  border-radius: 8px;

  background: #fff4ed;
  border: 1px solid #ff4405;
  color: #ff4405;
}
@media (max-width: 1100px) {
  .SMLSecondaryButton1 {
    text-wrap: nowrap;
  }
}
