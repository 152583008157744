.custom-modal {
  z-index: 9999;
}
.custom-modal .modal-content {
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.custom-modal .modal-header,
.custom-modal .modal-body,
.custom-modal .modal-footer {
  padding: 1.5rem;
}

.custom-modal .modal-header {
  border-bottom: none;
}

.custom-modal .modal-footer {
  border-top: none;
}

.custom-modal .modal-content {
  padding: 2rem;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.cardHeader {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.02em;
  width: 377.16px;
  height: 22px;
  top: 122px;
  left: 70px;
  color: #000000;
}

.signUp-close-div {
  position: absolute;
  display: flex;
  right: 1rem;
  top: 1rem;
  color: #999999;
  cursor: pointer;
}

.signUp-main {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 1.5rem;
}
.signUp-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.signUp-right-content {
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 495px;
}
.signUp-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.provider-signup-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  align-items: flex-end;
  padding-right: 2rem;
}
.signUp-controls-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.signUp-controls-divider {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.signUp-controls-about {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
}
.signUp-fields {
  width: 495px;
  max-height: 77px;
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
}

.signUp-fields1 {
  width: 495px;
  /* height: 77px; */
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 21px;
}
.signUp-fields-about {
  width: 495px;
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 21px;
}

.signUp-fields-about textarea {
  resize: none;
  height: 150px;
}

.signUp-controls-about-right {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  overflow: visible;
}

.signUp-groupby {
  display: flex;
  align-items: center;
  gap: 3px;
}

.signUp-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.signUp-grid-about {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
}

.div-vertical-line {
  width: 1px;
  height: 200px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  margin: 0 10px;
}

.divider {
  border-left: 6px solid green;
  height: 200px;
  border: 1px solid #e9eaec;
  background: #e9eaec;
  width: 1px;
}

.signUp-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  bottom: 1rem;
}

.signUp-back {
  width: 111px;
  height: 37px;
  top: 619px;
  left: 32px;
  padding: 8px;
  gap: 8px;
}

.link {
  cursor: pointer;
  text-decoration: none;
}

.password-container {
  position: relative;
}

.toggle-password-visibility {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.signUp-options-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.link_scrollbar {
  padding-right: 10px;
}

.about-link-groupby {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 10px;
}

.link_scrollbar::-webkit-scrollbar {
  width: 6px;
}

.link_scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.link_scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.link_scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.next-btn {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.plan-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 20px;
  height: auto;
  overflow: visible;
}

.plan-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  overflow: visible;
}

.plan-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  background-color: #e9eaec;
  position: relative;
  height: auto;
  overflow: visible;
}

.plan-card-content {
  flex: 1;
  overflow: visible;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: visible;
}

.plan-card[data-layout="full-width"] {
  grid-column: 1 / -1;
  width: 100%;
  justify-self: center;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .plan-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .plan-card[data-layout="full-width"] {
    grid-column: span 1;
  }
}

@media (max-width: 768px) {
  .plan-cards {
    grid-template-columns: 1fr;
    overflow: hidden;
  }

  .plan-card[data-layout="full-width"] {
    grid-column: span 1;
  }

  .plan-card {
    width: 100%;
  }
}

.provider-btn {
  width: 428px;
}
.provider-btn > div {
  width: 428px !important;
}
.back-btn {
  bottom: 1rem;
}
.error-box {
  margin-left: 2rem;
  margin-top: 1rem;
  width: 50%;
}
.error-box-sup {
  margin-left: 2rem;
  margin-top: 1rem;
  width: 50%;
}
@media (max-width: 1200px) {

  .signUp-content {
    width: 100%;
  }
  .signUp-grid {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }
  .signUp-grid-about {
    flex-direction: column;
    align-items: flex-start;
  }
  .signUp-controls-about {
    align-items: flex-start;
  }
  .signUp-controls-about-right {
    align-items: flex-start;
  }
  .divider {
    height: 1px;
    width: 400px;
  }
  .signUp-controls {
    gap: 2rem;
  }
  .signUp-controls-divider {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
  }
  .provider-signup-controls {
    display: flex;
    gap: 1rem;
  }
  .signUp-right-content {
    width: 100%;
    padding: 0;
  }
}
@media (max-width: 992px) {
  .signUp-fields-about {
    width: 100%;
  }
  .signUp-fields {
    width: 100%;
  }
  .signUp-fields1 {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .provider-signup-controls {
    flex-direction: column;
  }
}
.plan-card {
  width: 350px;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  transition: transform 0.3s ease-in-out;
}

.plan-card:hover {
  background-color: #ff4405;
  color: white;
}

.plan-button {
  width: 100%;
  padding: 10px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.plan-button.current-plan {
  background-color: #ff4405;
  color: white;
}

.plan-button.selected-button {
  background-color: #ff4405;
}

.plan-button:hover {
  opacity: 0.9;
}

.contact-information-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.social-buttons-container {
  padding-left: 2rem;
  width: 100%;
}

/* New specific class for "Already a member?" */
.login-prompt-group {
  composes: signUp-groupby;  /* Inherit base styles */
  padding-right: 2rem;
  width: 428px;
  justify-content: flex-end;
  padding-left: 4rem;
}

.plan-card {
  position: relative;
}

.limited-time-image {
  width: 150px;
  height: 80px;
  position: absolute;
  top: -5px;
  left: 250px;
  z-index: 1;
  filter: brightness(1) invert(0);
  transform: rotate(-12deg);
}

.plan-card:hover .limited-time-image {
  filter: brightness(0.9) invert(0);
}

.plan-card.selected-card .limited-time-image {
  filter: brightness(0) invert(1);
}

.plan-card.selected-card:hover .limited-time-image {
  filter: brightness(0) invert(1);
}

.highlight-block {
  background: rgba(255, 68, 5, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  position: relative;
  border: 1px solid rgba(255, 68, 5, 0.15);
}

.highlight-tags {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 0;
}

.price-tags {
  position: absolute;
  top: -12px;
  right: -5px;
  display: flex;
  gap: 4px;
  z-index: 2;
}

.price-tag {
  height: 24px;
  width: auto;
  object-fit: contain;
}

.selected-card .price-tag {
  filter: brightness(0) invert(1);
}

.selected-card .highlight-block {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.2);
}

.selected-card .highlight-label,
.selected-card .highlight-value {
  color: #ffffff;
}

.highlight-label {
  color: #FF4405;
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.highlight-value {
  position: absolute;
  top: 16px;
  right: 16px;
  color: #FF4405;
  font-weight: 600;
  font-size: 14px;
}

.plan-old-price {
  font-size: 1.8em;
  color: #ff4405;
  margin-top: 10px;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  text-decoration: none;
}

.plan-old-price::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #ff4405;
  top: 50%;
  left: 0;
  transform: rotate(-10deg);
  transform-origin: center;
}

.annual-discount-text {
  color: #141414;
  font-weight: 600;
  margin-left: 8px;
  font-size: 14px;
  background: #23F0C7 !important;
  padding: 6px 12px;
  border-radius: 8px;
  display: inline-block;
  transition: all 0.2s ease;
  box-shadow: 0 2px 8px rgba(35, 240, 199, 0.25);
  text-decoration: none;
  border: none;
  line-height: normal;
}

.annual-discount-text:hover {
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(35, 240, 199, 0.3);
}

/* New styles for required labels in AboutYou */
.signup-label-container {
  display: flex;
  align-items: baseline;
  gap: 8px;
  margin-bottom: 8px;
}

.signup-required-label {
  color: #ff4d4f;
  font-size: 14px;
  line-height: 1;
  animation: fadeInShake 0.5s ease-in-out;
  padding: 2px 6px;
  border-radius: 4px;
  background-color: #fff1f0;
  box-shadow: 0 2px 4px rgba(255, 77, 79, 0.15);
}

@keyframes fadeInShake {
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  20% {
    transform: translateX(2px);
  }
  40% {
    transform: translateX(-1px);
  }
  60% {
    transform: translateX(1px);
  }
  80% {
    transform: translateX(-1px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Add new styles for character count */
.character-count {
  text-align: right;
  color: #8c8c8c;
  font-size: 12px;
  margin-top: 4px;
  font-style: italic;
}

/* Add styles for dropdown to ensure proper stacking */
.SMLDropdownWrapper {
  position: relative;
  z-index: 5; /* Lower z-index than spinner overlay */
}