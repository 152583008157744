.input[type="text"]:focus {
  border: none;
}
.input.middle:focus {
  outline-width: 0;
}
.input:focus {
  outline: none;
}

.sml-textbox-wrapper {
  display: flex;
}

.SMLTextbox:focus {
  box-shadow: none;
}

.input[type="text"]:hover {
  border-color: transparent;
}
.sml-textbox-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.remove-link-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.SMLTextbox {
  padding-right: 30px;
}
