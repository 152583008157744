.restriction-sub-modal .restriction-sub-modal-content-wrapper {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  position: relative;
  animation: modal-slide-in 0.3s ease-out;
}

@keyframes restriction-sub-modal-slide-in {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.restriction-sub-modal-close-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: transform 0.2s ease;
}

.restriction-sub-modal-close-btn:hover {
  transform: scale(1.1);
}

.restriction-sub-modal-body-wrapper {
  text-align: center;
  color: #333;
  padding-top: 1.5rem;
}

.restriction-sub-modal-icon {
  color: #ffcc00;
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
}

.restriction-sub-modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 0.5rem;
}

.restriction-sub-modal-message {
  font-size: 1rem;
  font-weight: 500;
  color: #666;
  margin-bottom: 0.5rem;
}

.restriction-sub-modal-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.restriction-sub-action-button {
  padding: 0.6rem 1.5rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.restriction-sub-action-button.primary {
  background-color: #ff4405;
  color: #fff;
  border: none;
}

.restriction-sub-action-button.primary:hover {
  background-color: #ff4405;
}
.restriction-sub-action-button1.secondary{
  background-color: #e9ecef;
  /* width: 100%; */
  /* padding: 0.9rem 0; */
  border-radius: 8px;
  padding: 0.6rem 1.5rem;
  color: #333;
  border: 1px solid #ddd ;
}
.restriction-sub-action-button.secondary {
  background-color: white;
  width: 100%;
  padding: 0.9rem 0;
  color: #333;
  border: none;
}

.restriction-sub-action-button.secondary:hover {
  background-color: #ced4da;
}
.restriction-or {
  color: #333;
  font-family: DM Sans;
}
.restriction-or-line {
  width: 150px;
  height: 1px;
  background-color: #333;
}

.special-plan-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff4405;
  width: 40%;
  margin: 0 auto;
  cursor: pointer;
  padding: 0.5rem 0;
  border-radius: 10px;
  color: white;
  font-family: DM Sans;
  font-family: 600;
}

.special-plan-card1 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff4405;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  padding: 1.2rem 0;
  border-radius: 10px;
  color: white;
  font-family: DM Sans;
  font-family: 600;
}

.special-plan-card-button2 {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  width: 100%;
  margin: 0 auto;
  cursor: pointer;
  padding: 0.9rem 0;
  border-radius: 10px;
  color: white;
  font-family: DM Sans;
  font-family: 600;
}

.checbox-restriction-popup {
  /* background-color: #23F0C7; */
  margin-top: 1rem;
  /* font-size: 1.1rem; */
  color: black;
  margin-left: 1rem;
  font-weight: 400;
}
