.signUp-welcome-main {
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 3rem 2rem;
}

.Welcome-Label-text {
  text-align: center;
}

.Welcome-label-track {
  padding: 10px 8px 15px 0px;
}

.Welcome-label-div{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.welcome-home-btn1 {
  background: none;
  border: none;
  cursor: pointer;
}

.welcome-home-btn {
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
  border: 1px solid #ff4405;
  border-radius  : 10px;
  color: white;
  background-color: #ff4405;
padding: 0.5rem 1rem;
font-weight: 600;

}

.welcome-success-checkMark {
  width: 120px;
  height: 120px;
  margin-top: 4rem;
  margin-bottom: 1rem;
}

.Welcome-btn-grp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
}
