.artistProfile {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 0;
  font-family: DM Sans;
}

.artistProfile-container {
  top: 0px;
}

.artistProfile-close-div {
  position: absolute;
  display: flex;
  right: 2rem;
  top: 2rem;
  /* padding: 1px; */
  /* gap: 8px; */
  /* font-size: 25px; */
  color: #999999;
  cursor: pointer;
}

.artistProfile-rectangle {
  gap: 0px;
  background: #ffffff;
  border-radius: 24px;
}

.artistProfile-header {
  gap: 0px;
}

.artistProfile-frame {
  display: flex;
  justify-content: space-between;
}

.artistProfile-logo {
  gap: 16px;
  display: flex;
}

.artistProfile-link {
  margin-top: 10px;
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: #141414;
}

.artistProfile-description-container {
  margin-top: 37px;
  display: grid;
}

.artistProfile-description {
  display: grid;
  margin-bottom: 60px;
}
.lblArtistName {
  font-family: DM Sans;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #141414;
}
.lblSubTitle {
  display: flex;
  align-items: center;
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
  text-align: left;
  color: #141414;
  width: 120%;
  flex-wrap: wrap;
}
.profileTitle {
  font-family: DM Sans;
  font-size: 16px;
  font-weight: 700;
  line-height: 20.83px;
  text-align: left;
  color: #000000;
}
.profileDescription {
  font-family: DM Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
  color: #000000;
}
