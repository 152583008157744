.feedback-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.feedback-card {
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  height: 688px;
  width: 95% !important;
  top: 72px;
  max-width: 680px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.feedback-close-div {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  top: 46px;
  left: 622px;
  padding: 1px;
  gap: 8px;
  font-size: 25px;
  color: #999999;
  cursor: pointer;
  z-index: 10;
}

.feedback-close-div img {
  width: 40px;
  height: 40px;
}

.feedback-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  border-radius: 8px;
  border: 1px;
  padding: 20px;
  justify-content: space-between;
  height: 100%;
}

.feedback-label-div {
  height: 34px;
  top: 40px;
  left: 151px;
  margin: auto;
}

.feedback-content {
  height: 500.56px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  flex: 1;
  padding: 10px 20px 10px 20px;
}

.feedback-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.feedback-fields {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
}
.feedback-fields-link {
  width: 45%;
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
  overflow-y: auto;
}

.feedback-controls1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.feedback-middle-main {
  width: 432px;
  height: 185px;
  top: 251px;
  left: 166px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  position: relative;
}

.feedback-middle-content {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.feedback-middle-fields {
  margin: auto;
}

.feedback-groupby {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.feedback-btn-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: auto;
}

.feedback-groupby-middel-btn {
  margin-top: 1rem;
  align-items: center;
}

.feedback-btn-Bottom-right {
  position: absolute;
  right: 0;
  bottom: 0;
}

.no-border {
  border: none;
}

.link-btn-icon {
  font-size: 24px;
}

.feedback-groupby-bottom-margin {
  margin-bottom: 30px;
}

.feedback-top-margin {
  margin-top: 20px;
}

.feedback-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.feedback-card {
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  height: 688px;
  width: 95% !important;
  top: 72px;
  max-width: 680px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.feedback-close-div {
  position: relative;
  display: flex;
  width: 32px;
  height: 18px;
  top: 46px;
  left: 622px;
  padding: 1px;
  gap: 8px;
  font-size: 25px;
  color: #000;
  cursor: pointer;
}

.feedback-close-div img {
  width: 14px;
  height: 14px;
}

.feedback-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  border-radius: 8px;
  border: 1px;
  padding: 20px;
  justify-content: space-between;
  height: 100%;
}

.feedback-label-div {
  height: 34px;
  top: 40px;
  left: 151px;
  margin: auto;
}

.feedback-content {
  height: 500.56px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  padding: 10px 20px 10px 20px;
}

.feedback-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.feedback-fields {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
}

.feedback-controls1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.feedback-middle-main {
  width: 432px;
  height: 185px;
  top: 251px;
  left: 166px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  position: relative;
}

.feedback-middle-content {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.feedback-middle-fields {
  margin: auto;
}

.feedback-groupby {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.feedback-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.feedback-groupby-middel-btn {
  margin-top: 1rem;
  /* display: flex; */
  align-items: center;
}

.no-border {
  border: none;
}

.link-btn-icon {
  font-size: 24px;
}

.feedback-groupby-bottom-margin {
  margin-bottom: 30px;
}

.feedback-top-margin {
  margin-top: 20px;
}

.feedback-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.feedback-card {
  border-radius: 24px;
  background: white;
  border: 1px solid #e9eaec;
  height: 688px;
  width: 95% !important;
  top: 72px;
  max-width: 680px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.feedback-close-div {
  position: relative;
  display: flex;
  width: 32px;
  height: 18px;
  top: 46px;
  left: 92%;
  padding: 1px;
  gap: 8px;
  font-size: 25px;
  color: #999999;
  cursor: pointer;
}

.feedback-close-div img {
  width: 14px;
  height: 14px;
}

.feedback-main {
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  border-radius: 8px;
  border: 1px;
  padding: 20px;
  justify-content: space-between;
  height: 100%;
}

.feedback-label-div {
  height: 34px;
  top: 40px;
  left: 151px;
  margin: auto;
}

.feedback-content {
  height: 500.56px;
  border-radius: 8px;
  border: 1px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  padding: 10px 20px 10px 20px;
}

.feedback-controls {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.feedback-fields {
  position: relative;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 8px;
}

.feedback-controls1 {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.feedback-middle-main {
  width: 432px;
  height: 185px;
  top: 251px;
  left: 166px;
  gap: 0px;
  border-radius: 8px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  position: relative;
}

.feedback-middle-content {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.feedback-middle-fields {
  margin: auto;
}

.feedback-groupby {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.feedback-btn-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}

.feedback-groupby-middel-btn {
  margin-top: 1rem;
  align-items: center;
}

.no-border {
  border: none;
}

.link-btn-icon {
  font-size: 24px;
}

.feedback-groupby-bottom-margin {
  margin-bottom: 30px;
}

.feedback-top-margin {
  margin-top: 20px;
}

.SMLButton2.SMLButton-Orange.disabled {
  background-color: #cccccc;
}
.form-group.SMLSecondaryButtonSelected {
  margin-bottom: 40px;
  background-color: red;
  margin-left: 12px;
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 5px 0;
  width: 100%;
}

.checkbox-label {
  margin-left: 8px;
  font-size: 16px;
  color: #333;
  flex-grow: 1;
}

.feedback-groupby {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  width: 100%;
}

.feedback-groupby input[type="checkbox"] {
  pointer-events: none;
}

.checkbox-wrapper:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.checkbox-wrapper:focus-within {
  outline: 2px solid #007bff;
  outline-offset: 2px;
}
