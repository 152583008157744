.fontcolorblack {
  color: #141414;
}

.sml-fontIcon {
  font-family: "Google Sans", arial, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  color: #3c4043;
  text-align: left;
  letter-spacing: 0.25px;
  text-wrap: nowrap;
}

.SMLSignupbutton {
  width: 100%;
  max-width: 280px;
  height: 41px;
  color: #3e3e3e;
  background-color: white;
  border-color: #e9eaec;
  border-radius: 5px;
  border: 1px solid #d9dada;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;
}

.SMLSignupbutton:hover {
  background-color: #f5f5f5;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.SMLSignupbutton.gap {
  gap: 27px;
}
