.parallax-videoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #cbcbcb;
}
.parallax-screen {
  position: relative;
  max-width: 1200px;
  width: 85vw;
  height: 673.4px;
  max-height: 75dvh;
  background-color: rgb(0, 0, 0);
  background-size: contain;
  background-repeat: no-repeat;
}
.react-player {
  position: absolute;
  top: 14x;
  left: 1px;
  width: 968px;
  height: 497.4px;
  border-radius: 10px;
  box-shadow: 0 0 49.7px rgba(35, 240, 199, 0.1); 
}
