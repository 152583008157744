.link-btn {
  margin-left: auto;
  background: none;
  border: none;
  cursor: pointer;
  color: #333333;
  font-size: 14px;
  padding: 0;
}

.link-btn:hover {
  text-decoration: underline;
}

.link-btn .sub-subtitle2 {
  color: inherit;
  font-weight: 400;
}
