.myProfile-container {
  display: flex;
  flex-direction: row;
}
.custom-modal-upgrade {
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  padding: 2rem;
}
.saveBtn {
  color: #fff;
  background-color: #ff4405;
}

.reset-password {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.resetpasswordfield {
  position: relative;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
}

.myProfileContainer-Header {
  display: flex;
  justify-content: space-between;
}

.myProfile-header {
  color: black;
  height: 60px;
}

.myProfile-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  left: 245px;
  background: #f5f7f9;
}

.myProfile-main-content {
  flex-grow: 1;
  padding: 50px 30px 50px 50px;
  margin-top: 20px;
}

.profile-photo-card {
  width: 616px;
  height: 121px;
  top: 179px;
  left: 38.5px;
  border-radius: 8px;
  justify-content: space-between;
  padding: 22px 24px 16px 24px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  margin-bottom: 5px;
}

.profile-photo-avatar {
  width: 86px !important;
  height: 85px !important;
  gap: 8px;
}

.profile-add-photo {
  margin-top: 25px;
}

.profile-password-card {
  width: calc(110% - 17px);
}

.profile-email-card {
  width: 616px;
  height: 72px;
  top: 310px;
  left: 40px;
  border-radius: 8px;
  border: 0px 0px 0.5px 0px;
  padding: 24px;
  gap: 10px;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  background-color: #ffffff;
  margin-bottom: 5px;
  display: flex;
}

.profile-links-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  row-gap: 16px;
}

.profile-plan-card {
  width: calc(110% - 17px);
}

.profile-email-card-body,
.profile-email-card-content {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.profile-email-card-value {
  display: flex;
  gap: 10px;
}

.profile-email-card-body,
.profile-email-card-content,
.profile-plan-card-body,
.profile-plan-card-content {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
}

.profile-email-card-value,
.profile-plan-card-value,
.profile-password-card-value {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.profile-fields {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
  margin-bottom: 3px;
}

.profile-details-card {
  border-radius: 8px;
  border: 0px 0px 0.5px 0px;
  padding: 24px;
  gap: 8px;
  box-shadow: 0px 4px 4px 0px #8e8dd029;
  background-color: #ffffff;
  margin-bottom: 5px;
  position: absolute;
}

.profile-aboutYouSelf {
  /* height: 215px; */
  height: 100%;
  padding: 10px 0;
  border: 0px 0px 0.5px 0px;
  gap: 16px;
}

.profile-experience {
  gap: 16px;
  padding: 10px 0;
  border-bottom: 1.5px solid #e9eaec;
}

.profile-buttons {
  gap: 16px;
  padding: 10px 0;
  border-bottom: 1.5px solid #e9eaec;
}

.popups-blur {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Viewplan-popup {
  position: relative;
  z-index: 999;
  min-width: 67%;
}

/* Add these new styles below */
.Viewplan-popup .custom-modal {
  max-width: 95% !important;
  margin: 1.75rem auto;
}

.Viewplan-popup .modal-content {
  border-radius: 8px;
  background-color: #fff;
  max-height: 90vh;
  overflow-y: auto;
  padding: 20px;
}

/* Target the plan cards inside the modal structure */
.Viewplan-popup .signup-card .plan-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  gap: 24px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
}

.Viewplan-popup .signup-card .plan-card {
  min-width: 0;
  height: 100%;
}

/* Style for the Add-on Services card */
.Viewplan-popup .signup-card .plan-cards > div:nth-child(4) {
  grid-column: 2;
  max-width: 400px;
  margin: 20px auto 0;
  justify-self: center;
}

/* Add responsive styles */
@media (max-width: 1200px) {
  .Viewplan-popup .signup-card .plan-cards {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 10px;
  }

  .Viewplan-popup .signup-card .plan-cards > div:nth-child(4) {
    grid-column: 1 / -1;
  }
}

@media (max-width: 768px) {
  .Viewplan-popup .signup-card .plan-cards {
    grid-template-columns: 1fr;
  }

  .Viewplan-popup .signup-card .plan-cards > div:nth-child(4) {
    grid-column: 1;
  }
}

.no-border-input {
  border: 1px solid #e9eaec;
  outline: none;
  background-color: transparent;
  width: 100%;
  border-radius: 6px;
  font-family: var(--font-family-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.invalid-input {
  border: 1px solid red;
}

@media only screen and (min-device-width: 744px) and (max-device-width: 1133px) {
  .profile-details-card {
    width: 520px;
    height: 527px;
    top: 179px;
    left: 475px;
    border-radius: 8px;
    border: 0px 0px 0.5px 0px;
    padding: 24px;
    gap: 8px;
    box-shadow: 0 4px 4px #8e8dd0;
    background-color: #ffffff;
    margin-bottom: 5px;
  }

  .col-7 {
    flex: 0 0 auto;
    width: 59.333333% !important;
  }

  .side-menu-bar {
    width: 120px;
    height: 1133px;
    gap: 8px;
    border-radius: 0px 24px 0px 0px;
    opacity: 0px;
  }

  .iconName {
    display: none;
  }

  .myProfile-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 624px;
    height: 1384px;
    left: 120px;

    background-color: #f5f7f9;
  }

  .search-box {
    width: 262px;
    height: 42px;
    top: 32px;
    left: 24px;
    padding: 12px 16px 12px 16px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px #8e8dd029;
  }

  .sml-header {
    height: 60px;
    margin: 10px;
    width: 950px;
    display: flex;
  }

  .navbar-left {
    display: flex;
    align-items: center;
    position: relative;
    right: 71px;
    bottom: 32px;
  }

  .navbar-right {
    width: 223px;
    height: 48px;
    left: 66px;
    bottom: 12px;
    gap: 0px;
    opacity: 0px;
    position: absolute;
  }

  .profile-plan-card {
    width: calc(110% - 17px);
  }

  .profile-photo-card {
    padding: 16px 24px 16px 24px;
    gap: 0px;
    border-radius: 8px 0px 0px 0px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px 0px #8e8dd029;
    margin-bottom: 5px;
  }

  .profile-email-card {
    padding: 24px 0px 0px 0px;
    gap: 8px;
    border-radius: 8px;
    border: 0px 0px 0.5px 0px;
    opacity: 0px;
    box-shadow: 0px 4px 4px 0px #8e8dd029;
    background-color: #ffffff;
    margin-bottom: 5px;
    display: flex;
  }

  .profile-links-card {
    border-radius: 8px;
    justify-content: space-between;
    padding: 24px;
    box-shadow: 0px 4px 4px 0px #8e8dd029;
    background-color: #ffffff;
    margin-bottom: 5px;
  }

  .profile-plan-card {
    border-radius: 8px;
    border: 0px 0px 0.5px 0px;
    padding: 24px;
    gap: 10px;
    box-shadow: 0px 4px 4px 0px #8e8dd029;
    background-color: #ffffff;
  }

  .profile-details-card {
    border-radius: 8px;
    border: 0px 0px 0.5px 0px;
    padding: 24px;
    gap: 8px;
    box-shadow: 0px 4px 4px 0px #8e8dd029;
    background-color: #ffffff;
    margin-bottom: 5px;
  }

  .myProfile-main-content {
    flex-grow: 1;
    padding: 50px 30px 50px 50px;
    margin-top: 20px;
    position: relative;
    right: 29px;
  }

  .myProfile-header {
    color: black;
    height: 60px;
    position: relative;
    right: 29px;
  }

  .profile-email-responsive {
    display: contents !important;
  }
}

.profile-plan-card-wrapper {
  display: flex;
  justify-content: space-between;
}

.col-2,
.col-3,
.col-10,
.col-5,
.col-7,
.col-8,
.col-4 {
  width: auto;
}

.col-5 {
  width: auto;
}
#profile-link {
  z-index: 999;
}

/* Modal styles */
.custom-modal .modal-content {
  border-radius: 8px;
  background-color: #fff;
  /* background-color: #f5f7f9; */
  /* max-height: 90vh; */
  /* overflow-y: auto; */
  padding: 20px;
  width: 95vw;
  max-width: 1400px;
  margin: 0 auto;
}

.custom-modal .modal-dialog {
  max-width: 95vw !important;
  margin: 1.75rem auto;
}

/* Plan layout styles */
.plan-form {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.plan-cards {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  gap: 24px;
  padding: 20px;
  width: 100%;
  margin: 0 auto;
}

.plan-card {
  min-width: 0;
  height: auto;
  display: flex;
  flex-direction: column;
}

/* Fourth card (Add-on Services) */
.plan-cards > div:nth-child(4) {
  grid-column: 1 / -1;
  max-width: 400px;
  justify-self: center;
  margin: 20px auto 0;
}
.profile-delete-btn {
  display: flex;
  justify-content: end;
  /* margin-top: -2rem; */
  cursor: pointer;
  /* background-color: red; */
  /* color: white; */
}
.delete-btn {
  background-color: rgb(168, 3, 3);
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
}
@media (max-width: 1200px) {
  .plan-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .plan-cards {
    grid-template-columns: 1fr;
  }
}

.profile-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

/* Add these new styles for the plan badge */
.plan-badge {
  border-radius: 12px;
  padding: 4px 12px;
  font-size: 13px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.plan-badge.basic {
  background-color: #ecfdf5;
  color: #059669;
  border: 1px solid #a7f3d0;
}

.plan-badge.pro {
  background-color: #eff6ff;
  color: #3b82f6;
  border: 1px solid #bfdbfe;
}

.plan-badge.partner {
  background-color: #f5f3ff;
  color: #7c3aed;
  border: 1px solid #ddd6fe;
}

/* Default style for no subscription */
.plan-badge:not(.basic):not(.pro):not(.partner) {
  background-color: #f3f4f6;
  color: #6b7280;
  border: 1px solid #e5e7eb;
}

.currentPlan {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}

.currentPlan.free {
  background-color: #f3f4f6;
  color: #6b7280;
}

.currentPlan.basic {
  background-color: #dbeafe;
  color: #1d4ed8;
}

.currentPlan.essential {
  background-color: #d1fae5; /* Light Green */
  color: #065f46; /* Dark Green */
}

.currentPlan.pro {
  background-color: #fef3c7;
  color: #b45309;
}

.currentPlan.partner {
  background-color: #f3e8ff;
  color: #7e22ce;
}
