#waveform {
  position: relative;
  width: 100%;
  height: 150px;
  background-color: transparent;
}

.play-pause-btn {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #23f0c7;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#descriptions {
  flex: 1 1;
  display: flex;
  overflow-x: auto;
  gap: 20px;
  margin-top: 10px;
  padding: 10px 0;
  white-space: nowrap;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
}
.detail-results {
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}
#descriptions-detail {
  flex: 1 1 auto;
  min-width: 27%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  gap: 20px;
  margin-top: 10px;
  padding: 10px 0;
  white-space: nowrap;
  max-height: 400px;
  overflow-y: auto;
}

.input-track-note {
  width: 300px;
  height: fit-content;
  max-height: 100%;
  overflow-y: auto;
  flex: 0 0 auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: 8px;
  height: fit-content;
}
.input-track-notes {
  width: 400px;
  max-height: 100%;
  overflow-y: auto;
  flex: 0 0 auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: 8px;
  height: fit-content;
}

.comment-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.timestamp {
  font-weight: bold;
  font-size: 14px;
  color: #333;
  display: flex;
}

.comment-content {
  overflow: hidden;
  width: 100%;
  height: auto;
  padding: 8px;
  box-sizing: border-box;
  background: #d9fff7;
  border-radius: 5px;
  white-space: normal;
}

.author {
  font-weight: bold;
  margin-bottom: 5px;
  font-size: 14px;
  color: #333;
}

.transcript {
  height: 100%;
  overflow: auto;
  padding: 10px;
  white-space: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #333;
  border-radius: 5px;
  border: 1px solid #23f0c7; /* Teal border */
}

.comment-box {
  width: 300px;
  padding: 10px;
  background-color: white; /* Solid background */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  top: 4px;
  display: flex;
  flex-direction: column;
  grid-area: 8px;
}

.time-box {
  width: fit-content;
  padding: 5px;
  background-color: white; /* Solid background */
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
  z-index: 10;
  top: -24px;
}

.comment-box textarea {
  appearance: none;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #23f0c7;
}

.comment-box textarea:focus {
  box-shadow: 0 0 5px #23f0c7;
  outline: none;
}

.add-note {
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 4px;
  cursor: pointer;
}

.guide-note {
  width: 300px;
  text-wrap: wrap;
  color: white;
  padding: 8px;
  background-color: #141414;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* position: absolute; */
  z-index: 10;
}

.guide-note-content{
  padding-right: 0.6rem;
  margin-top: -1rem;
  padding-left: 1.1rem;
  padding-bottom: 0.5rem;
}

.input-track-note textarea {
  appearance: none;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #23f0c7;
}

.input-track-note textarea:focus {
  box-shadow: 0 0 5px #23f0c7;
  outline: none;
}

.input-track-note .content {
  margin: 0 -10px -10px;
  padding: 12px;
  background: #d9fff7;
}

.input-track-notes .content .comment-content {
  overflow: hidden;
  text-wrap: wrap;
  padding: 0;
}
.input-track-notes textarea {
  appearance: none;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #23f0c7;
}

/* .input-track-notes textarea:focus {
  box-shadow: 0 0 5px #23f0c7;
  outline: none;
} */

.input-track-notes .content {
  margin: 0 -10px -10px;
  padding: 12px;
  background: #d9fff7;
}
/*
.input-track-notes .content .comment-content {
  overflow: hidden;
  text-wrap: wrap;
  padding: 0;
} */
