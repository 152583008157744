.SMLTextarea {
  resize: none; /* Prevents the user from resizing the textarea */
  max-width: 100%;
  height: 150px;
  padding: 6px;
  border-radius: 8px;
  gap: 8px;
  background: #f5f7f9;
  border: none;
  width: 100%;
  width: Fill (428px) !important;
  border: 1px solid #e5e9eb;
}
.SMLTextarea-notes {
  resize: none;
  max-width: 100%;
  height: 150px;
  padding: 6px;
  border-radius: 8px;
  gap: 8px;
  background: #f5f7f9;
  border: none;
  width: 100%;
  width: Fill (428px) !important;
}
