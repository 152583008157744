.video-player-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%; /* 16:9 aspect ratio */
    overflow: hidden;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 5rem;
    color: white;
    cursor: pointer;
  }